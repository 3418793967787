import React, {Component, Fragment} from 'react';

import {VerticalTimeline, VerticalTimelineElement} from 'react-vertical-timeline-component';

import PerfectScrollbar from 'react-perfect-scrollbar';
import {apiAxios, postAxios} from "../../../../utils/commAxios";
import {injectIntl} from "react-intl";
import {Link, withRouter} from "react-router-dom";
import {SimpleTooltip} from "../../../../components/Tooltip/Tooltips";
import {connect} from "react-redux";
import {HEADER_LANG} from "../Header_lang";
import {nullToBlank} from "../../../../utils/commUtils";

const LIMIT_TITLE_SIZE = 15;
const SELECT_NOTICELIST_URL = 'https://wingsapi.sanhait.com/is/v1/notifications';
//const SELECT_NOTICELIST_URL = 'http://localhost:8762/is/v1/notifications';
const SELECT_PACKAGE_COMPANYID = "PG_ZZ00_COMN_COMBO_LIST.PR_TB_PM_MASTER_SEL";
const SELECT_NOTICE_VIEW = "PG_ZZ03_HEADER.PR_PM_NOTICE_VIEW_CHANGE";

const HeaderNotice = (props) => {
    const {setIsOpen, history, intl, isOpen, setNotReadNotice} = props;
    const {formatMessage} = intl;
    const MULTI_LANG = HEADER_LANG(formatMessage);
    const EMERGENCY_CODE = "001";
    const IMPORTANT_CODE = "002";
    const NORMAL_CODE = "003";

    const [ctxHeight, setCtxHeight] = React.useState(0);
    const [noticeData, setNoticeData] = React.useState([]);
    const [propertyObject, setPropertyObject] = React.useState({propertyNo : props.propertyNo, propertyName : props.propertyName});

    React.useEffect(() => {
        setPropertyObject({propertyNo : props.propertyNo, propertyName : props.propertyName});
        if (isOpen) {
            searchCompanyId(props.propertyNo);
        } else {
            setNoticeData([]);
        }
    }, [props.propertyNo, isOpen]);

    React.useEffect(() => {
        const viewHeight = window.innerHeight;
        const headerHeight = document.getElementsByClassName('app-header')[0].clientHeight;

        const contentHeight = viewHeight - headerHeight - 250;
        let itemHeight = 80;

        if (document.getElementsByClassName('vertical-timeline').length > 0) {
            itemHeight = document.getElementsByClassName('vertical-timeline')[0].clientHeight + 40;
        }

        if(itemHeight > contentHeight){
            setCtxHeight(contentHeight);
        }else{
            setCtxHeight(itemHeight);
        }
    }, [noticeData]);

    const searchCompanyId = (id) => {
        const form = {
            PROCEDURE_OR_TABLE_NAME : SELECT_PACKAGE_COMPANYID,
            PM_NO : id
        }

        cmsCommonFunc((result) => {
            const data = result.data.resultData.V_OUT_JAVA_CURSOR;

            if (data.length > 0) {
                searchNoticeList(SELECT_NOTICELIST_URL, data[0].COMPANY_ID, data[0].PM_CODE, data[0].PM_SYSTEM_CONN_TYPE_KEY);
            }
        },form);
    }

    const searchNoticeList = (url, id, pmCode, bsprNo) => {
        let bsnsCode = '11';
        let propertyNo = '11';

        if(nullToBlank(bsprNo) !== ""){
            let bsprNoArr = bsprNo.split('|');

            if(bsprNoArr.length === 2){
                bsnsCode = bsprNoArr[0];
                propertyNo = bsprNoArr[1];
            }
        }

        const params = {
            bsnsCode : bsnsCode,
            propertyNo : propertyNo,
            langTypeCode : props.intl.locale === "ko" ? "KR" : "EN",
            companyId : id,
            cmsCode : pmCode,
            callSystem : "CMS",
            //callSystem : "PMS",
            //id : id, //BHH1
            operationKey : "SELECT_NOTIFICATION",
            systemId : "WINGSINTEGRATION",
            sortType : "NEWEST",
            intPage : "1",
            intPageSize : LIMIT_TITLE_SIZE,
            anncType : "",
            anncNo : "",
            searchValue : "",
            noticeType : "",
            delYn : "N",
            anncCategoryType : ""
        };

        commonFunc((result) => {
            const data = result.data.resultData;
           
            if (data.length > 0) {
                searchNoticeView(data);
            }
        }, params, url);
    }

    const searchNoticeView = noticeData => {
        const tempList = [];
        
        noticeData.map(item => {
            if (item.NEW_YN === 'Y') {
                tempList.push({ANNC_NO: item.ANNC_NO});
            }
        });

        const form = {
            PROCEDURE_OR_TABLE_NAME: SELECT_NOTICE_VIEW,
            DATA_LIST_TB_ZZ_PM_NOTICE: tempList
        };

        cmsCommonFunc((result) => {
            const data = result.data.resultData.V_OUT_JAVA_CURSOR;
            const out = result.data.resultData.V_OUT_JSON_VALUE.out_message;

            makeData(noticeData, data);

            if (out === 'Y') {
                setNotReadNotice(true);
            } else {
                setNotReadNotice(false);
            }
        },form);
    }

    const makeData = (standard, addData) => {
        addData.map(data => {
            const findData = standard.findIndex(child => {
                return child.ANNC_NO == data.ANNC_NO;
            });

            if (findData > -1) {
                standard[findData].VIEW_YN = data.VIEW_YN;
                standard[findData].CONTROL_YN = data.CONTROL_YN;
            }
        });
        
        setNoticeData(standard);
    }

    const commonFunc = (callbackFunc, formData, url) => {
        const option = {
            method : 'GET',
            url : url,
            headers: {'Content-Type' : "application/json",
                // 'API-KEY' : "dfebb96ba869afb52685d96083ffef84b7ae1c8c3c5e1a3888836e75278937a6",
                // 'VENDOR_ID'  : 'SANHA'
                'API-KEY' : "$2a$12$hJwHWQ9t9UIpJTlmifqni.98n9ySz4UBJBukC2hR6foUrUPrq7qk2",
                'VENDOR_ID'  : 'NOTIFICATION'
            }
        }

        apiAxios(option, formData, callbackFunc, (error) => console.log(error));
    };

    const noticeClick = (anncNo) => {
        setIsOpen(false);

        history.push('/app/zz/zz03_0500/' +anncNo+ '/00/NEWEST');
    }

    const cmsCommonFunc = (callbackFunc, formData) => {
        postAxios({
                method: 'POST',
                url: "/comm/jsonProcess_Procedure"
            },
            formData,
            callbackFunc);
    };

    const createIcon = item => {
        if (item.CONTROL_YN === 'Y' && item.VIEW_YN === 'N') {
            return (
                <i className="badge badge-dot badge-dot-xl" style={{backgroundColor: '#ff0000'}}>&nbsp;</i>
            )
        } else {
            return (
                <i className="badge badge-dot badge-dot-xl" style={{backgroundColor: '#e9ecef'}}>&nbsp;</i>
            )
        }
    }

    return (
        <Fragment>
            <div className="scroll-area-sm" style={{height:ctxHeight}}>
                <PerfectScrollbar>
                    <div className="p-3" style={{height:'100%'}}>
                        {
                            noticeData.length > 0 &&
                            <VerticalTimeline className="vertical-without-time index-board" layout="1-column">
                                {noticeData.map((item, index) => {
                                    return (
                                        <VerticalTimelineElement className="vertical-timeline-item" key={index} icon={createIcon(item)}>
                                            <div className="timeline-board-title">
                                                { item.ALARM_TYPE_CODE === EMERGENCY_CODE && <span className="mr-2 state state-danger">{MULTI_LANG.HEADER_EMERGENCY}</span> }
                                                { item.ALARM_TYPE_CODE === IMPORTANT_CODE && <span className="mr-2 state state-outline-danger">{MULTI_LANG.HEADER_IMPORTANT}</span> }
                                                { item.ALARM_TYPE_CODE === NORMAL_CODE && <span className="mr-2 state state-light">{MULTI_LANG.HEADER_NORMAL}</span> }
                                                <SimpleTooltip title={item.SUBJECT} position="bottom">
                                                    {/*
                                                    <Link to={{pathname : `/app/zz/zz03_0500/${item.anncNo}/00`,}} onClick={(item.anncNo) => noticeClick}>
                                                    */}
                                                    <Link to='#' onClick={() => noticeClick(item.ANNC_NO)}>
                                                        {item.SUBJECT}
                                                    </Link>
                                                </SimpleTooltip>
                                            </div>
                                        </VerticalTimelineElement>
                                    )
                                })}
                                {/*
                                <VerticalTimelineElement className="vertical-timeline-item" >
                                    <div className="timeline-board-title"><button className="mr-2 btn btn-danger">긴급</button>공지사항 제목(영문기준 15자이후…)...</div>
                                </VerticalTimelineElement>
                                <VerticalTimelineElement className="vertical-timeline-item" >
                                    <div className="timeline-board-title"><button className="mr-2 btn btn-light">일반</button>공지사항 제목</div>
                                </VerticalTimelineElement>
                                <VerticalTimelineElement className="vertical-timeline-item" >
                                    <div className="timeline-board-title"><button className="mr-2 btn btn-outline-danger">중요</button>공지사항 제목</div>
                                </VerticalTimelineElement>
                                */}
                            </VerticalTimeline>
                        }
                        {
                            noticeData.length < 1 &&
                            <div style={{height:'100%', display:'table', margin:'0 auto'}}>
                                <div style={{display:'table-cell', verticalAlign:'middle'}}>
                                    {MULTI_LANG.HEADER_NODATA}
                                </div>
                            </div>
                        }
                    </div>
                </PerfectScrollbar>
            </div>
        </Fragment>
    )
}

const mapStateToProps = state => {
    return({
        propertyNo : state.PropertyState.propertyNo,
        propertyName : state.PropertyState.propertyName,
    });
}

export default withRouter(injectIntl(connect(mapStateToProps)(HeaderNotice)));
