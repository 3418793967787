import React from 'react';
import { SimpleTextField } from '../../Text/TextField';
import { InsertChart, InsertChartRounded } from '@material-ui/icons';

function TimeTextField(props) {
    /*
    1. 입력 제한
        - 입력 수 제한 
        - 숫자만 입력가능
    2. 입력시 중간에 : 삽입하기
    3. 2자리 입력 : 현재시간:2자리
    4. 3자리 이상 입력 : 시간, 분 순서로 입력
    */
    const [ state, setState ] = React.useState({
        value: '',
    });
    
    React.useEffect( () => {
        console.log("update");
    } );
    
    const onValidInput = (event) => {
        const {onChange} = props;

        let eventValue = event.target.value ;
       
        if ( !isValidNumber(eventValue) ) return;
        if ( !isValidLimitedNumberLength(eventValue, 4) ) return;
        
        setState({...state, value: eventValue} );
        if (onChange !== undefined) onChange();
    }

    const onAutoComplete = (event) => {
        const {onBlur} = props;

        let eventValue = event.target.value;

        let time = formatTime(eventValue);
        setState({...state, value: time} );

        if (onBlur !== undefined) onBlur();
    }

    const isValidNumber = (unclearValue) => {
        let number = unclearValue.replace(':', '')
        let lastChar = number.charCodeAt(number.length-1);

        if ( lastChar < 48 ||  lastChar > 57 ) {
            return false;;
        }
        return true;
    }

    const isValidLimitedNumberLength = (unclearValue, limitLength) => {
        let number = unclearValue.replace(':', '')
        
        if ( number.length > limitLength ) {
            return false;
        }
        return true;
    }

    const formatTime = (unclearValue) => {
        let inputDate = unclearValue.replace(':', '');

        let currentDate = new Date();

        if ( inputDate.length === 4) {
            currentDate.setHours(inputDate.slice(0,2));
            currentDate.setMinutes(inputDate.slice(2,4));
        } if ( inputDate.length === 3) {
            currentDate.setHours(inputDate.slice(0,1));
            currentDate.setMinutes(inputDate.slice(1,3));
        } else if ( inputDate.length <= 2 && inputDate.length > 0 ) {
            currentDate.setMinutes(inputDate.slice(0,2));
        }

        let hh = currentDate.getHours().toString();
        let mm = currentDate.getMinutes().toString();
        return (hh[1] ? hh : "0" + hh[0]) + ":" + (mm[1] ? mm : "0" + mm[0]);
    }
    
    return (
        <SimpleTextField 
            onChange={onValidInput} 
            onBlur={onAutoComplete}
            value={state.value} 
            InputProps={{ disableUnderline: true }} 
            style={{ border: ".01rem solid black", 
            backgroundColor: 'white' }} 
        />
    );

}

export default TimeTextField;