import React, { Fragment } from 'react';

import {
    Button,
    Modal,
    ModalFooter,
    Form,
    FormGroup,
    Input, Alert, Col,
} from 'reactstrap';

import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import PerfectScrollbar from 'react-perfect-scrollbar';

import SweetAlert from 'sweetalert-react';
import {getSessionInfo, nullToBlank, setCookieUserInfo} from "../utils/commUtils";
import {postAxios} from "../utils/commAxios";
import $ from "jquery";
import {injectIntl} from "react-intl";

import {CERT_POPUP_LANG} from "./certPopup_lang";
import {connect} from "react-redux";

const CERTI_AUTH_PACKAGE = "PG_ZZ00_AUTHORITY.PR_CERT_USER_SELECT";

const CertPopup = (props) => {
    const {show, setShow, callbackFunc, intl} = props;
    const { formatMessage } = intl;
    const MULTI_LANG = CERT_POPUP_LANG(formatMessage);
    let userInfo = getSessionInfo();

    //2020-03-18
    //SS_USER_FIRST_NAME, SS_USER_LAST_NAME 값이 없는 경우 NULL 값으로 출력됨.
    const userIdValidation = () => {
        const firstName = nullToBlank(userInfo.SS_USER_FIRST_NAME) == "" ? "" : userInfo.SS_USER_FIRST_NAME;
        const lastName = nullToBlank(userInfo.SS_USER_LAST_NAME) == "" ? "" : userInfo.SS_USER_LAST_NAME;
        
        return firstName + " " + lastName;
    }

    const passRef = React.useRef(null);
    const [userId, setUserId] = React.useState(userIdValidation());
    const [warningSnackbar, setWarningSnackbar] = React.useState(false);
    const [warningMessage, setWarningMessage] = React.useState("");
    const [state, setState] = React.useState({
        modal: true,
        show: false
    });

    

    React.useEffect(() => {
        if(show) {
            setTimeout(() => {
                if (nullToBlank(passRef.current) != "") {
                    $("#password").focus();
                }

                $("#certiFormBox").off("keydown", "input")
                $("#certiFormBox").on("keydown", "input", (event) => {
                    if(event.which === 13){
                        const form = event.target.form;
                        const index = Array.prototype.indexOf.call(form, event.target);

                        if(form.elements[index].className.indexOf('lastInput') > -1){
                            certHandler();
                        }else if(nullToBlank(form.elements[index + 1]) === ""){
                            certHandler();
                        }else{
                            form.elements[index + 1].focus();
                        }

                        event.preventDefault();
                    }
                })
            }, 300);
        }
    }, [show]);

    const toggle = () => {
        setState({
            modal: !state.modal
        });
        setShow(false);
        closePopup();
    }

    const certHandler = () => {
        setWarningSnackbar(false);
        $(".is-invalid").removeClass("is-invalid");

        if(nullToBlank($("#password").val()) == ""){
            warningAlert($("#password").attr("placeholder") + MULTI_LANG.CHECK_MESSAGE_INPUT, "#password");
            return false;
        }

        const dataString = {
            'PROCEDURE_OR_TABLE_NAME'   : CERTI_AUTH_PACKAGE,
            'USER_NO'                   : userInfo.SS_USER_NO + "",
            'PASSWORD'                  : $("#password").val()
        }

        postAxios({
                method: 'POST',
                url: "/comm/jsonProcess_Procedure"
            },
            dataString,
            (response) => {
                setShow(false);
                setState({show: false});

                if(typeof callbackFunc === "function"){
                    userInfo.SS_USER_ID = response.data.resultData.V_OUT_JSON_VALUE.out_message;
                    setCookieUserInfo(JSON.stringify(userInfo));
                    callbackFunc(userInfo);
                }
            },
            (e)=>{
                setTimeout(()=>{ $("#password").focus() }, 500);
            });
    }

    const warningAlert = (message, target) => {
        setWarningMessage(message);
        setWarningSnackbar(true);

        $(target).addClass("is-invalid");
        $(target).focus();
    }

    const closePopup = () => {
        setState({ show: false })
    }

    return (
        <Fragment>
            <ReactCSSTransitionGroup
                component="div"
                transitionName="TabsAnimation"
                transitionAppear={true}
                transitionAppearTimeout={0}
                transitionEnter={false}
                transitionLeave={false}
            >
                <Modal
                    centered={true}
                    //show={state.show} //에러로 인해 제거
                    isOpen={show}
                    toggle={toggle}
                    className="modal-medium noshadow"
                    backdrop="static"
                    style={{width: '400px'}}
                >
                    <div className="app-inner-layout__header text-white bg-success">
                        <div className="app-page-title">
                            <div className="page-title-wrapper">
                                <div className="page-title-heading">{MULTI_LANG.POPUP_HEADER_CERTIFICATION}</div>

                                <span className="closeBt" onClick={toggle}> {MULTI_LANG.POPUP_BUTTON_CLOSE} </span>
                            </div>
                        </div>
                    </div>

                    <div className="modal-wrapper">
                        <div className="modal-body">
                            <PerfectScrollbar>
                                <div className="container-fluid">
                                    <div className="channel-form">
                                        <div className="form-wrap mb-4">
                                            <Form id="certiFormBox">
                                                <FormGroup>
                                                    <ul className="p-0 m-0">
                                                        <li>
                                                            <Alert color="warning" isOpen={warningSnackbar}>
                                                                <button type="button" className="close top4" aria-label="Close" onClick={() => setWarningSnackbar(false)}><span aria-hidden="true">×</span></button>
                                                                {MULTI_LANG.POPUP_HEADER_WARNING +'! '+ warningMessage}
                                                            </Alert>
                                                        </li>
                                                        <li>
                                                            <Input
                                                                type="text"
                                                                name="USER_ID"
                                                                id="userId"
                                                                placeholder={MULTI_LANG.INPUT_LABEL_USER_NAME}
                                                                value={userId}
                                                                disabled
                                                            />
                                                        </li>
                                                        <li>
                                                            <Input
                                                                type="password"
                                                                name="PASSWORD"
                                                                id="password"
                                                                placeholder={MULTI_LANG.INPUT_LABEL_PASSWORD}
                                                                ref={passRef}
                                                            />
                                                        </li>
                                                    </ul>
                                                </FormGroup>
                                            </Form>
                                        </div>

                                    </div>
                                </div>
                            </PerfectScrollbar>
                        </div>
                    </div>
                    <ModalFooter className="text-center">
                        <Button color="link" onClick={toggle} className="pl-4 pr-4">
                            {MULTI_LANG.POPUP_BUTTON_CANCEL}
                        </Button>
                        <Button color="primary" onClick={certHandler} className="pl-4 pr-4">
                            {MULTI_LANG.POPUP_HEADER_CERTIFICATION}
                        </Button>
                    </ModalFooter>
                </Modal>


                <SweetAlert
                    title={MULTI_LANG.POPUP_LABEL_COMPLATE}
                    confirmButtonColor=""
                    show={state.show}
                    type="success"
                    html
                    showConfirmButton={false}
                    showCancelButton={true}
                    customClass="closeBt"
                    onCancel={closePopup}
                />
            </ReactCSSTransitionGroup>
        </Fragment>

    );
}

const mapStateToProps = state => {
    return({
        propertyNo: state.PropertyState.propertyNo,
        propertyName: state.PropertyState.propertyName,
        changeTime: state.PropertyState.changeTime,
        prsnUrl: state.PropertyState.prsnUrl,
        prsnSystemCode: state.PropertyState.prsnSystemCode,
        prsnContentType: state.PropertyState.prsnContentType,
        prsnApiKey: state.PropertyState.prsnApiKey,
        prsnVendorId: state.PropertyState.prsnVendorId,
        prsnEchoToken: state.PropertyState.prsnEchoToken,
        prsnApiYn: state.PropertyState.prsnApiYn
    });
}

export default injectIntl(connect(mapStateToProps)(CertPopup));
