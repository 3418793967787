import React, {Fragment, useEffect} from 'react';
import {withRouter, Link} from "react-router-dom";

import PerfectScrollbar from 'react-perfect-scrollbar';

import {
    DropdownToggle, DropdownMenu,
    Nav, Button, NavItem, NavLink,
    UncontrolledButtonDropdown
} from 'reactstrap';

import {
    faAngleDown,

} from '@fortawesome/free-solid-svg-icons';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import city3 from '../../../assets/utils/images/dropdown-header/city3.jpg';

import {removeUserCookie, nullToBlank, getSessionInfo} from "../../../utils/commUtils";
import {loginHistory, postAxios} from "../../../utils/commAxios";

const MY_PAGE_MENU_PACKAGE = "PG_ZZ03_HEADER.PR_USER_MENU_SELECT";
const LOGOUT_CHECK_PACKAGE = "PG_ZZ01_0100.PR_USER_LOGOUT_CHECK";

const TUTORIAL_COMN_CODE = '4';
let unmounted = false;

const UserBox = (props) => {const {location, history, ssUserInfo} = props;
    const [myMenu, setMyMenu] = React.useState([]);
    const [myMenuOpen, setMyMenuOpen] = React.useState(false);

    const myMenuCallback = (response) => {
        const data = response.data.resultData.V_OUT_JAVA_CURSOR;
        setMyMenu(data.filter(item => item.COMN_CODE !== TUTORIAL_COMN_CODE));
    };

    const toggleMyMenu = () => {
        setMyMenuOpen(!myMenuOpen);
    }

    const myMenuLinkClick = (item) => {
        setMyMenuOpen(false);


        history.push(item.PAGE_URL);
    }

    const myMenualLinkClick = (item) => {
        setMyMenuOpen(false);
        window.open(`#${item.PAGE_URL}/${location.pathname.split("/").slice(-1)}`)
    }
    
    useEffect(() => {
        let dataString = {
            PROCEDURE_OR_TABLE_NAME : MY_PAGE_MENU_PACKAGE,
        };

        postAxios({
                method: 'POST',
                url: "/comm/jsonProcess_Procedure"
            },
            dataString,
            myMenuCallback,
            (e)=>{console.log("에러 "+ e)});

        return function cleanup(){
            unmounted = true;
        }
    }, []);

    const logoutButton = () => {
        let userInfo = getSessionInfo();
        logoutCheck(userInfo);
    }

    const logoutCheck = (sessionInfo) => {
        const dataString = {
            'PROCEDURE_OR_TABLE_NAME'   : LOGOUT_CHECK_PACKAGE,
        }
        postAxios({
                method: 'POST',
                url: "/comm/jsonProcess_Procedure"
            },
            dataString,
            callbackFunc,
            (e)=>{
                console.log("에러 "+ e)
            });
    }

    const callbackFunc = () => {
        loginHistory("LOGOUT");
        removeUserCookie('Y');
        history.replace('/login/zz/zz01_0100');
    }

    return (
        <Fragment>
            <div className="header-btn-lg pr-0">
                <div className="widget-content p-0">
                    <div className="widget-content-wrapper">
                        <div className="widget-content-left">
                            <UncontrolledButtonDropdown isOpen={myMenuOpen}>
                                <DropdownToggle color="link" className="p-0" onClick={toggleMyMenu}>
                                    <div className="icon-wrapper icon-wrapper-alt rounded-circle">
                                        <div className="icon-wrapper-bg bg-success" />
                                        <FontAwesomeIcon icon={faAngleDown} size="1x" color="#3ac47d" className="m-auto font-size-lg"/>
                                    </div>
                                </DropdownToggle>
                                {(myMenuOpen) ? <div style={{position:'fixed',left:'0',top:'0',height:'100%',width:'100%',backgroundColor:'rgba(0, 0, 0, 0)'}} onClick={toggleMyMenu} /> : '' }
                                <DropdownMenu right className="rm-pointers dropdown-menu-lg">
                                    <div className="dropdown-menu-header">
                                        <div className="dropdown-menu-header-inner bg-info">
                                            <div className="menu-header-image opacity-2"
                                                 style={{
                                                     backgroundImage: 'url(' + city3 + ')'
                                                 }}
                                            />
                                            <div className="menu-header-content text-left">
                                                <div className="widget-content p-0">
                                                    <div className="widget-content-wrapper">
                                                        <div className="widget-content-left">
                                                            <div className="widget-heading">
                                                                {nullToBlank(ssUserInfo.SS_USER_FIRST_NAME) + " " + nullToBlank(ssUserInfo.SS_USER_LAST_NAME)}
                                                            </div>
                                                            <div className="widget-subheading opacity-8">
                                                                {nullToBlank(ssUserInfo.SS_USER_DUTY_NAME)}
                                                            </div>
                                                        </div>
                                                        <div className="widget-content-right mr-2">
                                                            <Button className="btn-pill btn-shadow btn-shine" color="focus" onClick={logoutButton}>
                                                                Logout
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="scroll-area-xs" style={{
                                        height: '110px'
                                    }}>
                                        <PerfectScrollbar>
                                            <Nav vertical>
                                                {
                                                    myMenu.map((item) => {
                                                        return (
                                                            item.COMN_CODE === "3" 
                                                            ? <NavItem key={item.COMN_CODE}>
                                                                <NavLink href="#" onClick={myMenualLinkClick.bind(this, item)}>
                                                                    {item.COMN_CODE_NAME_MKEY} 
                                                                </NavLink>
                                                            </NavItem>
                                                            : <NavItem key={item.COMN_CODE}>
                                                                <NavLink href="#" onClick={myMenuLinkClick.bind(this, item)}>
                                                                {/*<NavLink href={'#'+item.PAGE_URL}>*/}
                                                                    {item.COMN_CODE_NAME_MKEY}
                                                                </NavLink>
                                                            </NavItem>
                                                        );
                                                    })
                                                }
                                            </Nav>
                                        </PerfectScrollbar>
                                    </div>
                                </DropdownMenu>
                            </UncontrolledButtonDropdown>
                        </div>
                        <div className="widget-content-left  ml-3 header-user-info">
                            <div className="widget-heading">
                                {nullToBlank(ssUserInfo.SS_USER_FIRST_NAME) + " " + nullToBlank(ssUserInfo.SS_USER_LAST_NAME)}
                            </div>
                            <div className="widget-subheading">
                                {nullToBlank(ssUserInfo.SS_USER_DUTY_NAME)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default withRouter(UserBox);
