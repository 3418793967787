import React from 'react';
import Select from 'rc-select';
import PerfectScrollbar from 'react-perfect-scrollbar';

import {
    Button, Form, FormGroup,
    Card,
    CardBody,
    CardHeader,
    Col, CustomInput,
    InputGroup, InputGroupAddon,
    DropdownMenu,
    DropdownToggle, Label, Row,
    UncontrolledButtonDropdown,
    Input, Modal, ModalBody, ListGroup, ListGroupItem, Pagination,
} from "reactstrap";

import AgGridComponent from '../../../../../components/AgGrid/AgGridComponent';
import { ZZ12_0100_2_LANG } from '../zz12_0100_2_lang';
import { nullToBlank, multiLangReplace } from '../../../../../utils/commUtils';
import { postAxios } from '../../../../../utils/commAxios';

let MULTI_LANG ;
let historyGridApi = null;
let historyColumnApi = null;
const EMAIL_ALERT_CHANGE_LIST_PACKAGE = "PG_ZZ12_0100.PR_CUSTM_EMAIL_LIST_CHNG_SEL";

const ZZ12_0100_2_ChangeLogPopup = (params) => {
    const {formatMessage} = params.intl;
    MULTI_LANG = ZZ12_0100_2_LANG(formatMessage);
    
    const [modal, setModal] = React.useState(false);
    const [nowPage, setNowPage] = React.useState(1);
    const [pageSize, setPageSize] = React.useState(50);
    const [totalCount, setTotalCount] = React.useState(0);


    const [historyData, setHistoryData] = React.useState({
        columnDefs: [
            {field: "CRE_DT",           headerName: MULTI_LANG.SETTING_DATE,      cellStyle: {textAlign: 'center'},      width:80},
            {field: "COL_FILED_NAME",   headerName: MULTI_LANG.CHANGE_ITEM,       cellStyle: {textAlign: 'left'},      width:300,       cellRenderer :  'chngRenderer'},
            {field: "CHNG_USER_NAME",   headerName: MULTI_LANG.USER_ID,           cellStyle: {textAlign: 'center'},      width:80},
        ],
        defaultColDef : {
            sortable:true,
            editable : false,
            resizable: true,
            headerClass: 'text-center',
            menuTabs: false,
        },
        rowData : [],
        frameworkComponents: {
            chngRenderer: chngRenderer
        }
    });

    const onGridReadyForHistory = params => {
        historyGridApi = params.api;
        historyColumnApi = params.columnApi;

        historyGridApi.sizeColumnsToFit();
    }

    const toggle = () => {
        setModal(!modal);
    }

    const onShowSizeChange = (current, pageSize) => {
        setPageSize(pageSize)
    }

    const onPaginationChange = (current) => {
        setNowPage(current);
    }

    React.useEffect(() => {
        if(modal){
            let dataString = {
                'PROCEDURE_OR_TABLE_NAME'   : EMAIL_ALERT_CHANGE_LIST_PACKAGE,
                EMAIL_CUSTM_SET_NO  : params.data.EMAIL_CUSTM_SET_NO,
                INT_PAGE            : '',
                PAGE_SIZE           : '',
                MASKING_YN          : 'Y'
            }

            postAxios({
                    method: 'POST',
                    url: "/comm/jsonProcess_Procedure"
                },
                dataString,
                (response) => {
                    const resultList = response.data.resultData.V_OUT_JAVA_CURSOR;

                    setHistoryData({
                        ...historyData,
                        rowData : resultList
                    });

                    if(resultList.length > 0){
                        setTotalCount(resultList[0].TOTAL_ROW_COUNT);
                    }else{
                        setTotalCount(0)
                    }

                    historyGridApi.sizeColumnsToFit();
                }
                ,
                (e)=>{console.log(e)},
            );
        }
    }, [modal])

    return (
        <div className="d-flex align-items-center he-100 justify-content-center">
            <Button color="primary" className="font-size-sm btn-sm pl-3 pr-3" onClick={toggle}>
                {MULTI_LANG.CHANGE_HISTORY}
            </Button>

            <Modal isOpen={modal} toggle={toggle} className="modal-dialog-centered modal-large noshadow">
                <div className="app-inner-layout__header text-white bg-success">
                    <div className="app-page-title">
                        <div className="page-title-wrapper">
                            <div className="page-title-heading">{MULTI_LANG.HISTORY}</div>

                            <span className="closeBt" onClick={toggle}>{MULTI_LANG.CLOSE}</span>
                        </div>
                    </div>
                </div>
                <ModalBody>
                    <PerfectScrollbar>
                        <div className="container-fluid">
                            <div style={{height:'80.5vh'}} className="data-table">
                                <div style={{height: 'calc(100% - 20px)', width: "100%"}} className="ag-theme-balham">
                                    <AgGridComponent
                                        columnDefs={historyData.columnDefs}
                                        defaultColDef={historyData.defaultColDef}
                                        rowData={historyData.rowData}
                                        onGridReady={onGridReadyForHistory}
                                        pagination={false}
                                        frameworkComponents={historyData.frameworkComponents}
                                        overlayNoRowsTemplate={historyData.overlayNoRowsTemplate}
                                        headerHeight={44}
                                        rowHeight={46}
                                    />
                                    <Pagination
                                        selectComponentClass={Select}
                                        showSizeChanger
                                        defaultPageSize={pageSize}
                                        defaultCurrent={1}
                                        onShowSizeChange={onShowSizeChange}
                                        onChange={onPaginationChange}
                                        total={totalCount}
                                        locale={params.intl}
                                        style={{marginTop:'20px'}}
                                    />
                                </div>
                            </div>
                        </div>
                    </PerfectScrollbar>
                </ModalBody>
            </Modal>
        </div>
    )
}

export default ZZ12_0100_2_ChangeLogPopup;

const chngRenderer = (params) => {
    let thisData = params.data;

    if(thisData.CRE_TYPE === 'U'){
        let beforeData = thisData.BEFORE_DATA;
        if(nullToBlank(beforeData) === ""){
            beforeData = MULTI_LANG.NONE_DATA;
        }

        let returnMessage = multiLangReplace(MULTI_LANG.CHANGE_MESSAGE, [thisData.COL_FILED_NAME, beforeData, thisData.AFTER_DATA]);

        return returnMessage;
    }else {
        return params.value;
    }
}