import React from "react";
//react에서 사용할 수 있는 bootstrap
import {Modal, Button, ModalBody, ModalFooter, ModalHeader} from "reactstrap";

import $ from 'jquery';

import './ModalPopup.css';

import {renderToStaticMarkup, renderToString} from 'react-dom/server'
//alert 이쁘게
import SweetAlert from 'sweetalert-react';
import {nullToBlank} from "../../utils/commUtils";
import {commonLang} from "../../utils/commonLang";

const ModalPopup = ({classes, ...props}) => {
    const {openState, setOpenState, title, content, buttonList, modalType, closeCallback, noOutline, color, changeType="info"} = props;
    const [drawCheck, setDrawCheck] = React.useState(false);
    const [timeStamp, setTimestamp] = React.useState(new Date().getTime());
    let buttonChk = true;
    let buttonHTML = "";
    let timeStaemp = "";

    React.useEffect(()=> {
        setOpenState(openState);
        setTimestamp(new Date().getTime());
    }, [openState]);

    React.useEffect(() => {
        buttonList.map((buttonItem, index) => {
            if(nullToBlank(document.getElementById(timeStamp +"_button_"+ index)) !== ""){
                document.getElementById(timeStamp +"_button_"+ index).addEventListener('click', buttonItem.buttonFunc);
            }
         });
    }, [timeStamp]);
    if(typeof buttonList != 'object'){
        buttonChk = false;
    }else if(buttonList.length == 0){
        buttonChk = false;
    }

    const createButtonFunc = () => {
        let defaultColor = color;
        if(nullToBlank(color) === "") {defaultColor = "primary"}

        if(buttonChk) {
            return (buttonList.map((buttonItem, index) => {
                return (
                    <Button 
                        key={index} 
                        className="w110" 
                        id={timeStamp +"_button_"+ index}
                        color={defaultColor} 
                        outline={!noOutline} 
                        onClick={buttonItem.buttonFunc}>
                        {buttonItem.buttonName}
                        </Button>
                );
            }));
        }else{
            return (
                <Button variant="contained" color="primary" size="large" onClick={closeHandler} className="w110">{commonLang().POPUP_BUTTON_OK}</Button>
            );
        }
    }

    const closeHandler = () => {
        setOpenState(!openState);

        if(typeof closeCallback == "function"){
            closeCallback();
        }
    }

    /*
    <div>
        <Modal isOpen={openState} toggle={closeHandler} className={modalType} size={size}>
            <ModalHeader toggle={closeHandler}>{title}</ModalHeader>
            <ModalBody>
                {content}
            </ModalBody>
            <ModalFooter>
                {createButtonFunc()}
            </ModalFooter>
        </Modal>
    </div>
    */

    return(
            <SweetAlert
                title={''}
                confirmButtonColor=""
                show={openState}
                type={changeType}
                html
                text={
                    renderToString(
                        <div>
                            {content}
                            <div className="btn-line">
                                {createButtonFunc()}
                            </div>
                        </div>)}
                /*
                text={renderToStaticMarkup(
                    <div>
                        {content}
                        <div className="btn-line">
                            {createButtonFunc()}
                        </div>
                    </div>
                )}*/
                showConfirmButton={false}
                showCancelButton={false}
                customClass="closeBt"
                onCancel={closeHandler}
            />
    )
}

const SuccessPopup = ({classes, ...props}) => {
    const {openState, setOpenState, title, content, callback} = props;

    React.useEffect(()=> {
        setOpenState(openState);

        if(openState){
            $(document).off().on('keydown', (event) => {
                if(event.which === 13){
                    closeButton();
                }
            })
        }else{
            $(document).off('keydown');
        }
    }, [openState]);

    const closeButton = () => {
        setOpenState(false);

        if(typeof callback == "function"){
            callback();
        }
    }

    const createButtonFunc = () => {
        return (
            <Button variant="contained" color="primary" size="large" onClick={closeButton}>{commonLang().POPUP_BUTTON_OK}</Button>
        );
    }

    /*
        <div>
            <Modal isOpen={openState} toggle={closeButton} className="Success">
                <ModalHeader toggle={closeButton}>{title}</ModalHeader>
                <ModalBody>
                    {content}
                </ModalBody>
                <ModalFooter>
                    {createButtonFunc()}
                </ModalFooter>
            </Modal>
        </div>
     */
    return(
        <SweetAlert
            title={''}
            confirmButtonColor=""
            show={openState}
            type="success"
            html
            text={renderToString(<div>{content}<div className="btn-line">{createButtonFunc()}</div></div>)}
            showConfirmButton={false}
            showCancelButton={false}
            customClass="closeBt"
            onCancel={closeButton}
        />
    )
}

const WarningPopup = ({classes, ...props}) => {
    const {openState, setOpenState, title, content, sendYn, callback} = props;

    React.useEffect(()=> {
        setOpenState(openState);

        if(openState){
            $(document).off().on('keydown', (event) => {
                if(event.which === 13){
                    closeButton();
                }
            })
        }else{
            $(document).off('keydown');
        }
    }, [openState]);

    const customerButton = () => {
        //setOpenState(!openState);

        closeButton('S');
    }

    const closeButton = (type) => {
        setOpenState(false);

        if(typeof callback == "function"){
            callback(type);
        }
    }

    const createButtonFunc = () => {
        if(sendYn){
            return (
                <>
                    <Button outline color="warning" className="w110" onClick={closeButton}>{commonLang().POPUP_BUTTON_OK}</Button>
                    {/*<Button color="warning" className="w110" onClick={customerButton}>{commonLang().POPUP_BUTTON_CUSTOMER}</Button>*/}
                </>
            );
        }else{
            return (
                <Button outline color="warning" className="w110" onClick={closeButton}>{commonLang().POPUP_BUTTON_OK}</Button>
            );
        }
    }

    /*
        <div>
            <Modal isOpen={openState} toggle={closeButton} className="Warning">
                <ModalHeader toggle={closeButton}>{title}</ModalHeader>
                <ModalBody>
                    {content}
                </ModalBody>
                <ModalFooter>
                    {createButtonFunc()}
                </ModalFooter>
            </Modal>
        </div>
     */
    return(
        <SweetAlert
            title={''}
            confirmButtonColor=""
            show={openState}
            type="warning"
            html
            text={renderToStaticMarkup(
                <div>
                    {content}
                    <div className="btn-line">
                        {createButtonFunc()}
                    </div>
                </div>
            )}
            showConfirmButton={false}
            showCancelButton={false}
            customClass="closeBt"
            onCancel={closeButton}
        />
    )
}

const WarningModal = ({classes, ...props}) => {
    const {openState, setOpenState, title, content, sendYn, callback, buttonList} = props;

    React.useEffect(()=> {
        setOpenState(openState);

        if(openState){
            $(document).off().on('keydown', (event) => {
                if(event.which === 13){
                    closeButton();
                }
            })
        }else{
            $(document).off('keydown');
        }
    }, [openState]);

    const customerButton = () => {
        //setOpenState(!openState);

        closeButton('S');
    }

    const closeButton = (func) => {
        setOpenState(false);

        if(typeof func == "function"){
            func();
        }
    }

    const createButtonFunc = () => {
        if (buttonList.length == 1) {
            return (
                <Button outline color="warning" className="w110" onClick={() => closeButton(buttonList[0].buttonFunc)}>{buttonList[0].buttonName}</Button>
            )
        } else if (buttonList.length == 2){
            return (
                <>
                    <Button outline color="warning" className="w110" onClick={() => closeButton(buttonList[0].buttonFunc)}>{buttonList[0].buttonName}</Button>
                    <Button outline className="CustomerButton CustomerButtonOutline w110" onClick={() => closeButton(buttonList[1].buttonFunc)}>{buttonList[1].buttonName}</Button>
                </>
            )
        } else {
            return (" ");
        }
    }

    return(
        <div>
            <Modal isOpen={openState} toggle={closeButton} className="Warning">
                <ModalHeader toggle={closeButton}>{title}</ModalHeader>
                <ModalBody className="modalMessage">
                    {content}
                </ModalBody>
                <ModalFooter>
                    {createButtonFunc()}
                </ModalFooter>
            </Modal>
        </div>
    )
}


const ErrorPopup = ({classes, ...props}) => {
    const {openState, setOpenState, title, content, sendYn, callback} = props;
    const [tempOpenState, setTempOpenState] = React.useState(true);

    React.useEffect(()=> {
        if(setOpenState){
            setOpenState(openState);

            $(document).off().on('keydown', (event) => {
                if(event.which === 13){
                    closeModalPopup();
                }
            })
        }else{
            $(document).off('keydown');
            setTempOpenState(tempOpenState);
        }
    }, [openState]);

    const customerButton = () => {
        closeModalPopup("S");
    }

    const closeModalPopup = (type) => {
        if(setOpenState){
            setOpenState(false);
        }else{
            setTempOpenState(!tempOpenState);
        }

        if(typeof callback === 'function'){
            callback(type);
        }
    }

    const createButtonFunc = () => {
        if(sendYn){
            return (
                <>
                    <Button outline className="w110" color="danger" onClick={closeModalPopup}>{commonLang().POPUP_BUTTON_OK}</Button>
                    {/*<Button className="w110" color="danger" onClick={customerButton}>{commonLang().POPUP_BUTTON_CUSTOMER}</Button>*/}
                </>
            );
        }else{
            return (
                <Button outline className="w110" color="danger" onClick={closeModalPopup}>{commonLang().POPUP_BUTTON_OK}</Button>
            );
        }
    }

    /*
        <div>
            <Modal isOpen={openState} toggle={closeModalPopup} className="Error">
                <ModalHeader toggle={closeModalPopup}>{title}</ModalHeader>
                <ModalBody>
                    {content}
                </ModalBody>
                <ModalFooter>
                    {createButtonFunc()}
                </ModalFooter>
            </Modal>
        </div>
     */

    return(
        <SweetAlert
            title={''}
            confirmButtonColor=""
            show={openState}
            type="error"
            html
            text={renderToStaticMarkup(
                <div>
                    {content}
                    <div className="btn-line">
                        {createButtonFunc()}
                    </div>
                </div>
            )}
            showConfirmButton={false}
            showCancelButton={false}
            customClass="closeBt"
            onCancel={closeModalPopup}
        />
    )
}

const CustomModal = ({classes, ...props}) => {
    const {openState, setOpenState, title, content, sendYn, callback, buttonList, buttonColor=["warning", "warning"], customClass="Warning", outline=true} = props;

    React.useEffect(()=> {
        setOpenState(openState);

        if(openState){
            $(document).off().on('keydown', (event) => {
                if(event.which === 13){
                    closeButton();
                }
            })
        }else{
            $(document).off('keydown');
        }
    }, [openState]);

    const customerButton = () => {
        //setOpenState(!openState);

        closeButton('S');
    }

    const closeButton = (func) => {
        setOpenState(false);

        if(typeof func == "function"){
            func();
        }
    }

    const createButtonFunc = () => {
        if (buttonList.length == 1) {
            return (
                <Button outline={outline} color={buttonColor[0]} className="w110" onClick={() => closeButton(buttonList[0].buttonFunc)}>{buttonList[0].buttonName}</Button>
            )
        } else if (buttonList.length == 2){
            return (
                <>
                    <Button color={buttonColor[0]} className="w110" onClick={() => closeButton(buttonList[0].buttonFunc)}>{buttonList[0].buttonName}</Button>
                    <Button color={buttonColor[1]} className="CustomerButton CustomerButtonOutline w110" onClick={() => closeButton(buttonList[1].buttonFunc)}>{buttonList[1].buttonName}</Button>
                </>
            )
        } else {
            return (" ");
        }
    }

    return(
        <div>
            <Modal isOpen={openState} toggle={closeButton} className={customClass}>
                <ModalHeader toggle={closeButton}>{title}</ModalHeader>
                <ModalBody className="modalMessage">
                    {content}
                </ModalBody>
                <ModalFooter>
                    {createButtonFunc()}
                </ModalFooter>
            </Modal>
        </div>
    )
}

export { ModalPopup, SuccessPopup, WarningPopup, ErrorPopup, WarningModal, CustomModal };
