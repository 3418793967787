import React, { useState, useEffect } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { Button } from 'reactstrap';

import $ from 'jquery';

import AgGridComponent from './AgGridComponent';
import { EXCEL_DEFAULT_STYLES } from '../../utils/globalValue';
import { commonLang } from '../../utils/commonLang';


let rowType = 0;
let editingModeToggle = false;
let gridApi = null;

const rowClass = {
    "modify-style": function(params) {
        const compareData = params.data;
        let compareFlag = false;

        if (!compareData) return false;

        // 추가인 경우
        if (params.data.ROWTYPE == 2) {
            rowType = 2;
        // 수정인 경우
        } else {
            rowType = 0;
            for (const key in compareData) {
                compareData[key] = compareData[key] === "" ? null : compareData[key];

                if (compareData[key] !== null && compareData[key+"_TEMP"] !== undefined && (typeof compareData[key] === 'object')) {
                    if ( compareData[key].length && compareData[key].length === compareData[key+'_TEMP'].length ) {
                        let changeFlag = false;
                        const vItem = compareData[key].map((item) => item.value )
                        const vItemTemp = compareData[key+'_TEMP'].map((item) => item.value );

                        for ( let v of vItem ) {
                            if (vItemTemp.includes(v)) {
                                changeFlag = false;
                            } else {
                                changeFlag = true;
                                break;
                            }
                        }

                        if (changeFlag) {
                            rowType = 1;
                            compareFlag = true;
                        }

                    } else if (!compareData[key].length) {
                        let changeFlag = false;
                        const itemValue = compareData[key].value;
                        const itemValueTemp = compareData[key+'_TEMP'].value;

                        if (itemValue === itemValueTemp) {
                            changeFlag = false;
                        } else {
                            changeFlag = true;
                            break;
                        }

                        if (changeFlag) {
                            rowType = 1;
                            compareFlag = true;
                        }

                    } else {
                        rowType = 1;
                        compareFlag = true;
                    }
                } else if (compareData[key+"_TEMP"] !== undefined && compareData[key] != compareData[key+"_TEMP"]) {
                        rowType = 1;
                        compareFlag = true;
                }
            }
        }
        return compareFlag;
    },
    "add-style": function(params) {
        const compareData = params.data;
        let compareFlag = false;

        if (!compareData) return false;

        if (compareData.ROWTYPE == 2) {
            compareFlag = true;
        }
        return compareFlag;
    }
}

const AgGridEdit = ({
    columnDefs=[],
    onGridReady,
    frameworkComponents={},
    rowClassRules,
    onRowEditingStopped,
    onRowEditingStarted,
    onRowValueChanged,
    ...props
}) => {
    const [additionRowclassRules, setAdditionRowclassRules] = useState(rowClass);

    useEffect(() => {
        setAdditionRowclassRules((pre) => ({ ...pre,
            ...rowClassRules
        }));
    }, [rowClassRules !== undefined]);

    const handleRowEditingStopped = (params) => {
        if (params.data.ROWTYPE != "2") {
            params.node.setDataValue("ROWTYPE", rowType);
            params.api.refreshCells({ columns: ["ROWTYPE","CANCEL"], force: true });
        }

        if (onRowEditingStopped) onRowEditingStopped(params);

        editingModeToggle = false
    }

    const handleRowEditingStarted = (params) => {
        if (parseInt(params.node.data.ROWTYPE) === 0) {
            params.node.setDataValue("ROWTYPE", 1);
            params.api.refreshCells({ columns: ["ROWTYPE","CANCEL"], force: true });
        }

        if (onRowEditingStarted) onRowEditingStarted(params);

        editingModeToggle = true
    }

    const handleRowValueChanged = (params) => {
        if (onRowValueChanged) onRowValueChanged(params);
    }

    const additionalColumnDefs = [
        {
            field: "ROWTYPE", hide: true, editable: false, suppressNavigable: true,
        },
        {
            field: "MODIFY", headerName: commonLang().BUTTON_MODIFY, cellStyle: {textAlign:'center'}, width:69, minWidth:69, maxWidth:69, 
            colId: 'MODIFY',
            cellRenderer : "ButtonRenderer", suppressNavigable: true, editable : false,
            
        },
        {
            headerName:commonLang().BUTTON_CANCEL, cellStyle: {textAlign:'center'}, headerTooltip: commonLang().BUTTON_CANCEL,
            field: "CANCEL", width:69, minWidth:69, maxWidth:69, cellRenderer: "cancelCellRenderer", 
            colId: 'CANCEL',
            suppressNavigable: true, editable : false, 
        }
    ];

    const additionalFrameworkcomponents = ({
        ButtonRenderer: modifyTag,
        cancelCellRenderer : GridCancelBtnRenderer,
    });

    const handleGridReady = (params) => {
        gridApi = params.api;

        // gridApi.addEventListener('rowEditingStarted', event => {
        //     console.log('AG EDITOR ');
        //     console.log('rowEditingStarted');
        //     return;
        // });
        // gridApi.addEventListener('rowEditingStopped', event => {
        //     console.log('AG EDITOR ');
        //     console.log('rowEditingStopped');
        //     return;
        // });

        if (onGridReady) onGridReady(params);
    }


    return (
        // <AgGridReact
        <AgGridComponent
            onGridReady={handleGridReady}
            columnDefs={[...columnDefs, ...additionalColumnDefs]}
            frameworkComponents={{ ...frameworkComponents, ...additionalFrameworkcomponents }}
            rowClassRules={additionRowclassRules}
            editType="fullRow"
            onRowEditingStarted={handleRowEditingStarted}
            onRowEditingStopped={handleRowEditingStopped}
            onRowValueChanged={handleRowValueChanged}
            suppressKeyboardEvent={(params) => {
                const KEY_ENTER = 13;

                var event = params.event;
                var key = event.which;

                var suppress = key === KEY_ENTER ;
                return suppress;

            }}
            onCellKeyDown={(params) => {
                const KEY_ENTER = 13;

                const { event, api: gridApi } = params
                const { which: key } = event;

                if (key === KEY_ENTER) {
                    const lastColId = gridApi.getEditingCells().filter(v => !v.column.colDef.suppressNavigable ).pop().column.colId;

                    if (lastColId === gridApi.getFocusedCell().column.colId)
                        gridApi.stopEditing();
                    else
                        gridApi.tabToNextCell();
                }
            }}
            suppressColumnVirtualisation={true}
            enableCellChangeFlash={true}
            suppressClickEdit={true}
            {...props}
        />
    );
}

export default AgGridEdit;

const GridCancelBtnRenderer = (props) => {
    // const rowType = parseInt(props.data.ROWTYPE);
    const rowType = parseInt(props.node.data.ROWTYPE);
    const cancelHandler = () => {
        if (rowType == 2) {
            const gridData = [];
            props.api.forEachNode((node, index) => {
                if (node.id !== props.node.id) {
                    gridData.push(node.data);
                }
            });

            props.api.setRowData(gridData);
            gridApi.ensureIndexVisible(props.rowIndex-1, 'bottom');
        } else {
            const originkeys = Object.keys(props.data).filter((key) => key.indexOf( '_TEMP' ) !== -1 );
            originkeys.forEach((key) => {
                props.node.setDataValue( key.replace("_TEMP", ""), props.data[key] );
            });

            props.node.setDataValue("ROWTYPE", 0);

            // 데이터 초기화 ....
            props.api.redrawRows({ rowNodes: [gridApi.getRowNode(props.rowIndex)], });
            gridApi.stopEditing();
        }
    }

    return (
        <>
        {
            rowType !== 0 ?
                <div className="d-flex align-items-center he-100 justify-content-center">
                    <div className="cancel-ico" onClick={() => cancelHandler()}/>
                </div>
            :

            <div className="d-block w-100 text-center"></div>
        }
        </>
    )
}

const modifyTag = (props) => {
    const handleChangeMode = () => {
        if (props.node.data.ROWTYPE != 2) {
        }

        let cols = props.columnApi.getAllColumns().
            filter(v =>
                v.colDef.editable && !v.colDef.suppressNavigable );


        const index = props.rowIndex;

        let col = [];
        if (!cols.length) {
            col = props.columnApi.getAllColumns()[0]
        } else {
            col = cols[0];
        }
        props.api.setFocusedCell(index, col.colId);
        props.api.startEditingCell({
            rowIndex: index,
            colKey:  col.colId,
        });

    }

    return (
        // <div className="d-flex align-items-center he-100 justify-content-center">
        //     <Button className="btn-icon btn-icon-only" color="link" onClick={handleChangeMode}>
        //         <Ionicon fontSize="26px" color="#333" icon={'ios-create-outline'} />
        //     </Button>
        // </div>
        <div className="d-flex w-100 he-100 align-items-center justify-content-center">
            <Button className="modify-ic" color="link" onClick={() => handleChangeMode()}></Button>
        </div>
    )
}
