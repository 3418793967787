import React, { Fragment } from 'react';
import {
  Modal,
} from 'reactstrap';

import PerfectScrollbar from 'react-perfect-scrollbar';

import tutorial00 from '../../assets/utils/images/member/tutorial_img00.jpg';


import AppHeader from '../../Layout/AppHeader'
import AppSidebar from '../../Layout/AppSidebar';
import { injectIntl } from 'react-intl';
import { TUTORIAL_LANG } from './Tutorial_lang';
import parse from 'html-react-parser';

// Layout
// import BoxHeader from '../BoxHeader/';
// import BoxFooter from '../BoxFooter/';
const TutorialStart = ({ intl, open, onClickNextButton }) => {
  const { formatMessage } = intl;
  const MULTI_LANG = TUTORIAL_LANG(formatMessage);

  return (
    <>
      <Modal
        // centered={true}
        // show={this.show}
        isOpen={open}
        modaltoggle={onClickNextButton}
        className="modal-large2 noshadow"
        backdrop="static"
      >
        
        <div className="modal-wrapper tutorial-wrapper">
          <div className="modal-body">
            <PerfectScrollbar>
              <div className="container-fluid">
                <div className="tutorial-wrap">
                  <div>
                      <img width={1577} height={810} src={tutorial00} alt="튜토리얼1"/>
                        {/* <h1 className="title">{MULTI_LANG.LABEL_TITLE_NEW_WINGS_CMS}</h1> */}
                        <h1 className="title">{parse(MULTI_LANG.LABEL_TITLE_NEW_WINGS_CMS)}</h1>
                      {/* <h1 className="title">새로워진<br/>
                      <b>WINGS CMS</b>를 소개합니다.</h1> */}
                      <button className="w140 bg-primary text-white go-pop2 py-2" onClick={onClickNextButton} ><b>GO!</b></button>
                    </div>
                </div>
              </div>
            </PerfectScrollbar>
          </div>
        </div>
        
      </Modal>
    </>
  );
}

export default injectIntl(TutorialStart);
