import React, {Fragment} from 'react';

import { Radio, FormControl, RadioGroup, FormControlLabel, FormLabel } from '@material-ui/core'

import { CustomInput } from 'reactstrap';

// 통합
function LabelRadio(props) {
    const { label, color, formControlLabelClassName, radioClassName, value, labelPlacement="end", ...otherProperties } = props
    return (
        <FormControlLabel 
            className={formControlLabelClassName}
            labelPlacement={labelPlacement} 
            value={value} 
            label={label} 
            control={<Radio className={radioClassName} color={color} {...otherProperties}/>}
        />
    );
}

function RadioButtonGroup( props ) {
    const {title, formClassName, formLabelClassName, formControlLabelClassName, radioGroupClassName, radioClassName, radioButtons, onChange, value, row, name, ...ButtonGroupOtherProperties} = props;
    let radioButtonTag = [];
    radioButtons.forEach( (radioButton) => {
        let { key, label, value, ...buttonOtherProperties } = radioButton;
        radioButtonTag.push( 
            <FormControlLabel
                value={value}
                key={key}
                label={label}
                className={formControlLabelClassName}
                control={
                    <Radio className={radioClassName} {...buttonOtherProperties}/>
                }
            /> 
        );
    })

    return (
        <FormControl component="fieldset" className={formClassName}>
            <FormLabel component="legend" className={formLabelClassName}>{title}</FormLabel>
            <RadioGroup
                value={value}
                onChange={onChange}
                row={row}
                name={name}
                className={radioGroupClassName}
                {...ButtonGroupOtherProperties}
            >   
                {radioButtonTag}
            </RadioGroup>
        </FormControl>
    )
}

const BootstrapGroupRadio = props => {
    let { radioClass, onChangeFunc, options, addData, rdName, value, id, ...etc } = props;

    if (!options) {
        options = [];
    }

    const onChangeHandler = event => {
        if (onChangeFunc) {
            onChangeFunc(event);
        }
    }

    return (
        <Fragment>
            {
                addData &&
                <CustomInput 
                    type="radio" 
                    id={id == undefined ? addData.key : id+addData.key}
                    name={rdName}
                    label={addData.label}
                    value={addData.value}
                    className={radioClass}
                    key={addData.key}
                    onChange={(e) => onChangeHandler(e)}
                    checked={value==addData.value}
                    {...etc}
                />
            }
            {
                 options.map((item,index) => (
                    <CustomInput 
                        type="radio" 
                        id={id == undefined ? item.key : id+item.key}
                        name={rdName}
                        label={item.label}
                        value={item.value}
                        className={radioClass}
                        key={item.key}
                        onChange={(e) => onChangeHandler(e)}
                        checked={value==item.value}
                        {...etc}
                    />
                ))
            }
        </Fragment>
    )
}
export {
    LabelRadio,
    
    RadioButtonGroup, 
    TopLabelRadio, 
    StartLabelRadio, 
    BottomLabelRadio, 
    EndLabelRadio,
    BootstrapGroupRadio
};

// DDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDD
// DDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDD
function TopLabelRadio(props) {
    const { label, color, formControlLabelClassName, radioClassName, value, ...otherProperties } = props
    return (
        <FormControlLabel 
            className={formControlLabelClassName}
            labelPlacement="top" 
            value={value} 
            label={label} 
            control={<Radio className={radioClassName} color={color} {...otherProperties}/>}
        />
    );
}

function StartLabelRadio(props) {
    const { label, formControlLabelClassName, radioClassName, color, value, ...otherProperties } = props
    return (
        <FormControlLabel 
            className={formControlLabelClassName}   
            labelPlacement="start" 
            value={value} 
            control={<Radio className={radioClassName} color={color} {...otherProperties}/>}
            label={label} 
        />
    );
}

function BottomLabelRadio(props) {
    const { label, formControlLabelClassName, radioClassName, color, value, ...otherProperties } = props
    return (
        <FormControlLabel 
            className={formControlLabelClassName}
            labelPlacement="bottom" 
            value={value} 
            label={label} 
            control={<Radio className={radioClassName} color={color} {...otherProperties}/>}
        />
    );
}

function EndLabelRadio(props) {
    const { label, formControlLabelClassName, radioClassName, color, value, ...otherProperties } = props
    return (
        <FormControlLabel 
            className={formControlLabelClassName}
            labelPlacement="end" 
            value={value} 
            control={<Radio className={radioClassName} color={color} {...otherProperties}/>}
            label={label} 
        />
    );
}
// DDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDD
// DDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDD
