import React, {Fragment} from 'react';

import {VerticalTimeline, VerticalTimelineElement} from 'react-vertical-timeline-component';

import PerfectScrollbar from 'react-perfect-scrollbar';
import {injectIntl} from "react-intl";
import {Link, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {postAxios} from "../../../../utils/commAxios";
import {HEADER_LANG} from "../Header_lang";
import {SimpleTooltip} from "../../../../components/Tooltip/Tooltips";

const RECENT_LIST_PACKAGE = "PG_ZZ03_0200.PR_USER_PROC_TRACE_SEL";

const HeaderRecent = (props) => {
    const [ctxHeight, setCtxHeight] = React.useState(0);
    const [reacentList, setRecentList] = React.useState([]);
    const {isOpen, intl, ssUserInfo, onClickItem} = props;
    const {formatMessage} = intl;
    const MULTI_LANG = HEADER_LANG(formatMessage);

    React.useEffect(() => {
        if(document.getElementsByClassName('vertical-timeline').length != 0) {
            const viewHeight = window.innerHeight;
            const headerHeight = document.getElementsByClassName('app-header')[0].clientHeight;

            const contentHeight = viewHeight - headerHeight - 250;

            const itemHeight = document.getElementsByClassName('vertical-timeline')[0].clientHeight + 40;

            if (itemHeight > contentHeight) {
                setCtxHeight(contentHeight);
            } else {
                setCtxHeight(itemHeight);
            }
        }else{
            setCtxHeight(80);
        }
    }, [reacentList]);

    React.useEffect(() => {
        if(isOpen){
            getRecentList();

            let intervalTime = setInterval(() => {
                getRecentList();
            }, 60000);

            return () => {
                clearInterval(intervalTime);
            }
        }
    }, [isOpen, ssUserInfo]);

    const getRecentList = () => {
        const dataString = {
            'PROCEDURE_OR_TABLE_NAME'   : RECENT_LIST_PACKAGE,
        }

        postAxios({
                method: 'POST',
                url: "/comm/jsonProcess_Procedure"
            },
            dataString,
            (response) => {
                setRecentList(response.data.resultData.V_OUT_JAVA_CURSOR);
            },
            (e)=>{console.log("에러 "+ e)
            }, '', true);
    };

    const recentClickHandler = (traceNo) => {
        onClickItem(traceNo);
        // props.history.push({
        //     pathname : '/app/zz/zz03_0210',
        //     state : {traceNo : traceNo}
        // });       
    }

    return (
        <Fragment>
            <div className="scroll-area-sm" style={{height:ctxHeight}}>
                <PerfectScrollbar>
                    <div className="p-3" style={{height:'100%'}}>
                        {
                            reacentList.length == 0 ?
                            <div style={{height:'100%', display:'table', margin:'0 auto'}}>
                                <div style={{display:'table-cell', verticalAlign:'middle'}}>
                                    {MULTI_LANG.HEADER_NODATA}
                                </div>
                            </div>
                            :
                            <VerticalTimeline layout="1-column" className="vertical-without-time">
                                {
                                    reacentList.map((item, index) => (
                                        <VerticalTimelineElement
                                            className="vertical-timeline-item"
                                            icon={<i className="badge badge-dot badge-dot-xl"
                                                     style={{backgroundColor: item.ICON_COLOR}}> </i>}
                                            key={index}
                                        >
                                            <h4 className="timeline-title">{item.USER_PROC_TRACE_MESSAGE}</h4>
                                            <SimpleTooltip title={item.USER_NAME +" - "+ item.USER_PROC_TRACE_CODE_NAME} position="left" onClick={() => {recentClickHandler(item.TRACE_NO)}}>
                                                <dl className="timeline-info" style={{cursor:'pointer'}} >
                                                    <dt>{item.USER_NAME}</dt>
                                                    <dd>{item.USER_PROC_TRACE_CODE_NAME}</dd>
                                                </dl>
                                            </SimpleTooltip>
                                        </VerticalTimelineElement>
                                    ))
                                }
                            </VerticalTimeline>
                        }
                    </div>
                </PerfectScrollbar>
            </div>
        </Fragment>
    )
}

const mapStateToProps = state => {
    return({
        propertyNo : state.PropertyState.propertyNo,
        propertyName : state.PropertyState.propertyName,
    });
}

export default withRouter(injectIntl(connect(mapStateToProps)(HeaderRecent)));
