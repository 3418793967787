import React from "react";

export const TUTORIAL_LANG = (formatMessage) => {
    let MULTI_LANG = {
        // LABEL_TITLE_TUTORIAL                    : formatMessage({id: "tutorial.title.tutorial", defaultMessage: "CMS 대시보드에서 매출 현황을 한눈에 확인!"}),
        CHECKBOX_LABEL_STOP_VIEW                : formatMessage({id: "tutorial.label.tutorialStopView", defaultMessage: "튜토리얼 그만 보기"}),
        LABEL_TITLE_NEW_WINGS_CMS               : formatMessage({id: "tutorial.title.newWingsCms", defaultMessage: "새로워진<br/><b>WINGS CMS</b>를 소개합니다."}),
        LABEL_TITLE_EXIT                        : formatMessage({id: "tutorial.title.exit", defaultMessage: "튜토리얼을 종료합니다."}),
        LABEL_TITLE_SUB_EXIT                    : formatMessage({id: "tutorial.title.subExit", defaultMessage: "다시 보시려면 메뉴 별<br/>[튜토리얼] 에서 확인 가능합니다."}),
        BUTTON_LABEL_MENU_STOP_VIEW             : formatMessage({id: "tutorial.button.stopView", defaultMessage: "해당 메뉴 <BR/>튜토리얼 그만보기"}),
        BUTTON_LABEL_ALL_MENU_STOP_VIEW         : formatMessage({id: "tutorial.button.allStopView", defaultMessage: "모든 메뉴 <BR/>튜토리얼 그만보기"}),
    }
    
    return MULTI_LANG;
};