import {getSessionInfo, nullToBlank} from "../utils/commUtils";

const sessionInfo = getSessionInfo();

export const initialState = {
    propertyNo      : (nullToBlank(sessionInfo.SS_PMS_NO) != "") ? sessionInfo.SS_PMS_NO : "",
    propertyName    : (nullToBlank(sessionInfo.SS_PMS_NAME) != "") ? sessionInfo.SS_PMS_NAME : "",
    changeTime      : "",
    sessionTimeout  : 240,
    sessionExpirationTime : new Date().setMinutes(new Date().getMinutes() + 240),
    menuChangeTime  : "",
    searchFilterOpen : false,
    notReadYn       : false,
    prsnUrl             : "",
    prsnSystemCode      : "",
    prsnContentType     : "",
    prsnApiKey          : "",
    prsnVendorId        : "",
    prsnEchoToken       : "",
    prsnApiYn           : "N",
    hotelInfo           : "11|11",
    hotelCode           : ""
};
export const PROPERTY_NO = "Layout/PROPERTY_NO";
export const COOKIE_CHANGE_TIME = "Layout/COOKIE_CHANGE_TIME";
export const MENU_CHANGE_TIME = "Layout/MENU_CHANGE_TIME";
export const SEARCH_FILTER_OPEN = "Layout/SEARCH_FILTER";
export const NOT_READ_NOTICE = "Layout/NOT_READ_NOTICE";
export const PRSN_API_INFO = "Layout/PRSN_API_INFO";
export const SESSION_TIME_OUT = "Layout/SESSION_TIME_OUT";
export const SESSION_EXPIRATION_TIME = "Layout/SESSION_EXPIRATION_TIME"

const propertyState =  (state = initialState, action) => {
    switch (action.type){
        case PROPERTY_NO:
            return {
                ...state,
                propertyNo : action.propertyNo,
                propertyName : action.propertyName
            };
        case COOKIE_CHANGE_TIME :
            return {
                ...state,
                changeTime : action.changeTime
            }
        case MENU_CHANGE_TIME :
            return {
                ...state,
                menuChangeTime : action.menuChangeTime
            }
        case SEARCH_FILTER_OPEN :
            return {
                ...state,
                searchFilterOpen: action.searchFilterOpen
            }
        case NOT_READ_NOTICE:
            return {
                ...state,
                notReadYn : action.notReadYn
            };
        case PRSN_API_INFO:
            return {
                ...state,
                prsnUrl: action.prsnUrl,
                prsnSystemCode: action.prsnSystemCode,
                prsnContentType: action.prsnContentType,
                prsnApiKey: action.prsnApiKey,
                prsnVendorId: action.prsnVendorId,
                prsnEchoToken: action.prsnEchoToken,
                prsnApiYn: action.prsnApiYn,
                hotelInfo: action.hotelInfo,
                hotelCode: action.hotelCode
            };
        case SESSION_TIME_OUT:
            return {
                ...state,
                sessionTimeout : action.sessionTimeout
            };
        case SESSION_EXPIRATION_TIME:
            return {
                ...state,
                sessionExpirationTime : action.sessionExpirationTime
            };
        default :
            return state;
    }
}

export default propertyState;
