import React, {useRef, useEffect, useState} from 'react';
import { Label, Input } from 'reactstrap';
import {fileUpload} from "../../utils/commAxios";
import { injectIntl } from 'react-intl';
import { IMGATTACH_LANG } from './ImgAttach_lang';

const ACCEPT_EXT = [".jpg", ".png"];
const MAX_SIZE = 5242880;
let sendedFiles = [];

const ImgAttach = injectIntl(({ multiple=false, intl }) => {
    const {formatMessage} = intl;
    const MULTI_LANG = IMGATTACH_LANG(formatMessage);

    const inputEl = useRef(null);
    const [imgUrl, setImgUrl] = useState([]);

    const handleSendFiles = event => {
        const params = {parameter : "TEST"}

        fileUpload(sendedFiles, (result) => {
            // console.log(result);
            // console.log("첨부파일 번호 : " + result.data.resultData.V_OUT_JSON_VALUE.out_message);
        }, (error) => {
            console.log("Error");
            console.log(error);
        }, params);
    }
  
    const addImgTagValue = () => {
        const files = inputEl.current.files;
        
        if (multiple) {
            sendedFiles = [ ...sendedFiles, ...files ] 
        } else {
            sendedFiles = [ ...files ] 
            setImgUrl([]);
        }
        
        if (!isValidFile(sendedFiles)) return;

        Object.keys(files).forEach((key, index)=>{
            const file = files[key]
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                setImgUrl( (prev) => [...prev, reader.result ] );
            }; 
        });
    }

    const handleDeleteFile = (index) => () => {
        setImgUrl( [...imgUrl.slice(0,index), ...imgUrl.slice(index+1)]  );        
    }
    
    const handleOpenFileSelectPopup = event => inputEl.current.click();
  
    const handleChangeImg = (event) => {
        addImgTagValue(event);
    }
  
    const isValidFile = (files) => {
        const badFilePath = inputEl.current.value;

        if (files.length === 0) {return false;}

        const extIndex = badFilePath.lastIndexOf('.');
        const fileExt = badFilePath.substring(extIndex).toLowerCase();
        const findIndex = ACCEPT_EXT.find( value => value === fileExt );
        if (findIndex === undefined) {
            alert( MULTI_LANG.VALIDATE_MESSAGE_NOT_UPLOAD_EXT );
            return false;
        }

        const isNotVaildFileSize = files.find( file => file.size > MAX_SIZE );
        if (isNotVaildFileSize) {
            alert( MULTI_LANG.VALIDATE_MESSAGE_NOT_UPLOAD_SIZE );
            return false;
        }
        return true;
    }

    return (
        <>
            {imgUrl.map( (img, index) => <Image src={img} key={index} index={index} onDelImg={handleDeleteFile} /> )}
            <div>
                <input type="button" value="버튼 " onClick={handleOpenFileSelectPopup} />
                <input type="button" value="전송" hidden={imgUrl.length===0?true:false} onClick={handleSendFiles} />
                <input type="file" alt="다운로드 버튼" accept={ACCEPT_EXT} hidden={true} ref={inputEl} multiple={multiple} onChange={handleChangeImg} />
            </div>
        </>
    );
});

export {ImgAttach};

const Image = ({ src, index, onDelImg }) => (
    <div style={{ display: "inline-block", width:"250px", height:"250px"}} >
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }} > 
            <div>
                <img width={200} alt="이미지" src={src} />
            </div>
            <div>  
                <i className="pe-7s-close-circle font-weight-bold mr-2 ml-2" onClick={onDelImg(index)}></i>
            </div>
        </div>
    </div>
);