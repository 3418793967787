import React, {Component} from 'react';

import { Home as HomeIcon
       , MoveToInbox as InboxIcon
       , Assessment as AssessmentIcon
       , Queue as QueueIcon
       , Filter9Plus as Filter9PlusIcon
       , GridOn as GridOnIcon
       , Build as BuildIcon
       , NotificationImportant as NotificationIcon
       , Input as InputIcon
       , Stars as StarsIcon
       , Close as CloseIcon
       , CheckCircle as CheckCircleIcon
       , Warning as WarningIcon
       , Error as ErrorIcon
       , Info as InfoIcon
       , Mail as MailIcon
       , TagFaces as TagFacesIcon
       , Face as FaceIcon
       , Phone as PhoneIcon
       , Favorite as FavoriteIcon
       , PersonPin as PersonPinIcon
       , Help as HelpIcon
       , ThumbDown as ThumbDown
       , ThumbUp as ThumbUp
       , Add as AddIcon
       , Navigation as NavigationIcon
       , Delete as DeleteIcon
       , Create as CreateIcon
       , AttachFile as AttachFileIcon
} from "@material-ui/icons";

const iconList = {
    HomeIcon: HomeIcon,
    AssessmentIcon : AssessmentIcon,
    InboxIcon : InboxIcon,
    QueueIcon : QueueIcon,
    Filter9PlusIcon : Filter9PlusIcon,
    GridOnIcon : GridOnIcon,
    BuildIcon : BuildIcon,
    NotificationIcon : NotificationIcon,
    InputIcon : InputIcon,
    StarsIcon : StarsIcon,
    CloseIcon : CloseIcon,
    CheckCircleIcon : CheckCircleIcon,
    WarningIcon : WarningIcon,
    ErrorIcon : ErrorIcon,
    InfoIcon : InfoIcon,
    MailIcon : MailIcon,
    TagFacesIcon : TagFacesIcon,
    FaceIcon : FaceIcon,
    PhoneIcon : PhoneIcon,
    FavoriteIcon : FavoriteIcon,
    PersonPinIcon : PersonPinIcon,
    HelpIcon : HelpIcon,
    ThumbDown : ThumbDown,
    ThumbUp : ThumbUp,
    AddIcon : AddIcon,
    NavigationIcon : NavigationIcon,
    DeleteIcon : DeleteIcon,
    CreateIcon : CreateIcon,
    AttachFileIcon : AttachFileIcon
}

const Icons = ({classes, ...props})  => {
    let Component = null;
    let {className, tag, body} = props;

    if(className != null && className != 'undefined') {
        Component = React.createElement(iconList[tag], {className: className});
    }else{
        Component = React.createElement(iconList[tag]);
    }

    return Component;
}

export { Icons };