import React, {useState, useEffect, useRef} from "react";
import Slider from "react-slick";

import {Modal, CustomInput} from "reactstrap";

import errorImg from "../../assets/utils/images/member/tutorial_img01.jpg";

import PerfectScrollbar from "react-perfect-scrollbar";

import {commonRequest} from "../../utils/commAxios";
import {injectIntl} from "react-intl";
import {TUTORIAL_LANG} from "./Tutorial_lang";

const TutorialMain = ({ tutorialMst, intl, open, onClickCloseButton}) => {
    const { formatMessage } = intl;
    const MULTI_LANG = TUTORIAL_LANG(formatMessage);

    // const TUTORIAL_MASTER_DTL_LIST_SEL = 'PG_ZZ11_0401.PR_TB_ZZ_TUTORIAL_DTL_LIST_SEL';
    // const ATTACH_FILES_LIST_SEL = 'PG_ZZ11_0401.PR_TB_ZZ_ATTACH_FILES_LIST_SEL';
    const ATTACH_FILES_LIST_SEL = 'PG_ZZ11_0401.PR_FILES_SEL_BY_PAGE_NO';
    const FILE_DOWNLOAD_URL = process.env.REACT_APP_BACKEND_UTILS_HOST+"/utils/file_download/";

    const [tutorailDtl, setTutorailDtl ] = useState([]);

    const [stopView, setStopView] = useState(false);
    const handleCheckStopView = v => {
        setStopView(v.target.checked);
    }

    const handleClose = () => {
        onClickCloseButton(stopView);
    }

    const [totalCount, setTotalCount] = useState(0);
    const [currentCount, setCurrentCount] = useState(0);

    const refMain = useRef(null);
    const refImage = useRef([]);

    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        beforeChange: (cur, next) => { 
            if (next < 0) return;
            
            setTotalCount(tutorailDtl[next].length);
            setCurrentCount(1);

            refImage.current[next].slickGoTo(0);
        },
        customPaging: (i) => {
            return (
            <div>
                {tutorailDtl[i][0].STEP_NAME}
            </div>
            );
        },
    };
    const settings2 = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        beforeChange: (cur, next) => { 
            setCurrentCount(next + 1);
        }
    };

    useEffect(() => {
        if (open === false) {
            refImage.current = [];
            // setTutorialAttr({ ...initTutorialAttr });
            // setRowData([]);
            // setImages({ ...initImage });
            return;
        }

        const form = {
            PROCEDURE_OR_TABLE_NAME: ATTACH_FILES_LIST_SEL,
            PAGE_NO: tutorialMst.PAGE_NO
        };

        commonRequest(result => {
            const items = result.data.resultData.V_OUT_JAVA_CURSOR;
            if (items.length <= 0) return;

            const fileInfomation = result.data.resultData.V_OUT_JAVA_CURSOR;
            
            const responseValue = fileInfomation.map(file => {
                if (file.ATTC_FILE_DTL_NO === null) {
                    return {
                        ATTC_FILE_DTL_NO: file.ATTC_FILE_DTL_NO,
                        URL: errorImg
                    }
                }
                return {
                    ATTC_FILE_DTL_NO: file.ATTC_FILE_DTL_NO,
                    URL: `${FILE_DOWNLOAD_URL}?PROCEDURE_OR_TABLE_NAME=PG_ZZ00_FILE_UPLOAD.PR_TB_ZZ_ATTACH_FILES_SEL&ATTC_FILE_NO=${file.ATTC_FILE_NO}&ATTC_FILE_DTL_NO=${file.ATTC_FILE_DTL_NO}`,
                };
            });

            const bbb = fileInfomation.map(item => {
                return {...item, URL: responseValue.find(v => v.ATTC_FILE_DTL_NO === item.ATTC_FILE_DTL_NO).URL};
            });

            const groupedTutorialDtl = groupBy(bbb, 'TURORIAL_DTL_NO');
            const valueA = Object.values(groupedTutorialDtl);
            setTutorailDtl(valueA);
            setTotalCount(valueA[0].length);
            setCurrentCount(1);
            
        }, form);
    }, [open]);

    useEffect(() => {
        if (tutorailDtl.length === 0)  return;
        refMain.current.slickGoTo(0);
    }, [JSON.stringify(tutorailDtl)]);

    return (
        <Modal
            centered={true}
            // show={this.show}
            isOpen={open}
            modaltoggle={handleClose}
            className="modal-large2 noshadow"
            backdrop="static"
        >
            <div className="app-inner-layout__header text-white bg-primary tutorial-pop">
                <div className="app-page-title">
                    <div className="page-title-wrapper">
                        <span className="ttr-page w50">{currentCount}/{totalCount}</span>
                        <div className="page-title-heading">{tutorialMst.SUBJECT}</div>
                        <div className="float-right mr-5">
                            <CustomInput
                                inline
                                type="checkbox"
                                id="stopView"
                                name="daySelect"
                                label={MULTI_LANG.CHECKBOX_LABEL_STOP_VIEW}
                                className="mr-2 vertical-middle"
                                checked={stopView}
                                onChange={handleCheckStopView}
                            />
                        </div>
                        <span className="closeBt" onClick={handleClose}>닫기</span>
                    </div>
                </div>
            </div>

            <div className="modal-wrapper tutorial-wrapper">
                <div className="modal-body">
                    <PerfectScrollbar>
                        <div className="container-fluid">
                            <div className="tutorial-wrap">

                                {tutorailDtl.length === 0 && 
                                <Slider {...settings}>
                                    <img style={{maxWidth: '1577px', maxHeight: '667px' }}  src={errorImg} />
                                </Slider>
                                }

                                {tutorailDtl.length !== 0 && <Slider ref={refMain} {...settings}>
                                
                                    {(tutorailDtl.map((item, index) => {
                                        return (
                                            <div className="slider2">
                                                <Slider ref={(ref) => { refImage.current[index] = ref }} {...settings2}>
                                                {item.map(i => {
                                                return (
                                                    <div>
                                                    <img style={{maxWidth: '1577px', maxHeight: '667px' }} src={`${i.URL}`} alt="튜토리얼1"/>
                                                    </div>
                                                );
                                                })}
                                            </Slider>
                                        </div>
                                        );
                                    }))}
                                
                                </Slider>}

                            </div>
                        </div>
                    </PerfectScrollbar>
                </div>
            </div>
        </Modal>
    );
}

export default injectIntl(TutorialMain);

const groupBy = (items, divAttr)  => {
    return items.reduce((r, a) => {
        r[a[divAttr]] = [...r[a[divAttr]] || [], a];
        return r;
    }, {})
}