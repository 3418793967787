/**
 * 파일명       : zz10_0301_AutoComplate.js
 * 파일 설명	: 호텔 계약 리스트 그리드 무료 채널 AutoComplete
 * 시스템 구분	: ZZ
 *
 * Ver 작성자  일자         내용         비고
 * --- ------ ---------- ---------- --------------------------
 * 1.0  설대형 2020.01.03  최초 작성
*/

import React, {Component} from "react";
import $ from 'jquery';
import {BootstrapAutoComplete } from "../../../../components/Autocomplete/Autocomplete";
import {commonLang} from "../../../../utils/commonLang";
import {splitFreeChannel} from "../zz10_0301";

class ZZ10_0301_AutoComplate extends Component {
    constructor(props) {
        super(props);

        const freeChannel = splitFreeChannel(props.value);

        this.state = {
            selectedChannel: freeChannel,
            open: false,
        };
    }

    getValue() {
        let value = '';
        const channels = this.state.selectedChannel.filter(child => child.value !== '');
        channels.forEach(item => {
            value += `${item.value}##${item.label}$$`;
        });

        return value.slice(0, -2);
    }

    focusIn() {
        this.setState({
            open: true
        });

        return true;
    }

    focusOut() {
        this.setState({
            open: false
        });

        return true;
    }

    isPopup() {
        return false;
    }

    render() {
        const AutoSelectChange = name => value => {
            this.setState({
                selectedChannel: value
            });
        }

        const AutoClose = id => {
            $("#"+id).closest('.ag-cell').css('overflow', 'hidden');
            $("#"+id).closest('.ag-center-cols-viewport').css('overflow-y', 'hidden');
        }

        const AutoOpen = id => {
            $("#"+id).closest('.ag-cell').css('overflow', 'visible');
            $("#"+id).closest('.ag-center-cols-viewport').css('overflow-y', 'visible');
            $("#"+id).css("line-height", "normal");
        }

        return (
            <BootstrapAutoComplete
                id="autoComplate"
                options={this.props.names}
                selectList={this.state.selectedChannel}
                onChangeFunc={AutoSelectChange("freeChannel")}
                placeholder=""
                multiple={true}
                includeSelectAll={true}
                includeFilter={true}
                keepOpen={true}
                selectAllText={commonLang().SELECT_MESSAGE_ALL_SELECTED}
                dropdownHeight={200}
                onOpen={AutoOpen.bind(this, "autoComplate")}
                onClose={AutoClose.bind(this, "autoComplate")}
                open={this.state.open}
            />
        );
    }
}

export default ZZ10_0301_AutoComplate;