import React from 'react';
import { Dialog, Button, DialogContent, DialogActions, DialogTitle, IconButton, Typography } from '@material-ui/core';
import { withStyles } from "@material-ui/styles";
import { Icons } from '../../../components/Icons/Icons';

const DynamicPopup = ({classes, ...props}) => {
    let {title, actionComponent, open, onOpen, onClose, childrenDialogContent, childrenDialogActions, TransitionComponent} = props;

    return (
        <>
            {actionComponent}
            <Dialog 
                open={open}
                onClose={onClose}
                disableBackdropClick={false}
                TransitionComponent={TransitionComponent}
            >
                <DialogTitleClose onClose={onClose}> {title} </DialogTitleClose>
                <DialogContent dividers>
                    {childrenDialogContent} 
                </DialogContent>
                <DialogActions>
                    {childrenDialogActions}
                </DialogActions>
            </Dialog> 
            
        </>
    )
}

const stylesButton = theme => ({
    root: {
      margin: 0,
      padding: 16,
      // padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: 8,
      top: 8,
      color: "lightGray"
      // right: theme.spacing(1),
      // top: theme.spacing(1),
      // color: theme.palette.grey[500],
    },
});
  
const DialogTitleClose = withStyles(stylesButton)(props => {
    const { children, classes, onClose } = props;
    return (
      <DialogTitle disableTypography className={classes.root}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <Icons tag="CloseIcon" />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
});

export default DynamicPopup;