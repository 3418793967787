import React from 'react';

import { AgGridReact } from "ag-grid-react";
import $ from 'jquery';
import {nullToBlank} from "../../utils/commUtils";
import icoWarning4 from '../../assets/utils/images/icon/ico-warning_4.png';
import {commonLang} from "../../utils/commonLang";

// let gridApi = null;

const AgGridComponent = props => {
    const {onGridReady, headerFixed, onGridSizeChanged, offsetAdd=0, horizonMiddle=false, overlayNoRowsTemplate, initBtn = 'N', setInitBtn, ...other} = props;
    const [fixedYn , setFixedYn] = React.useState(false);
    const commLang = commonLang();
    const parse = require('html-react-parser');
    let tempHtml = `<div class="d-flex justify-content-center align-items-center" style="padding-top:20px;"><div class="warning text-center"><img src="${icoWarning4}" alt="warning icon" /><h5 class="menu3-header-title">${commLang.GRID_NODATA_HTML_MSG1}</h5><h6 class="menu3-header-subtitle mt-0">${commLang.GRID_NODATA_HTML_MSG2} ${commLang.GRID_NODATA_HTML_MSG3}</h6></div></div>`;

    const [gridApi, setGridApi] = React.useState(null);

    const gridReadyFunc = (params) => {
        setGridApi(params.api);

        const scrollArea = $(".app-container").children('div').children('div')[0];
        let scrollElement =  $(".ag-body-horizontal-scroll-viewport");
        let scrollContainerElement =  $(".ag-body-horizontal-scroll");

        if(headerFixed) {
            $(scrollArea).off().on('scroll', function (e) {
                //그리드 존재하지 않으면 스크롤 이벤트 제거(0214)
                if ($(".ag-theme-balham").length < 1) {
                    $(this).off();
                    return;
                }

                if ($(".spaceAdd").length < 1) {
                    $(params.api.gridPanel.eGui).prepend('<div class="spaceAdd"></div>')
                }

                let headerHeight = $(".app-header").height();

                //let gridWidth = params.columnApi.columnController.bodyWidth;
                let gridWidth = $(".ag-theme-balham").width();
                // 그리드 레이아웃을 넘어가는 경우 넘어가는 부분은 안보이게 처리 (0204)
                let gridLayerWidth = gridWidth > params.columnApi.columnController.viewportRight ? gridWidth : params.columnApi.columnController.viewportRight;
                //let gridLayerWidth = params.columnApi.columnController.viewportRight;

                if ($(params.api.gridCore.eGui).offset().top < headerHeight) {
                    setFixedYn(true);
                    $(".spaceAdd").css("height", $(params.api.headerRootComp.eGui).height() + 3);
                    $(params.api.headerRootComp.eGui).css("position", "fixed");
                    $(params.api.headerRootComp.eGui).css("top", headerHeight);
                    // 그리드 셀 머지 사용시 머지된 셀이 헤더칼럼을 덮어버리는 현상을 해결하기 위해서 1 에서 2로 변경
                    $(params.api.headerRootComp.eGui).css("z-index", 2);
                    $(params.api.headerRootComp.eGui).css('width', gridWidth);

                    // 그리드 레이아웃을 넘어가는 경우 넘어가는 부분은 안보이게 처리 (0204)
                    $(params.api.headerRootComp.eGui).css('max-width', gridLayerWidth);

                } else {
                    setFixedYn(false);
                    $(".spaceAdd").css("height", 0);
                    $(params.api.headerRootComp.eGui).css("position", "");
                    $(params.api.headerRootComp.eGui).css("top", "");
                    $(params.api.headerRootComp.eGui).css("z-index", "");
                    $(params.api.headerRootComp.eGui).css('width', "");
                    $(params.api.headerRootComp.eGui).css('max-width', '');
                }

                //그리드 가로 스크롤 존재하는 경우만 실행(0214)
                if (scrollElement.length > 0) {
                    if (scrollElement.length == 1 && scrollElement.height() > 0) {
                        
                        if (horizonMiddle) {
                            if ($(window).height() >= $(scrollContainerElement[0]).offset().top + 17) {
                                $(scrollElement[0]).css("position","");
                                $(scrollElement[0]).css("bottom","");
                                $(scrollElement[0]).css("z-index","");
                                $(scrollElement[0]).css("width","");
                            } else {
                                if ($(scrollElement[0]).css("position") != 'fixed') {
                                    $(scrollElement[0]).css("position","fixed");
                                    $(scrollElement[0]).css("bottom","57px");
                                    $(scrollElement[0]).css("z-index","2");
                                    $(scrollElement[0]).css("width",gridWidth);
                                }
                            }
                        } else {
                            if ($(this).scrollTop() >= this.scrollHeight - this.offsetHeight) {
                                $(scrollElement[0]).css("position","");
                                $(scrollElement[0]).css("bottom","");
                                $(scrollElement[0]).css("z-index","");
                                $(scrollElement[0]).css("width","");
                            } else {
                                if ($(scrollElement[0]).css("position") != 'fixed') {
                                    if ($(".fixFooter").length > 0) {
                                        $(scrollElement[0]).css("position","fixed");
                                        $(scrollElement[0]).css("bottom","57px");
                                        $(scrollElement[0]).css("z-index","2");
                                        $(scrollElement[0]).css("width",gridWidth);
                                    } else {
                                        $(scrollElement[0]).css("position","fixed");
                                        $(scrollElement[0]).css("bottom","0px");
                                        $(scrollElement[0]).css("z-index","2");
                                        $(scrollElement[0]).css("width",gridWidth);
                                    }
                                }
                            }
                        }
                    } else {
                        const gridWidthList = [];

                        $(this).find('.ag-full-width-container').each(function(index, item){
                            gridWidthList.push($(this).width())
                        });

                        for (let i=0; i<scrollElement.length; i++) {
                            if (scrollElement[i].scrollHeight > 0) {
                                if ($(this).scrollTop() >= this.scrollHeight - this.offsetHeight) {
                                    $(scrollElement[i]).css("position","");
                                    $(scrollElement[i]).css("bottom","");
                                    $(scrollElement[i]).css("z-index","");
                                    $(scrollElement[i]).css("width","");
                                } else {
                                    if ($(".fixFooter").length > 0) {
                                        $(scrollElement[i]).css("position","fixed");
                                        $(scrollElement[i]).css("bottom","57px");
                                        $(scrollElement[i]).css("z-index","2");
                                        $(scrollElement[i]).css("width",gridWidthList[i]+2);
                                    } else {
                                        $(scrollElement[i]).css("position","fixed");
                                        $(scrollElement[i]).css("bottom","73px");
                                        $(scrollElement[i]).css("z-index","2");
                                        $(scrollElement[i]).css("width",gridWidthList[i]+2);
                                    }
                                }
                            }
                        }
                    }
                }
            });
        } else {
            //그리드는 사용하나 헤더 고정 안쓰면 가로 스크롤 이벤트 제거(0214) => 필요시 제거
            $(scrollArea).off();
            if (scrollElement.length > 0 && scrollElement.height() > 0) {
                scrollElement.css("position","");
                scrollElement.css("bottom","");
                scrollElement.css("z-index","");
                scrollElement.css("width","");
            }
        }

        onGridReady(params);

        if(nullToBlank(params.api.headerRootComp.eHeaderViewport.children[0].children[0]) !== ""){
            const headerCell = params.api.headerRootComp.eHeaderViewport.children[0].children[0].children;
            const headerRow = params.api.headerRootComp.eHeaderViewport.children[0].children;

            params.api.columnController.columnDefs.map((item, index) => {
                let totalHeight = 0;

                if(parseInt(item.rowspan) > 1){
                    for(let i = 1; i < item.rowspan; i++){
                        if(headerRow[i]){
                            totalHeight += $(headerRow[i]).height();
                        }
                    }

                    totalHeight += $(headerCell[index]).height();
                    $(headerCell[index]).css('height', totalHeight);
                    $(headerCell[index]).css('z-index', 1);
                    $(headerCell[index]).css('background-color', '#f8f9fa');
                    $(headerCell[index]).parent().css('overflow', 'inherit');
                }
            })
        }
    }

    const gridSizeChangeFunc = (params) => {
        if(typeof onGridSizeChanged === "function"){
            onGridSizeChanged(params);
        }

        if(fixedYn){
            let gridWidth = $(params.api.gridCore.eGui).width();
            $(params.api.headerRootComp.eGui).css("width", gridWidth);
        }
    }

    // React.useEffect(() => {
    //     debugger;
    //     gridApi = null;
    // }, [gridApi]);

    React.useEffect(() => {
        if (!gridApi) return;
        if (!other.rowData || other.rowData.length !== 0) return;
        if (gridApi.getDisplayedRowCount() !== 0) return;
        if (initBtn === 'Y') {
            gridApi.hideOverlay();
        } else if (initBtn === 'N') {
            if (other.rowData.length === 0)
                gridApi.showNoRowsOverlay();
        }
    }, [other.rowData, gridApi]);


    return (
        <AgGridReact
            onGridReady = {gridReadyFunc}
            onGridSizeChanged = {gridSizeChangeFunc}
            overlayNoRowsTemplate = {nullToBlank(overlayNoRowsTemplate) !== '' ? overlayNoRowsTemplate : initBtn==='Y' ? '<div></div>' : tempHtml}
            suppressContextMenu = {true}
            suppressDragLeaveHidesColumns={true}
            {...other}
        />
    )
}

export default AgGridComponent;
