/**
 * 파일명       : zz12_0100_2_Grid.js
 * 파일 설명	: 이메일 신청리스트 그리드
 * 시스템 구분	: ZZ
 *
 * Ver 작성자  일자         내용         비고
 * --- ------ ---------- ---------- --------------------------
 * 1.0 정훈의  2020.02.20  최초 작성
*/

import React, {useEffect} from "react";
import {Button, Card, CardBody, CardHeader, Col, Row} from "reactstrap";
import {CertButton, SaveButton} from "../../../../components/Button/Button";
import {EXCEL_DEFAULT_STYLES, NORMAL_STATUS} from "../../../../utils/globalValue";
import AgGridEdit from "../../../../components/AgGrid/AgGridEdit";
import {stringDateFormatChange, nullToBlank, setGridHeight} from "../../../../utils/commUtils";
import {injectIntl} from "react-intl";
import {ZZ12_0100_2_LANG} from "./zz12_0100_2_lang";
import {CriticalColumnHeader} from "../../../../components/AgGrid/components/AggridComponents";
import {CancelButton} from "../../zz11/zz11_0300/CancelButton";
import {ModifyButton} from "../../zz11/zz11_0300/ModifyButton";
import {GridSelect, SendTypeRenderer} from "../../../../components/Select/Select";
import ZZ12_0100_2_ChangeLogPopup from "./components/zz12_0100_2_ChangeLogPopup";
import ZZ12_0100_2_EmailType from "./components/zz12_0100_2_EmailType";
import {commonRequest, successModalFunction, warningModalFunction} from "../../../../utils/commAxios";
import ZZ12_0100_2_GridVisidSelect from "./components/zz12_0100_2_Select";
import {showWarningPopup } from "./zz12_0100_2";
import {commonLang} from "../../../../utils/commonLang";
import {ExcelButton} from "../../../../components/ComponentsList";

let gridApi = null;
let gridColumnApi = null;
let MULTI_LANG = null;

const ZZ12_0100_2_Grid = ({
    intl,
    initBtn,
    setInitBtn,
    rowData,
    cancelHandler,
    searchHandler,
    saveBtn,
    pageNo,
    systemMenuName,
    excelBtn,
    maskingYn,
    isOpenCertPopup,
    channelListParams,
    emailTypeParams,
    emailAlertOptions,
    gridActiveParams
}) => {
    const {formatMessage} = intl;
    MULTI_LANG = ZZ12_0100_2_LANG(formatMessage);

    /** @const {string} */
    const EMAIL_CUSTOM_ALERT_SAVE_PACKAGE = "PG_ZZ12_0100.PR_TB_ZZ_EMAIL_CUSTM_SET_CUD";

    const [joinEmailData, setJoinEmailData] = React.useState({
        columnDefs: [{
                field: 'EMAIL_SET_DATE',
                headerName: MULTI_LANG.SETTING_DATE,
                cellClass: 'excel-date',
                cellStyle: {textAlign: 'center'},
                width: 80,
                editable: false,
                valueFormatter: stringDateFormatChange
            }, {
                field: 'EMAIL_SENDER_SET_CN',
                headerName: MULTI_LANG.EMAIL_TYPE,
                cellClass: 'excel-left-align',
                codeName: 'EMAIL_SENDER_SET_NAME',
                cellStyle: {textAlign: 'left'},
                width: 200,
                headerComponent:'headerRender',
                cellRenderer: 'EmailCellRenderer',
                cellEditor: 'EmailSelectRenderer',
                cellEditorParams: emailTypeParams
            }, {
                field: 'EMAIL',
                headerName: MULTI_LANG.EMAIL_ADDRESS,
                cellStyle: {textAlign: 'left'},
                width: 130,
                editable: false,
                hide: true,
                protection: {hideFormula : true}
            }, {
                field: 'INCHARGE_NAME',
                headerName: MULTI_LANG.CHARGE_NAME,
                cellClass: 'excel-left-align',
                cellStyle: {textAlign: 'left'},
                width: 100,
                editable: true
            }, {
                field: 'CN_NO',
                headerName: MULTI_LANG.BUSINESS_NAME,
                cellClass: 'excel-left-align',
                cellStyle: {textAlign: 'left'},
                width: 110,
                headerComponent: 'headerRender',
                codeName: 'CN_NAME',
                valueFormatter: companyNameFormatter,
                cellEditor: 'VisidSelectCellRenderer',
                cellEditorParams: channelListParams
            }, {
                field: 'EMAIL_ALERT_CN_LIST',
                headerName: MULTI_LANG.SEND_TYPE,
                width: 260,
                headerComponent: 'headerRender',
                cellClass: ["excel-array-v2", "multiDropdown", "excel-left-align"],
                cellRenderer : 'SendTypeCellRenderer',
                cellStyle: {textAlign: 'left'},
                cellEditor: 'sendTypeRenderer',
                cellEditorParams: emailAlertOptions,
                editable: true,
                suppressNavigable: true
            }, {
                field: 'ACTIVE_YN',
                headerName: MULTI_LANG.ACTIVE_YN,
                cellStyle: {textAlign:'center'},
                width: 75,
                headerComponent: 'headerRender',
                cellClass: 'excel-center-align',
                valueFormatter: useYnFormatter,
                cellEditor: 'SelectCellRenderer',
                cellEditorParams: gridActiveParams
            }, {
                field: 'HISTORY',
                headerName: MULTI_LANG.HISTORY,
                cellStyle: {textAlign: 'center'},
                width: 85,
                cellRenderer: 'viewButtonRenderer',
                editable: false,
                cellRendererParams: {intl: intl},
                excelExportable: false
            }, {
                field: 'EMAIL_SENDER_SET_NAME',
                hide: true
            }, {
                field: 'CN_NAME',
                hide: true
            }
        ],
        defaultColDef: {
            sortable: true,
            editable: true,
            resizable: true,
            headerClass: 'text-center',
            menuTabs: false
        },
        rowData : [],
        frameworkComponents: {
            EmailSelectRenderer: ZZ12_0100_2_EmailType,
            EmailCellRenderer: params => {
                const emailTypeCn = params.value;
                const selectedEmailType = emailTypeParams.names.find(emailTypeItem => emailTypeItem.value === emailTypeCn);

                if (!selectedEmailType) return '';

                const emailTypeName = selectedEmailType.label;
                const emailTypeComnCode = selectedEmailType.key;

                if (emailTypeComnCode === '1') return `${emailTypeName} (${params.data.EMAIL})`;

                return emailTypeName;
            },
            sendTypeRenderer: SendTypeRenderer,
            SendTypeCellRenderer: sendTypeCellRenderer,
            VisidSelectCellRenderer: ZZ12_0100_2_GridVisidSelect,
            viewButtonRenderer: ZZ12_0100_2_ChangeLogPopup,
            SelectCellRenderer: GridSelect,
            modifyButtonRenderer: ModifyButton,
            cancelCellRenderer: CancelButton,
            headerRender: CriticalColumnHeader
        },
        editType: 'fullRow'
    });

    /**
     * @author hoonui Jeong
     * @description Row Data Changed Event
    */
    useEffect(() => {
        if (gridApi === null) return;
        if (rowData.length === 0) gridApi.setRowData([]);

        setJoinEmailData({...joinEmailData, rowData: rowData});
    }, [rowData]);

    /**
     * @author hoonui Jeong
     * @description Grid Ready Function
     * @param {Object} params
    */
    const onGridReady = params => {
        gridApi = params.api;
        gridColumnApi = params.columnApi;

        gridApi.sizeColumnsToFit();
    }

    /**
     * @author hoonui Jeong
     * @description Row Add Button Function
    */
    const rowAddHandler = () => {
        gridApi.stopEditing();

        let addRowData = {EMAIL_SET_DATE : '', EMAIL_SENDER_SET_CN : '', EMAIL : '', INCHARGE_NAME : '', CN_NO : '', EMAIL_ALERT_CN_LIST : '', ACTIVE_YN : 'Y', HISTORY : '', MODIFY : '', CANCEL : '', ROWTYPE : '2', CUD_TYPE : 'I'};
        const movableCells = gridColumnApi.getAllColumns().filter(column => column.colDef.editable && !column.colDef.suppressNavigable);
        const gridRowDataTemp = [];

        gridApi.forEachNode(index => gridRowDataTemp.push(index.data));
        gridApi.setRowData([addRowData, ...gridRowDataTemp]);
        gridApi.setFocusedCell(0, movableCells[0].colId);
        gridApi.startEditingCell({
            rowIndex: 0,
            colKey: movableCells[0].colId
        });
    }

    /**
     * @author hoonui Jeong
     * @description Save Button Function
    */
    const saveHandler = () => {
        gridApi.stopEditing();

        const gridData = getUpdatedGridData(gridApi);

        if (gridData.length === 0) return;

        const validKeys = ["EMAIL_SENDER_SET_CN", "CN_NO", "EMAIL_ALERT_CN_LIST", "ACTIVE_YN"];

        /** 수정된 데이터 필터 */
        const isValid = gridData.every((data, index) => {
            /** 업데이트된 로우데이터 */
            if (nullToBlank(data.ROWTYPE) === '0') return;

            const inValidCol = validKeys.filter((key) => {
                if (nullToBlank(data[key]) === '' || nullToBlank(data[key]) === "###") {
                    if (key === 'CN_NO' && data.EMAIL_SENDER_SET_CN !== 906500) return false;

                    return true;
                }
            });

            if (inValidCol.length > 0) {
                const firstInvaildColumnName = inValidCol[0];

                showWarningPopup(gridApi.getColumnDef(firstInvaildColumnName).headerName);
                moveInvalidCol(index, firstInvaildColumnName);

                return false;
            }

            /** 이메일 유효성 체크 */
            const emailTypeComnCode = emailTypeParams.names.find(emailTypeItem => parseInt(emailTypeItem.value) === parseInt(data.EMAIL_SENDER_SET_CN));
            let emailRegularExpression = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/;

            if (emailTypeComnCode.key === '1') {
                const email = data.EMAIL;
                const isValidEmail = emailRegularExpression.test(email);

                if (isValidEmail === false) {
                    warningModalFunction(commonLang(), MULTI_LANG.EMAIL_FORMATTER);
                    moveInvalidCol(index, "EMAIL_SENDER_SET_CN");
                    return false;
                }
            }

            return true;
        });

        /** 유효성 체크 */
        if (isValid === false) return;
        
        /** 데이터 전송 */ 
        gridData.forEach((rowData) => {
            let masterEmail = {};
            let childrenEmail = [];

            if (nullToBlank(rowData.EMAIL_ALERT_CN_LIST) !== '') {
                let emailAlertArr = rowData.EMAIL_ALERT_CN_LIST.split('#');
                let emailCnArr = emailAlertArr[0].split(',');
                let emailTraceArr = emailAlertArr[2].split(',');
                let emailActiveArr = emailAlertArr[3].split(',');

                childrenEmail = emailAlertOptions.names.map(v => v.value).map(emailCn => {
                    const initParams = {
                        EMAIL_CUSTM_SET_NO: rowData.EMAIL_CUSTM_SET_NO,
                        EMAIL_ALERT_CN: emailCn + '',
                    };

                    if (emailCnArr.includes(emailCn + '')) {
                        const emailCnArrIndex = emailCnArr.findIndex(item => item === emailCn);

                        return {
                            ...initParams,
                            TRACE_NO: emailTraceArr[emailCnArrIndex],
                            ACTIVE_YN: 'Y'
                        };
                    }

                    return {
                        ...initParams,
                        ACTIVE_YN: 'N'
                    };
                });
            }

            const emailTypeComnCode = emailTypeParams.names.find(emailTypeItem => parseInt(emailTypeItem.value) === parseInt(rowData.EMAIL_SENDER_SET_CN));

            if (emailTypeComnCode.key === '1') masterEmail = {...masterEmail, EMAIL : rowData.EMAIL};

            let emailSetDate = rowData.EMAIL_SET_DATE;

            if (nullToBlank(emailSetDate) === '') emailSetDate = new Date().format("yyyyMMdd");

            masterEmail = {
                ...masterEmail,
                PROCEDURE_OR_TABLE_NAME: EMAIL_CUSTOM_ALERT_SAVE_PACKAGE,
                EMAIL_SENDER_SET_CN: rowData.EMAIL_SENDER_SET_CN,
                EMAIL_CUSTM_SET_NO: rowData.EMAIL_CUSTM_SET_NO,
                EMAIL_SET_DATE: emailSetDate,
                INCHARGE_NAME: rowData.INCHARGE_NAME,
                CN_NO: rowData.CN_NO,
                ACTIVE_YN: rowData.ACTIVE_YN,
                TRACE_NO: rowData.TRACE_NO,
                DATA_LIST_TB_ZZ_EMAIL_CUSTM_SET_CODE: childrenEmail
            };

            commonRequest(response => searchHandler(), masterEmail);
        });

        successModalFunction(commonLang(), MULTI_LANG.SUCCESS);

        return;
    }

    /**
     * @author hoonui Jeong
     * @description Grid Size Control Function
     * @param {Object} gridParam
    */
    const resizeGrid = gridParam => {
        setGridHeight(gridParam.api);
        gridParam.api.sizeColumnsToFit();
    }

    return (
        <>
            <div style={{marginBottom: '80px'}}>
                <Row>
                    <Col md="12">
                        <Card>
                            <CardHeader>
                                <div className="btn-actions-pane-right">
                                    <Button
                                        className="mr-2 btn-icon btn-icon-onlys"
                                        onClick={rowAddHandler}
                                        color="light"
                                    >
                                        <i className="pe-7s-add"/>
                                    </Button>
                                    <ExcelButton
                                        pageNo={pageNo}
                                        gridApi={gridApi}
                                        pageName={systemMenuName}
                                        isNotPerm={excelBtn}
                                    />
                                    <CertButton
                                        maskingYn={maskingYn}
                                        onClick={isOpenCertPopup}
                                        dateLength={joinEmailData.rowData.length}
                                    />
                                </div>
                            </CardHeader>
                            <CardBody
                                id="joinEmailGrid"
                                className="data-table"
                                style={{height: '47vh'}}
                            >
                                <div
                                    id="zz12_0100_2__grid"
                                    className="ag-theme-balham"
                                    style={{height: '100%', width: '100%'}}
                                >
                                    <AgGridEdit
                                        columnDefs={joinEmailData.columnDefs}
                                        defaultColDef={joinEmailData.defaultColDef}
                                        rowData={joinEmailData.rowData}
                                        onGridReady={onGridReady}
                                        rowClassRules={joinEmailData.rowClassRules}
                                        rowSelection={joinEmailData.rowSelection}
                                        editType={joinEmailData.editType}
                                        frameworkComponents={joinEmailData.frameworkComponents}
                                        suppressClickEdit={true}
                                        getRowClass={joinEmailData.getRowClass}
                                        onRowDataChanged={resizeGrid}
                                        onGridSizeChanged={resizeGrid}
                                        headerFixed={true}
                                        context={{emailTypeParams: emailTypeParams}}
                                        excelStyles={EXCEL_DEFAULT_STYLES}
                                        initBtn={initBtn}
                                        setInitBtn={setInitBtn}
                                    />
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <div className="app-footer fixFooter">
                    <div className="app-footer__inner">
                        <div className="text-center">
                            <SaveButton
                                cancelYn='Y'
                                cancelHandler={cancelHandler}
                                saveHandler={saveHandler}
                                close={saveBtn}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default injectIntl(ZZ12_0100_2_Grid);

/**
 * @author hoonui Jeong
 * @description Whether or not to use Formatter
 * @param {Object} params
 * @return {String}
*/
const useYnFormatter = params => {
    return params.value === '' ? (
        MULTI_LANG.ACTIVE_YN 
    ) : (
        params.value === 'Y' ? (
            MULTI_LANG.OPTIONS_USE
        ) : (
            MULTI_LANG.OPTIONS_NO_USE
        )
    );
}

/**
 * @author hoonui Jeong
 * @description Send Type Formatter
 * @param {Object} params
 * @return {String}
*/
export const sendTypeFormatter = params => {
    let tempValue = '';
    let resultValue = '';

    if (nullToBlank(params.value) === '') {
        return tempValue;
    } else if (typeof params.value === "object"){
        tempValue = params.value.value.split('#');
    } else {
        tempValue = params.value.split('#');
    }

    if (tempValue.length === 4) {
        let alertName = tempValue[1].split(',');

        alertName.map((item, index) => {
            if (index === 0) {
                resultValue = item;
            } else {
                resultValue += ',' + item;
            }
        });
    }

    return resultValue;
}

/**
 * @author hoonui Jeong
 * @description Invalid Value Focus Cell
 * @param {Object} params
*/
const moveInvalidCol = (index, colKey) => {
    const rowIndex = gridApi.getRowNode(index).rowIndex;

    gridApi.setFocusedCell(rowIndex, colKey);
    gridApi.startEditingCell({
        rowIndex: rowIndex,
        colKey: colKey
    });
}

/**
 * @author hoonui Jeong
 * @description Company Name Formatter
 * @param {Object} params
 * @return {String}
*/
const companyNameFormatter = params => {
    let name = '';
    const companyNameItem = params.colDef.cellEditorParams.names.find(companyItem => parseInt(companyItem.value) === parseInt(params.value));
    const EmailTypeItems = params.context.emailTypeParams;
    const selectedEmailType = EmailTypeItems.names.find(emailTypeItem => parseInt(emailTypeItem.value) === params.data.EMAIL_SENDER_SET_CN);

    if (!selectedEmailType || selectedEmailType.key !== '1') return '';

    if (companyNameItem && companyNameItem.label) name = companyNameItem.label;

    return name;
}

/**
 * @author hoonui Jeong
 * @description Send Type Render
 * @param {Object} params
 * @return {Component}
*/
const sendTypeCellRenderer = params => {
    return (
        <div style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
            {sendTypeFormatter(params)}
        </div>
    );
}

/**
 * @author daehyung Seol
 * @description Get updated row data
 * @param {Object} grid api
 * @return {Object} updatedGridData
 */
const getUpdatedGridData = gridApi => {
    return gridApi.getRenderedNodes()
        .map(item => item.data)
        .filter(item => item.ROWTYPE !== NORMAL_STATUS);
}