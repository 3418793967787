import React, {Fragment} from "react";
import Drawer from "react-motion-drawer";
import PerfectScrollbar from "react-perfect-scrollbar";
import Hamburger from "react-hamburgers";
import SEARCH_FILTER from "../../../comm/searchFilter";
import Ionicon from 'react-ionicons';

const SEARCH_FILETER = (props) => {
    const {initCodition, searchHandler, condition, addCondition, onDetect, locale} = props;
    const [open, setOpen] = React.useState(false);
    const drawerChange = event => {
        if (event) {
            setTimeout(function() {
                // 초기 포커스 설정
            }, 10);
        }
        setOpen(event);
    }

    const onInitHandler = () => {
        setOpen(false);
        if(typeof initCodition === "function"){
            initCodition();
        }
    }
    const onSearchHandler = () => {
        setOpen(false);
        if(typeof searchHandler === "function"){
            searchHandler();
        }
    }

    const filterDetect = (setting, data, addCombo) => {
        if(typeof onDetect === 'function') {
            onDetect(setting, data, addCombo);
        }
    }

    return (
        <Fragment>
            <Drawer
                right
                className="drawer-content-wrapper p-0 filter-content drawerArea"
                width={700}
                open={open}
                noTouchOpen={true}
                noTouchClose={true}
                onChange={(e) => drawerChange(e)}
            >
                <PerfectScrollbar>
                    <div className="drawer-nav-btn">
                        <Hamburger
                            active={open}
                            type="elastic"
                            onClick={() => {setOpen(false);}}
                        />
                    </div>
                    {(open) ?
                        <SEARCH_FILTER
                            searchData={condition}
                            searchAddData={addCondition}
                            detect={filterDetect}
                            initFunc={onInitHandler}
                            searchFunc={onSearchHandler}
                            locale={locale}
                            isOpen={open}
                        /> : <></>
                    }
                </PerfectScrollbar>
            </Drawer>
            {/* <div className="filter-area">
                    <span className="filter-ico"
                          type="elastic"
                          onClick={() => {
                              setOpen(true);
                          }}
                    >filter</span>
            </div> */}
            <div className="fix-bottom-btn">
                <div className="btn-style">
                    <span className="filter-ico" onClick={() => { setOpen(true); }}>
                        <Ionicon fontSize="28px" color="#333" icon={'ios-funnel'}/>
                        <em>Filter</em>
                    </span>
                </div>
            </div>
        </Fragment>
    )
}

export default SEARCH_FILETER;
