import React from "react";

export const ERROR_LANG = (formatMessage) => {
    let MULTI_LANG = {
        ERROR_CONTENT1              : formatMessage({id: "error.title.content1",        defaultMessage: "페이지를 표시할 수 없습니다."}),
        ERROR_CONTENT1_SUB1         : formatMessage({id: "error.title.content1Sub1",    defaultMessage: "서비스 이용에 불편을 드려 죄송합니다."}),
        ERROR_CONTENT1_SUB2         : formatMessage({id: "error.title.content1Sub2",    defaultMessage: "연결하려는 페이지 서버에 내부적으로  <span class='text-danger'>오류가 발생</span>하여 페이지를  표시할 수 없습니다."}),
    }

    return MULTI_LANG;
}