import React from "react";
import {Button, CustomInput} from "reactstrap";
import classnames from "classnames";
import {commonLang} from "../../utils/commonLang";
import {GridExcelDownloadButton} from "../../pages/comm/DownloadReasonPopup";
import {warningModalFunction} from "../../utils/commAxios";
import parse from "html-react-parser";

/**
 * @author hoonui Jeong
 * @description Search, Init Button Component
 * @param {function} initHandler
 * @param {function} searchHandler
 * @param {String} initId
 * @param {String} searchId
 * @param {Object} searchRef
*/
const SearchButton = props => {
    const {initHandler, searchHandler, initId, searchId, searchRef, showCheck, checkId, checkLabel, checkVal, checkHandler} = props;

    /**
     * @author hoonui Jeong
     * @description Init Button Function
     * @param {} param1 미정의 된 파라미터 => 필요시 사용
     * @param {} param2
     * @param {} param3
     * @param {} param4
     * @param {} param5
    */
    const commInitHandler = (param1, param2, param3, param4, param5) => {
        if (typeof initHandler === "function") initHandler(param1, param2, param3, param4, param5);
    }

    /**
     * @author hoonui Jeong
     * @description Search Button Function
     * @param {} param1 미정의 된 파라미터 => 필요시 사용
     * @param {} param2
     * @param {} param3
     * @param {} param4
     * @param {} param5
    */
    const commSearchHandler = (param1, param2, param3, param4, param5) => {
        if (props.close) {
            warningModalFunction(commonLang(), parse(commonLang().NOT_AUTHORITY));
            return;
        }

        if (typeof searchHandler === "function") searchHandler(param1, param2, param3, param4, param5);
    }
    
    const onCheckHandler = event => {
        if (typeof checkHandler === "function") checkHandler(event);
    }

    return (
        <>
            <div className="text-center">
                <Button
                    id={initId}
                    className="mr-2 btn-icon font-weight-md w90"
                    onClick={commInitHandler}
                    color="light"
                >
                    <i className="pe-7s-refresh btn-icon-wrapper font-weight-bold" />
                    {commonLang().BUTTON_INITIALIZE}
                </Button>
                <Button
                    id={searchId}
                    className="btn-icon font-weight-md text-white w90"
                    onClick={commSearchHandler}
                    color="primary"
                    //close={props.close}
                    innerRef={searchRef}
                >
                    <i className="pe-7s-search btn-icon-wrapper font-weight-bold" />
                    {commonLang().BUTTON_SEARCH}
                </Button>
                {
                    showCheck === 'Y' && (
                    <CustomInput
                        inline
                        //checked
                        id={checkId}
                        type="checkbox"
                        className="ml-3 custom-control-inline font-size-082 font-weight-md text-333 mb-10"
                        checked={checkVal}
                        onChange={onCheckHandler}
                        label={checkLabel}
                    />
                )}
            </div>
        </>
    )
}

/**
 * @author hoonui Jeong
 * @description Save, Cancel(prev,Next,Complete) Button Component
 * @param {String} cancelYn cancel 버튼 출력 여부
 * @param {function} cancelHandler
 * @param {function} saveHandler
 * @param {String} cancelId
 * @param {String} saveId
 * @param {String} cancelColor 버튼 색상
 * @param {String} showPrev prev 버튼 출력 여부
 * @param {String} showNext next 버튼 출력 여부
 * @param {String} showComplete complete 버튼 출력 여부
*/
const SaveButton = props => {
    const {cancelYn, cancelHandler, saveHandler, cancelId, saveId, cancelColor="secondary", showPrev, showNext, showComplete} = props;

    /**
     * @author hoonui Jeong
     * @description Save Button Function
     * @param {} param1 미정의 된 파라미터 => 필요시 사용
     * @param {} param2
     * @param {} param3
     * @param {} param4
     * @param {} param5
    */
    const commSaveHandler = (param1, param2, param3, param4, param5) => {
        if (props.close) {
            warningModalFunction(commonLang(), parse(commonLang().NOT_AUTHORITY));
            return;
        }

        if (typeof saveHandler === "function") saveHandler(param1, param2, param3, param4, param5);
    }

    /**
     * @author hoonui Jeong
     * @description Cancel,Prev,Next,Complete Button Function
     * @param {} param1 미정의 된 파라미터 => 필요시 사용
     * @param {} param2
     * @param {} param3
     * @param {} param4
     * @param {} param5
    */
    const commCancelHandler = (param1, param2, param3, param4, param5) => {
        if (typeof cancelHandler === "function") cancelHandler(param1, param2, param3, param4, param5);
    }

    return (
        <>
            <div className="text-center">
                {cancelYn === 'N' ? (
                    <></>
                ) : (
                    <Button
                        id={cancelId}
                        className="btn-wide btn-shadow w140 mr-2"
                        onClick={commCancelHandler}
                        size="lg"
                        color={cancelColor}
                    >
                        {showPrev === 'Y' ? (
                            commonLang().BUTTON_PREV
                        ) : (
                            commonLang().BUTTON_CANCEL
                        )}
                    </Button>
                )}
                <Button
                    id={saveId}
                    className="btn-wide btn-shadow w140"
                    onClick={commSaveHandler}
                    size="lg"
                    color="primary"
                    //close={props.close}
                >
                    {showNext === 'Y' ? (
                        commonLang().BUTTON_NEXT
                    ) : (
                        showComplete === 'Y' ? (
                            commonLang().BUTTON_COMPLETE
                        ) : (
                            commonLang().BUTTON_SAVE
                        )
                    )}
                </Button>
            </div>
        </>
    )
}

/**
 * @author hoonui Jeong
 * @description Excel Button Component
 * @param {Number} pageNo cancel 버튼 출력 여부
 * @param {Object} gridApi
 * @param {Boolean} isNotPerm 권한에 따른 엑셀 버튼 활성화 여부
 * @param {String} pageName
 * @param {Object} addProperty Ag-grid 엑셀 Export 추가 속성
*/
const ExcelButton = ({pageNo, gridApi, isNotPerm=false, pageName="업무명", addProperty={}, ...other}) => {
    return (
        <>
            <GridExcelDownloadButton
                pageNo={pageNo}
                gridApi={gridApi}
                isNotPerm={isNotPerm}
                pageName={pageName}
                addProperty={addProperty}
                {...other}
            />
        </>
    )
}

/**
 * @author hoonui Jeong
 * @description Print Button Component
*/
const PrintButton = props => {
    return (
        <></>
    )
}

/**
 * @author hoonui Jeong
 * @description Cert Button Component
 * @param {String} maskingYn 현재 마스킹 상태 여부
 * @param {Function} onClick
 * @param {Number} dateLength Row 데이터 갯수
*/
const CertButton = ({maskingYn, onClick, dateLength, authCert=false}) => {
    const masking = maskingYn === 'Y' ? true: false;

    /**
     * @author hoonui Jeong
     * @description Cert Button Function
    */
    const handleClick = () => {
        if (authCert) {
            warningModalFunction(commonLang(), parse(commonLang().NOT_AUTHORITY));
            return;
        }

        if (!masking || dateLength === 0) return;

        onClick();
    }

    /**
     * @author hoonui Jeong
     * @description Row Data 갯수가 변화할 때마다 실행
    */
    React.useEffect(() => {
        if (masking) {
            if (dateLength === 0) {
                if (document.getElementsByClassName("certificationBtn").length > 0) {
                    document.getElementsByClassName("certificationBtn")[0].style.backgroundColor = "#8A8A8A";
                    document.getElementsByClassName("certificationBtn")[0].style.cursor = "default";
                    document.getElementsByClassName("certificationBtn")[0].classList.remove("btn-warning");
                }
            } else {
                if (document.getElementsByClassName("certificationBtn").length > 0) {
                    document.getElementsByClassName("certificationBtn")[0].style.backgroundColor = '';
                    document.getElementsByClassName("certificationBtn")[0].style.cursor = '';
                    document.getElementsByClassName("certificationBtn")[0].classList.add("btn-warning");
                }
            }
        }
    }, [dateLength]);

    return (
        <>
            <Button
                disabled={!masking}
                className={classnames({'mr-2': true, 'btn-icon': true, 'btn-icon-onlys': true, 'lock-ic': masking, 'unlock-ic': !masking, 'certificationBtn': true})}
                onClick={handleClick}
                style={masking ? null : {backgroundColor: '#8A8A8A', cursor: 'default'}}
                color={masking ? 'warning' : null}
            />
        </>
    )
}

export {
    SearchButton,
    SaveButton,
    ExcelButton,
    PrintButton,
    CertButton
}