export const initialState = {
    localeState: "ko",
};
export const LOCALE_CODE = "Layout/LOCALE_CODE";

export const localeTypeObject = {type: LOCALE_CODE};

export const localeCode = () => ({
    type: LOCALE_CODE,
})

const localeState =  (state = initialState, action) => {
    switch (action.type){
        case LOCALE_CODE:
            return {
                ...state,
                localeState: !state.localeState,
            };
        default :
            return state;
    }
}

export default localeState;