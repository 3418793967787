import React, { Fragment } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Fade from '@material-ui/core/Fade';
import Zoom from '@material-ui/core/Zoom';
import Typography from '@material-ui/core/Typography';

const SimpleTooltip = ({classes, ...props})=> {
    let {position, transitionType, timeout, ...options} = props;
    let delayTime = {timeout : parseInt(timeout)};

    return (
        <Fragment>
            {
                transitionType === "Zoom" &&
                <Tooltip  
                    placement={position}
                    TransitionComponent={Zoom}
                    TransitionProps={delayTime}
                    {...options}    
                >

                </Tooltip>
            }
            {
                transitionType === "Fade" &&
                <Tooltip  
                    placement={position}
                    TransitionComponent={Fade}
                    TransitionProps={delayTime}
                    {...options}    
                >

                </Tooltip>
            }        
            {
                transitionType === undefined &&
                <Tooltip  
                    placement={position}
                    {...options}    
                >

                </Tooltip>
            } 
        </Fragment>
    )
}

const BottomPositionedTooltip = ({classes, ...props})=> {
    return (
        <Tooltip {...props} placement="bottom">

        </Tooltip>
    )
}

const TopPositionedTooltip = ({classes, ...props})=> {
    return (
        <Tooltip {...props} placement="top">

        </Tooltip>
    )
}

const RightPositionedTooltip = ({classes, ...props})=> {
    return (
        <Tooltip {...props} placement="right">

        </Tooltip>
    )
}

const LeftPositionedTooltip = ({classes, ...props})=> {
    return (
        <Tooltip {...props} placement="left">

        </Tooltip>
    )
}

function arrowGenerator(color) {
    return {
      '&[x-placement*="bottom"] $arrow': {
        top: 0,
        left: 0,
        marginTop: '-0.95em',
        width: '3em',
        height: '1em',
        '&::before': {
          borderWidth: '0 1em 1em 1em',
          borderColor: `transparent transparent ${color} transparent`,
        },
      },
      '&[x-placement*="top"] $arrow': {
        bottom: 0,
        left: 0,
        marginBottom: '-0.95em',
        width: '3em',
        height: '1em',
        '&::before': {
          borderWidth: '1em 1em 0 1em',
          borderColor: `${color} transparent transparent transparent`,
        },
      },
      '&[x-placement*="right"] $arrow': {
        left: 0,
        marginLeft: '-0.95em',
        height: '3em',
        width: '1em',
        '&::before': {
          borderWidth: '1em 1em 1em 0',
          borderColor: `transparent ${color} transparent transparent`,
        },
      },
      '&[x-placement*="left"] $arrow': {
        right: 0,
        marginRight: '-0.95em',
        height: '3em',
        width: '1em',
        '&::before': {
          borderWidth: '1em 0 1em 1em',
          borderColor: `transparent transparent transparent ${color}`,
        },
      },
    };
}

const useStylesArrow = makeStyles(theme => ({
    tooltip: {
      position: 'relative',
    },
    arrow: {
      position: 'absolute',
      fontSize: 6,
      width: '3em',
      height: '3em',
      '&::before': {
        content: '""',
        margin: 'auto',
        display: 'block',
        width: 0,
        height: 0,
        borderStyle: 'solid',
      },
    },
    popper: arrowGenerator(theme.palette.grey[700]),
}));

const ArrowTooltip = ({classes, ...props})=> {
    const ArrowTooltip = props => {
        const { arrow, ...classes } = useStylesArrow();
        const [arrowRef, setArrowRef] = React.useState(null);
      
        return (
            <Tooltip
                classes={classes}
                PopperProps={{
                popperOptions: {
                    modifiers: {
                    arrow: {
                        enabled: Boolean(arrowRef),
                        element: arrowRef,
                    },
                    },
                },
                }}
                {...props}
                title={
                <React.Fragment>
                    {props.title}
                    <span className={arrow} ref={setArrowRef} />
                </React.Fragment>
                }
            />
        );
    }

    return (
        <ArrowTooltip {...props}>

        </ArrowTooltip>
    )
}

const HtmlTooltip = withStyles(theme => ({
    tooltip: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
}))(Tooltip);

const HtmlTagTooltip = ({classes, ...props})=> {
    let {title, titleColor, content, ...options} = props;
    content = {__html: content};

    return (
        <HtmlTooltip title={
                        <React.Fragment >
                            <Typography color={titleColor}>{title}</Typography>
                            <div  dangerouslySetInnerHTML={content}>
                            </div>
                        </React.Fragment>
            }
            {...options}
        >

        </HtmlTooltip>
    )
}

const HoverTriggerTooltip = ({classes, ...props}) => {
    return (
        <Tooltip disableFocusListener disableTouchListener {...props}>

        </Tooltip>
    )
}

const FocusTriggerTooltip = ({classes, ...props}) => {
    return (
        <Tooltip disableHoverListener {...props}>

        </Tooltip>
    )
}

const FadeTransitionTooltip = ({clasess, ...props}) => {
    let {timeout, ...options} = props;
    let delayTime = {timeout : parseInt(timeout)};
    
    return (
        <Tooltip TransitionComponent={Fade} TransitionProps={delayTime} {...options}>
        </Tooltip>
    )
}

const ZoomTransitionTooltip = ({classes,...props}) => {
    let {timeout, ...options} = props;
    let delayTime = {timeout : parseInt(timeout)};

    return (
        <Tooltip TransitionComponent={Zoom} TransitionProps={delayTime} {...options} >

        </Tooltip>
    )
}

export {BottomPositionedTooltip, TopPositionedTooltip, RightPositionedTooltip, LeftPositionedTooltip, ArrowTooltip, HtmlTagTooltip, HoverTriggerTooltip, FocusTriggerTooltip, FadeTransitionTooltip, ZoomTransitionTooltip, SimpleTooltip}