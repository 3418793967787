import React, { Fragment } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Col,
  Row,
  Form,
  FormGroup,
  Label,
  Input,
  Card,
  CardText,
  CardBody,
  CardTitle,
  CardHeader,
  CardFooter
} from 'reactstrap';

import PerfectScrollbar from 'react-perfect-scrollbar';
import { TUTORIAL_LANG } from './Tutorial_lang';
import parse from 'html-react-parser';
import { injectIntl } from 'react-intl';



// Layout
// import BoxHeader from '../BoxHeader/';
// import BoxFooter from '../BoxFooter/';
const TutorialEnd = ({ intl, open, onClickCloseButton, onClickStopView, onClickAllStopView }) => {
  const { formatMessage } = intl;
  const MULTI_LANG = TUTORIAL_LANG(formatMessage);

  const handleStopView = () => {
    onClickStopView();
    onClickCloseButton();
  }
  const handleStopAllView = () => {
    onClickAllStopView();
    onClickCloseButton();
  }

  return (
    <Modal
      centered={true}
      show={"true"}
      isOpen={open}
      toggle={onClickCloseButton}
      className="modal-small00 noshadow"
      backdrop="static"
    >
      <div className="app-inner-layout__header text-white bg-primary tutorial-pop pb-5">
        <div className="app-page-title">
          <div className="page-title-wrapper">
            <span className="closeBt" onClick={onClickCloseButton}>
              닫기
              </span>
          </div>
        </div>
      </div>

      <div className="modal-wrapper bg-primary text-white text-center tutorial-wrapper">
        <div className="modal-body">
          <PerfectScrollbar>
            <div className="container-fluid">
              <h4 className="mb-3">{MULTI_LANG.LABEL_TITLE_EXIT}</h4>
              <p className="mb-5 font-size-rem">{parse(MULTI_LANG.LABEL_TITLE_SUB_EXIT)}</p>
            </div>
            <div className="btn-group">
            <Button onClick={handleStopView} className="w180 bg-white text-primary mb-4-5rem mr-2">
              {parse(MULTI_LANG.BUTTON_LABEL_MENU_STOP_VIEW)}
            </Button>
            <Button onClick={handleStopAllView} className="w180 bg-white text-primary mb-4-5rem">
              {parse(MULTI_LANG.BUTTON_LABEL_ALL_MENU_STOP_VIEW)}
            </Button>{' '}
            </div>
          </PerfectScrollbar>
        </div>
      </div>
    </Modal>
  );
}

export default injectIntl(TutorialEnd);
