import React, {Fragment, useEffect} from 'react';
import cx from 'classnames';

import {connect, useSelector} from 'react-redux';
import {withRouter, BrowserRouter as Router} from "react-router-dom";

import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

import HeaderLogo from '../AppLogo';

import SearchBox from './Components/SearchBox';
import MegaMenu from './Components/MegaMenu';
import UserBox from './Components/UserBox';
import HeaderRightDrawer from "./Components/HeaderRightDrawer";
import HeaderDots from "./Components/HeaderDots";
import {getSessionInfo, nullToBlank, loadLocalStorage, removeUserCookie, getCookie} from "../../utils/commUtils";
import {COOKIE_CHANGE_TIME, SESSION_EXPIRATION_TIME, SESSION_TIME_OUT} from "../../reducers/propertyState";
import {IntlContext} from "../../config/ConnectedIntlProvider";
import {loginHistory} from "../../utils/commAxios";
import { injectIntl } from 'react-intl';
import { APPFOOTER_LANG } from './AppFooter_lang';

//class Header extends React.Component {
const Header = (props) => {
    let {
        headerBackgroundColor,
        enableMobileMenuSmall,
        enableHeaderShadow,
        enableClosedSidebar,
        history,
        intl
    } = props;
    const { formatMessage } = intl;
    const MULTI_LANG = APPFOOTER_LANG(formatMessage);

    const sessionInfo = getSessionInfo();
    const [ssUserInfo, setSsUserInfo] = React.useState(sessionInfo);
    const { switchToKorean,  switchToEnglish, switchToChinese, switchToJapanese} = React.useContext(IntlContext);

    React.useEffect(() => {
        if(nullToBlank(props.changeTime) === ""){
            props.setChangeTime(loadLocalStorage('COOKIE_CHANGE_TIME'));
        }else{
            let tempSession = getSessionInfo();
            setSsUserInfo(tempSession);
        }

        let intervalTime = setInterval(() => {
            let currentTime = new Date();

            if(currentTime > props.sessionExpirationTime){
                loginHistory("LOGOUT");
                removeUserCookie('TIMEOUT');
                history.replace('/login/zz/zz01_0700');

                return false;
            }else if(props.changeTime !== loadLocalStorage('COOKIE_CHANGE_TIME')){
                let nowSessionIinfo = getSessionInfo();

                if(nowSessionIinfo.SS_USER_LANG_CODE !== ssUserInfo.SS_USER_LANG_CODE){
                    switch(nowSessionIinfo.SS_USER_LANG_CODE){
                        case 'KOR' :
                            switchToKorean();
                            break;
                        case 'ENG' :
                            switchToEnglish();
                            break;
                        case 'CHI' :
                            switchToChinese();
                            break;
                        case 'JPN' :
                            switchToJapanese();
                            break;
                        default :
                            switchToKorean();
                    }
                }else{
                    setSsUserInfo(nowSessionIinfo);
                }
            }
        }, 5000);

        return () => {
            clearInterval(intervalTime);
        }
    }, [props.changeTime,props.sessionExpirationTime]);

    React.useEffect(() => {
        grantButtonCheck();
    }, [history.location.pathname]);

    const grantButtonCheck = () => {
        let elements = document.getElementsByTagName('Button');

        if(elements.length > 0) {
            Array.prototype.forEach.call(elements, (element) => {
                if (element.classList.contains('close')) {
                    element.parentNode.removeChild(element);
                }
            })
        }
    }

    React.useEffect(() => {
        setTimeout(() => {
            grantButtonCheck();
        }, 1000);
    });

    const logoClickHandler = () => {
        //window.location.reload();
        history.push('/app/zz/zz03_0100');
    }

    if(nullToBlank(sessionInfo) === ""){
        props.history.replace('/login/zz/zz01_0100');

        return false;
    }

    return (
        <Fragment>
            <ReactCSSTransitionGroup
                component="div"
                className={cx("app-header", headerBackgroundColor, {'header-shadow': enableHeaderShadow})}
                transitionName="HeaderAnimation"
                transitionAppear={true}
                transitionAppearTimeout={1500}
                transitionEnter={false}
                transitionLeave={false}>

                <HeaderLogo/>

                <div className={cx(
                    "app-header__content",
                    {'header-mobile-open': enableMobileMenuSmall},
                )}>
                    {props.menualCheck
                        ? <>
                            <div>
                                {MULTI_LANG.LABEL_TITLE_MESSAGE}
                            </div>
                        </>
                        : <>
                            <div className="app-header-left">
                                {enableClosedSidebar ? <div className="logo-src-no-menu" onClick={logoClickHandler} style={{cursor:'pointer'}}/> : <></>}
                                <MegaMenu ssUserInfo={ssUserInfo} setSsUserInfo={setSsUserInfo} />
                            </div>
                            <div className="app-header-right">
                                <HeaderDots ssUserInfo={ssUserInfo} setSsUserInfo={setSsUserInfo} />
                                <UserBox ssUserInfo={ssUserInfo} setSsUserInfo={setSsUserInfo} />
                            </div>
                        </>
                    }
                </div>
            </ReactCSSTransitionGroup>
        </Fragment>
    );
}

const mapStateToProps = state => ({
    enableHeaderShadow: state.ThemeOptions.enableHeaderShadow,
    closedSmallerSidebar: state.ThemeOptions.closedSmallerSidebar,
    headerBackgroundColor: state.ThemeOptions.headerBackgroundColor,
    enableMobileMenuSmall: state.ThemeOptions.enableMobileMenuSmall,
    enableClosedSidebar: state.ThemeOptions.enableClosedSidebar,
    propertyNo : state.PropertyState.propertyNo,
    changeTime : state.PropertyState.changeTime,
    menualCheck : state.MenualState.menualCheck,
    sessionTimeout : state.PropertyState.sessionTimeout,
    sessionExpirationTime : state.PropertyState.sessionExpirationTime
});

const mapDispatchToProps = dispatch => ({
    setChangeTime: (changeTime) => dispatch({type:COOKIE_CHANGE_TIME, changeTime:changeTime}),
    setSessionTimeout : (sesssionTimeout) => dispatch({type:SESSION_TIME_OUT, sesssionTimeout:sesssionTimeout}),
    setSessionExpirationTime : (sessionExpirationTime) => dispatch({type:SESSION_EXPIRATION_TIME, sessionExpirationTime:sessionExpirationTime})
});

export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(Header)));