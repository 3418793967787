import React from 'react';

const CriticalColumnHeader = (value) => {
    return (
        <div className="headerClass d-flex align-items-center justify-content-center">
            <div>{value.displayName}</div>
            <div className="badge badge-dot badge-dot-sm badge-danger ml-1">
                필수항목
            </div>
        </div>
    )
}

export { 
    CriticalColumnHeader,
};