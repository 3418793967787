import React, { Fragment, Component } from 'react';
import TextField from '@material-ui/core/TextField';
import DateFnsUtils from '@date-io/date-fns';
import dateFormat from 'dateformat';

import { MuiPickersUtilsProvider, DatePicker as MaterialDatePicker, KeyboardDatePicker } from '@material-ui/pickers';
import { format } from 'date-fns'
import { PortraitSharp } from '@material-ui/icons';
import DatePicker from 'react-datepicker';
import {dateFormatChange, nullToBlank} from "../../utils/commUtils";
import { Input } from 'reactstrap';
import $ from 'jquery';
import {faCalendarAlt} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import '../../assets/datepicker/material-picker-custom.css';

const InputDatePicker = ({classes, ...props}) => {
    const {className, ...options} = props;

    return (
        <div>
            <form  className={className} >
                <TextField
                    {...options}
                    type="date"
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </form>
        </div>
    )
}

const PopupDatePicker = ({classes, ...props}) => {
    const {defaultValue,callbackFunc, ...options} = props;
    const [state, setState] = React.useState({
        selectedDate : defaultValue
    });

    const handleDateChange = (name, callback) => event => {
        setState({ ...state, [name]: event });

        if (callback) {
            callback(event);
        }
    };

    const pickerOpen = () => {
        setTimeout(function() {
            $('.MuiDialog-root').css('zIndex', '10001');
        }, 100);
    }

    return (
        <div style={{border:'1px solid #ced4da', padding:'0.375rem 0.75rem'}}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <MaterialDatePicker
                    autoOk
                    value={state.selectedDate}
                    onChange={handleDateChange('selectedDate',callbackFunc)}
                    onOpen={pickerOpen}
                    {...options}
                />
            </MuiPickersUtilsProvider>
      </div>
    )
}

const IconButtonDatePicker = ({classes, ...props}) => {
    let {defaultValue,compareMinValue,compareMaxValue,callbackFunc,format,callbackFunc2, ...options} = props;

    //Local Storage 에서 값 가져올 부분
    //const format="yyyy-MM-dd";

    const handleDateChange = (name, callback) => event => {
        let compareMinFlag = false;
        let compareMaxFlag = false;

        if (callback) {
            if (compareMinValue && event !== null && compareMinValue > event) {
                compareMinFlag = true;
            }

            if (compareMaxValue && event !== null && compareMaxValue < event) {
                compareMaxFlag = true;
            }

            callback(event, compareMinFlag, compareMaxFlag);
        }
    };

    //가능한 format
    //yyyy-MM-dd, yyyy-MM, yyyy
    //MM/dd/yyyy, MM/yyyy
    const onBlurHandle = event => {
        const today = new Date();
        let year = today.getFullYear()
        let month = today.getMonth() + 1;
        let date = today.getDate();

        const originTargetVal = event.target.value;
        let targetVal = event.target.value.replace(/_|-|\//gi, "");
        const formatLength = format.replace(/_|-|\//gi, "").length;
        const LenTargetVal = targetVal.length;
        let fullDate = null;

        if (formatLength == LenTargetVal) {
            const yearFirstIndex = format.indexOf('y');
            const yearLastIndex = format.lastIndexOf('y') == -1 ? -1 : format.lastIndexOf('y') + 1;
            const monthFirstIndex = format.indexOf('M');
            const monthLastIndex = format.lastIndexOf('M') == -1 ? -1 : format.lastIndexOf('M') + 1;
            const dateFirstIndex = format.indexOf('d');
            const dateLastIndex = format.lastIndexOf('d') == -1 ? -1 : format.lastIndexOf('d') + 1;
            const inputYear = originTargetVal.substring(yearFirstIndex, yearLastIndex);
            const inputMonth = originTargetVal.substring(monthFirstIndex, monthLastIndex);
            const inputDate = originTargetVal.substring(dateFirstIndex, dateLastIndex);

            fullDate = lastDateValidation(inputYear, inputMonth, inputDate);
        } else {
            switch (LenTargetVal) {
                case 1:
                    if (formatLength == 8) {
                        fullDate = lastDateValidation(year, month, targetVal)
                    } else if (formatLength == 6) {
                        fullDate = lastDateValidation(year, targetVal)
                    } else {
                        fullDate = lastDateValidation(Number(targetVal) + 2000 )
                    }

                    break;
                case 2:
                    if (formatLength == 8) {
                        fullDate = lastDateValidation(year, month, targetVal)
                    } else if (formatLength == 6) {
                        fullDate = lastDateValidation(year, targetVal)
                    } else {
                        fullDate = lastDateValidation(Number(targetVal) + 2000 )
                    }

                    break;
                case 3:
                    if (formatLength == 8) {
                        month = Number(lpad(targetVal, 4, "0").substring(0,2));
                        date = lpad(targetVal, 4, "0").substring(2,4);
                        fullDate = lastDateValidation(year, month, date);
                    } else if (formatLength == 6) {
                        year = Number(targetVal.substring(0,1))+2000;
                        month = Number(targetVal.substring(1,3));
                        fullDate = lastDateValidation(year, month)
                    } else {
                        fullDate = lastDateValidation(Number(targetVal) + 2000)
                    }

                    break;
                case 4:
                    if (formatLength == 8) {
                        month = targetVal.substring(0,2);
                        date = targetVal.substring(2,4);
                        fullDate = lastDateValidation(year, month, date);
                    } else if (formatLength == 6) {
                        year = Number(targetVal.substring(0,2))+2000;
                        month = Number(targetVal.substring(2,4));
                        fullDate = lastDateValidation(year, month);
                    } else {
                        fullDate = lastDateValidation(event.target.value);
                    }

                    break;
                case 5:
                    if (formatLength == 8) {
                        year = Number(targetVal.substring(0,1)) + 2000;
                        month = targetVal.substring(1,3);
                        date = targetVal.substring(3,5);
                        fullDate = lastDateValidation(year, month, date);
                    } else {
                        year = Number(targetVal.substring(0,3))+2000;
                        month = Number(targetVal.substring(3,5));
                        fullDate = lastDateValidation(year, month);
                    }

                    break;
                case 6:
                    if (formatLength == 8) {
                        year = Number(targetVal.substring(0,2)) + 2000;
                        month = targetVal.substring(2,4);
                        date = targetVal.substring(4,6);
                        fullDate = lastDateValidation(year, month, date);
                    } else {
                        year = targetVal.substring(0,4);
                        month = targetVal.substring(4,6);
                        fullDate = lastDateValidation(year, month);
                    }

                    break;
                case 7:
                    year = Number(targetVal.substring(0,3)) + 2000;
                    month = targetVal.substring(3,5);
                    date = targetVal.substring(5,7);
                    fullDate = lastDateValidation(year, month, date);

                    break;
                case 8:
                    year = Number(targetVal.substring(0,4)) + 2000;
                    month = targetVal.substring(4,6);
                    date = targetVal.substring(6,8);
                    fullDate = lastDateValidation(year, month, date);
                    break;
            }
        }

        let compareMinFlag = false;
        let compareMaxFlag = false;

        if (callbackFunc) {
            if (compareMinValue && compareMinValue > fullDate) {
                compareMinFlag = true;
            }

            if (compareMaxValue && compareMaxValue < fullDate) {
                compareMaxFlag = true;
            }

            callbackFunc(fullDate, compareMinFlag, compareMaxFlag);
        }

        if (callbackFunc2) {
            callbackFunc2(fullDate)
        }

    }

    const lastDateValidation = (
            year = new Date().getFullYear(),
            month = new Date().getMonth() + 1,
            date = new Date().getDate()
        ) => {
                year = year == "" ? new Date().getFullYear() : year;
                month = month == "" ? new Date().getMonth() + 1 : month;
                date = date == "" ? new Date().getDate() : date;

                const lastDate = new Date(year, month, 0).getDate();
                let dateVal;

                if (lastDate < Number(date)) {
                    dateVal = null;
                } else {
                    dateVal = new Date(year + '-' + month + '-' + date)

                    if (dateVal == "Invalid Date") {
                        dateVal = null;
                    }
                }

                return dateVal;
    }

    const lpad = (str, padLen, padStr) => {
        if (padStr.length > padLen) {
            return str;
        }

        str += ""; // 문자로
        padStr += ""; // 문자로
        while (str.length < padLen)
            str = padStr + str;
        str = str.length >= padLen ? str.substring(0, padLen) : str;
        return str;
    }

    return (
        <Fragment>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                    autoOk
                    value={defaultValue}
                    onChange={handleDateChange('selectedDate',callbackFunc)}
                    onBlur = {onBlurHandle}
                    format = {format}
                    {...options}
                />
            </MuiPickersUtilsProvider>
        </Fragment>
    )
}

const GridIconButtonDatePicker = ({classes, ...props}) => {
    let {value,callbackFunc,dateFormat, ...options} = props;

    const [state, setState] = React.useState(value);

    //Local Storage 에서 값 가져올 부분
    //const format="yyyy-MM-dd";

    const handleDateChange = (callback) => event => {
        if (Object.prototype.toString.call(event) === "[object Date]") {

            if (!isNaN(event.getTime())) {
                setState(format(event, dateFormat));

                if (callback) {
                    if (event === null) {
                        callback(null, props);
                    } else {
                        callback(format(event, dateFormat), props);
                    }
                }
            }
        }

    };

    //가능한 format
    //yyyy-MM-dd, yyyy-MM, yyyy
    //MM/dd/yyyy, MM/yyyy
    const onBlurHandle = event => {
        const today = new Date();
        let year = today.getFullYear()
        let month = today.getMonth() + 1;
        let date = today.getDate();

        const originTargetVal = event.target.value;
        let targetVal = event.target.value.replace(/_|-|\//gi, "");
        const formatLength = dateFormat.replace(/_|-|\//gi, "").length;
        const LenTargetVal = targetVal.length;
        let fullDate = null;

        if (formatLength == LenTargetVal) {
            const yearFirstIndex = dateFormat.indexOf('y');
            const yearLastIndex = dateFormat.lastIndexOf('y') == -1 ? -1 : dateFormat.lastIndexOf('y') + 1;
            const monthFirstIndex = dateFormat.indexOf('M');
            const monthLastIndex = dateFormat.lastIndexOf('M') == -1 ? -1 : dateFormat.lastIndexOf('M') + 1;
            const dateFirstIndex = dateFormat.indexOf('d');
            const dateLastIndex = dateFormat.lastIndexOf('d') == -1 ? -1 : dateFormat.lastIndexOf('d') + 1;
            const inputYear = originTargetVal.substring(yearFirstIndex, yearLastIndex);
            const inputMonth = originTargetVal.substring(monthFirstIndex, monthLastIndex);
            const inputDate = originTargetVal.substring(dateFirstIndex, dateLastIndex);

            fullDate = lastDateValidation(inputYear, inputMonth, inputDate);
        } else {
            switch (LenTargetVal) {
                case 1:
                    if (formatLength == 8) {
                        fullDate = lastDateValidation(year, month, targetVal)
                    } else if (formatLength == 6) {
                        fullDate = lastDateValidation(year, targetVal)
                    } else {
                        fullDate = lastDateValidation(Number(targetVal) + 2000 )
                    }

                    break;
                case 2:
                    if (formatLength == 8) {
                        fullDate = lastDateValidation(year, month, targetVal)
                    } else if (formatLength == 6) {
                        fullDate = lastDateValidation(year, targetVal)
                    } else {
                        fullDate = lastDateValidation(Number(targetVal) + 2000 )
                    }

                    break;
                case 3:
                    if (formatLength == 8) {
                        month = Number(lpad(targetVal, 4, "0").substring(0,2));
                        date = lpad(targetVal, 4, "0").substring(2,4);
                        fullDate = lastDateValidation(year, month, date);
                    } else if (formatLength == 6) {
                        year = Number(targetVal.substring(0,1))+2000;
                        month = Number(targetVal.substring(1,3));
                        fullDate = lastDateValidation(year, month)
                    } else {
                        fullDate = lastDateValidation(Number(targetVal) + 2000)
                    }

                    break;
                case 4:
                    if (formatLength == 8) {
                        month = targetVal.substring(0,2);
                        date = targetVal.substring(2,4);
                        fullDate = lastDateValidation(year, month, date);
                    } else if (formatLength == 6) {
                        year = Number(targetVal.substring(0,2))+2000;
                        month = Number(targetVal.substring(2,4));
                        fullDate = lastDateValidation(year, month);
                    } else {
                        fullDate = lastDateValidation(event.target.value);
                    }

                    break;
                case 5:
                    if (formatLength == 8) {
                        year = Number(targetVal.substring(0,1)) + 2000;
                        month = targetVal.substring(1,3);
                        date = targetVal.substring(3,5);
                        fullDate = lastDateValidation(year, month, date);
                    } else {
                        year = Number(targetVal.substring(0,3))+2000;
                        month = Number(targetVal.substring(3,5));
                        fullDate = lastDateValidation(year, month);
                    }

                    break;
                case 6:
                    if (formatLength == 8) {
                        year = Number(targetVal.substring(0,2)) + 2000;
                        month = targetVal.substring(2,4);
                        date = targetVal.substring(4,6);
                        fullDate = lastDateValidation(year, month, date);
                    } else {
                        year = targetVal.substring(0,4);
                        month = targetVal.substring(4,6);
                        fullDate = lastDateValidation(year, month);
                    }

                    break;
                case 7:
                    year = Number(targetVal.substring(0,3)) + 2000;
                    month = targetVal.substring(3,5);
                    date = targetVal.substring(5,7);
                    fullDate = lastDateValidation(year, month, date);

                    break;
                case 8:
                    year = Number(targetVal.substring(0,4)) + 2000;
                    month = targetVal.substring(4,6);
                    date = targetVal.substring(6,8);
                    fullDate = lastDateValidation(year, month, date);
                    break;
            }
        }

        if (fullDate === null) {
            setState(null);
        } else {
            setState(format(fullDate, dateFormat));
        }

        if (callbackFunc) {
            if (fullDate === null) {
                callbackFunc(null, props)
            } else {
                callbackFunc(format(fullDate, dateFormat), props)
            }
        }
    }

    const lastDateValidation = (
            year = new Date().getFullYear(),
            month = new Date().getMonth() + 1,
            date = new Date().getDate()
        ) => {
                year = year == "" ? new Date().getFullYear() : year;
                month = month == "" ? new Date().getMonth() + 1 : month;
                date = date == "" ? new Date().getDate() : date;

                const lastDate = new Date(year, month, 0).getDate();
                let dateVal;

                if (lastDate < Number(date)) {
                    dateVal = null;
                } else {
                    dateVal = new Date(year + '-' + month + '-' + date)

                    if (dateVal == "Invalid Date") {
                        dateVal = null;
                    }
                }

                return dateVal;
    }

    const lpad = (str, padLen, padStr) => {
        if (padStr.length > padLen) {
            return str;
        }

        str += ""; // 문자로
        padStr += ""; // 문자로
        while (str.length < padLen)
            str = padStr + str;
        str = str.length >= padLen ? str.substring(0, padLen) : str;
        return str;
    }

    return (
        <div>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                    autoOk
                    value={state}
                    onChange={handleDateChange(callbackFunc)}
                    onBlur = {onBlurHandle}
                    format = {dateFormat}
                    InputProps={{ disableUnderline: true }}
                />
            </MuiPickersUtilsProvider>
        </div>
    )
}

let inputVal = "";
let clickVal = "";
let keyboardFocus = false;

const BootstrapDatePicker = ({classes, ...props}) => {
    let {dateClass, value, onChangeFunc, dateFormat, onBlurFunc, compareMinValue, compareMaxValue,compareMinTimeValue,timeValue,compareMaxTimeValue, onBlurFunc2, onKeyDownFunc, selectFuncExec, ...etc} = props;
    const userDateFormat = dateFormatChange();

    //가능한 format
    //yyyy-MM-dd, yyyy-MM, yyyy
    //MM/dd/yyyy, MM/yyyy
    const onBlurHandle = event => {
        let thisValue = "";
        if(typeof event === "string"){
            thisValue = event;
        }else{
            thisValue = event.target.value;
        }

        if (onBlurFunc || onBlurFunc2) {
            if (nullToBlank(thisValue) == "") {
                if (onBlurFunc) {
                    onBlurFunc(event, null, false, false);
                }
                return;
            }

            const today = new Date();
            let year = today.getFullYear()
            let month = today.getMonth() + 1;
            let date = today.getDate();

            if (!keyboardFocus) {
                inputVal = thisValue;
            }

            if (!isNaN(Number(thisValue))) {
                inputVal = thisValue;
            }

            if (clickVal != "") {
                inputVal = clickVal;
                clickVal = "";
            }

            let originTargetVal = inputVal;
            let targetVal = inputVal.replace(/_|-|\//gi, "");
            const formatLength = userDateFormat.replace(/_|-|\//gi, "").length;
            const LenTargetVal = targetVal.length;
            let fullDate = null;

            if (userDateFormat.length == inputVal.length) {
                const yearFirstIndex = userDateFormat.indexOf('y');
                const yearLastIndex = userDateFormat.lastIndexOf('y') == -1 ? -1 : userDateFormat.lastIndexOf('y') + 1;
                const monthFirstIndex = userDateFormat.indexOf('M');
                const monthLastIndex = userDateFormat.lastIndexOf('M') == -1 ? -1 : userDateFormat.lastIndexOf('M') + 1;
                const dateFirstIndex = userDateFormat.indexOf('d');
                const dateLastIndex = userDateFormat.lastIndexOf('d') == -1 ? -1 : userDateFormat.lastIndexOf('d') + 1;
                const inputYear = originTargetVal.substring(yearFirstIndex, yearLastIndex);
                const inputMonth = originTargetVal.substring(monthFirstIndex, monthLastIndex);
                const inputDate = originTargetVal.substring(dateFirstIndex, dateLastIndex);
                fullDate = lastDateValidation(inputYear, inputMonth, inputDate);
            } else {
                switch (LenTargetVal) {
                    case 0:
                        originTargetVal = thisValue;
                        const yearFirstIndex = userDateFormat.indexOf('y');
                        const yearLastIndex = userDateFormat.lastIndexOf('y') == -1 ? -1 : userDateFormat.lastIndexOf('y') + 1;
                        const monthFirstIndex = userDateFormat.indexOf('M');
                        const monthLastIndex = userDateFormat.lastIndexOf('M') == -1 ? -1 : userDateFormat.lastIndexOf('M') + 1;
                        const dateFirstIndex = userDateFormat.indexOf('d');
                        const dateLastIndex = userDateFormat.lastIndexOf('d') == -1 ? -1 : userDateFormat.lastIndexOf('d') + 1;
                        const inputYear = originTargetVal.substring(yearFirstIndex, yearLastIndex);
                        const inputMonth = originTargetVal.substring(monthFirstIndex, monthLastIndex);
                        const inputDate = originTargetVal.substring(dateFirstIndex, dateLastIndex);
                        fullDate = lastDateValidation(inputYear, inputMonth, inputDate);

                        break;
                    case 1:
                        if (formatLength == 8) {
                            fullDate = lastDateValidation(year, month, targetVal)
                        } else if (formatLength == 6) {
                            fullDate = lastDateValidation(year, targetVal)
                        } else {
                            fullDate = lastDateValidation(Number(targetVal) + 2000 )
                        }

                        break;
                    case 2:
                        if (formatLength == 8) {
                            fullDate = lastDateValidation(year, month, targetVal)
                        } else if (formatLength == 6) {
                            fullDate = lastDateValidation(year, targetVal)
                        } else {
                            fullDate = lastDateValidation(Number(targetVal) + 2000 )
                        }

                        break;
                    case 3:
                        if (formatLength == 8) {
                            month = Number(lpad(targetVal, 4, "0").substring(0,2));
                            date = lpad(targetVal, 4, "0").substring(2,4);
                            fullDate = lastDateValidation(year, month, date);
                        } else if (formatLength == 6) {
                            year = Number(targetVal.substring(0,1))+2000;
                            month = Number(targetVal.substring(1,3));
                            fullDate = lastDateValidation(year, month)
                        } else {
                            fullDate = lastDateValidation(Number(targetVal) + 2000)
                        }

                        break;
                    case 4:
                        if (formatLength == 8) {
                            month = targetVal.substring(0,2);
                            date = targetVal.substring(2,4);
                            fullDate = lastDateValidation(year, month, date);
                        } else if (formatLength == 6) {
                            year = Number(targetVal.substring(0,2))+2000;
                            month = Number(targetVal.substring(2,4));
                            fullDate = lastDateValidation(year, month);
                        } else {
                            fullDate = lastDateValidation(thisValue);
                        }

                        break;
                    case 5:
                        if (formatLength == 8) {
                            year = Number(targetVal.substring(0,1)) + 2000;
                            month = targetVal.substring(1,3);
                            date = targetVal.substring(3,5);
                            fullDate = lastDateValidation(year, month, date);
                        } else {
                            year = Number(targetVal.substring(0,3))+2000;
                            month = Number(targetVal.substring(3,5));
                            fullDate = lastDateValidation(year, month);
                        }

                        break;
                    case 6:
                        if (formatLength == 8) {
                            year = Number(targetVal.substring(0,2)) + 2000;
                            month = targetVal.substring(2,4);
                            date = targetVal.substring(4,6);
                            fullDate = lastDateValidation(year, month, date);
                        } else {
                            year = targetVal.substring(0,4);
                            month = targetVal.substring(4,6);
                            fullDate = lastDateValidation(year, month);
                        }

                        break;
                    case 7:
                        year = Number(targetVal.substring(0,3)) + 2000;
                        month = targetVal.substring(3,5);
                        date = targetVal.substring(5,7);
                        fullDate = lastDateValidation(year, month, date);

                        break;
                    case 8:
                        year = Number(targetVal.substring(0,4));
                        month = targetVal.substring(4,6);
                        date = targetVal.substring(6,8);
                        fullDate = lastDateValidation(year, month, date);
                        break;
                }
            }

            let compareMinFlag = false;
            let compareMaxFlag = false;

            if (onBlurFunc) {
                if (compareMinValue && compareMinValue > fullDate) {
                    compareMinFlag = true;
                }

                if (compareMaxValue && compareMaxValue < fullDate) {
                    compareMaxFlag = true;
                }

                onBlurFunc(event, fullDate, compareMinFlag, compareMaxFlag);
            }

            if (onBlurFunc2) {
                if (fullDate !== null && timeValue) {
                    const timeArray = timeValue.split(":");
                    fullDate.setHours(timeArray[0]);
                    fullDate.setMinutes(timeArray[1]);
                    fullDate.setSeconds(0);
                }

                if (compareMinTimeValue && compareMinValue) {
                    const minTimeArray = compareMinTimeValue.split(":");
                    compareMinValue.setHours(minTimeArray[0]);
                    compareMinValue.setMinutes(minTimeArray[1]);
                    compareMinValue.setSeconds(0);

                    if (timeValue && compareMinValue > fullDate) {
                        compareMinFlag = true;
                    }
                }

                if (compareMaxTimeValue && compareMaxValue) {
                    const maxTimeArray = compareMaxTimeValue.split(":");
                    compareMaxValue.setHours(maxTimeArray[0]);
                    compareMaxValue.setMinutes(maxTimeArray[1]);
                    compareMaxValue.setSeconds(0);

                    if (timeValue && compareMaxValue < fullDate) {
                        compareMaxFlag = true;
                    }
                }

                onBlurFunc2(event, fullDate, compareMinFlag, compareMaxFlag);
            }

            inputVal = "";
            keyboardFocus = false;
        }
    }

    const onKeyDownHandler = event => {
        // if (event.keyCode === 9) {
        //     event.preventDefault();
        // }

        inputVal = event.currentTarget.value;

        if (event.keyCode === 9 || event.keyCode === 13) {
            keyboardFocus = true;
        }

        if (onKeyDownFunc) {
            if (event.keyCode === 13) {
                onBlurHandle(event);
            } else if (event.keyCode === 9) {
                onBlurHandle(event);
            }
            onKeyDownFunc(event);
        }
    }

    const lastDateValidation = (
            year = new Date().getFullYear(),
            month = new Date().getMonth() + 1,
            date = new Date().getDate()
        ) => {
                year = year == "" ? new Date().getFullYear() : year;
                month = month == "" ? new Date().getMonth() + 1 : month;
                date = date == "" ? new Date().getDate() : date;

                const lastDate = new Date(year, month, 0).getDate();
                let dateVal;

                if (lastDate < Number(date)) {
                    dateVal = null;
                } else {
                    dateVal = new Date(year + '-' + month + '-' + date)

                    if (dateVal == "Invalid Date") {
                        dateVal = null;
                    }
                }

                return dateVal;
    }

    const lpad = (str, padLen, padStr) => {
        if (padStr.length > padLen) {
            return str;
        }

        str += ""; // 문자로
        padStr += ""; // 문자로
        while (str.length < padLen)
            str = padStr + str;
        str = str.length >= padLen ? str.substring(0, padLen) : str;
        return str;
    }

    const onSelectEvent = (date,event) => {
        if (event.nativeEvent.type === "click") {
            clickVal = format(date, userDateFormat);

            if (onChangeFunc) {
                 onChangeFunc(date);
            }

            // if (onBlurFunc && selectFuncExec) {
            if (onBlurFunc && selectFuncExec) {
                onBlurFunc(event, date, false, false);
            }else{
                onBlurHandle(date.format('yyyy-MM-dd'));
            }
        }
    }

    return (
        <Fragment>
            <DatePicker
                className={dateClass}
                selected={value}
                onChange={onChangeFunc}
                onBlur={onBlurHandle}
                dateFormat={userDateFormat}
                onKeyDown={onKeyDownHandler}
                disabledKeyboardNavigation={true}
                onSelect={onSelectEvent}
                autoComplete={'off'}
                {...etc}
            />
        </Fragment>
    )
}

const BootstrapTimePicker = ({classes, ...props}) => {
    let {timeClass, placeholder, value, onChangeFunc, onBlurFunc, compareMinValue, compareMaxValue, onKeyDownFunc, ...etc} = props;

    const onBlurHandle = event => {

        if (onBlurFunc) {
            let originTargetVal = event.target.value;

            if (nullToBlank(originTargetVal) == "" ) {
                originTargetVal = "00:00";
            }

            const today = new Date();
            let hour = today.getHours();
            let minute = today.getMinutes();
            let fullTime = null;

            const LenTargetVal = originTargetVal.length;


            const timePattern = /^[0-2]?[0-9]:[0-5][0-9]$/;
            const timePatternFlag = timePattern.test(originTargetVal);

            if (timePatternFlag) {
                const timeSlice = originTargetVal.split(":");
                hour = lpad(timeSlice[0], 2, "0");
                minute = lpad(timeSlice[1], 2, "0");

                if (Number(hour) > 23) {
                    fullTime = null;
                } else {
                    fullTime = hour + ":" + minute;
                }
            } else {

                if (!isNaN(Number(originTargetVal))) {
                    switch (LenTargetVal) {
                        case 1:
                            fullTime = hour + ":0" + originTargetVal;
                            break;
                        case 2:
                            if (Number(originTargetVal) > 59) {
                                fullTime = null;
                            } else {
                                fullTime = hour + ":" + originTargetVal;
                            }

                            break;
                        case 3:
                            if (Number(originTargetVal.substring(1,3)) > 59) {
                                fullTime = null;
                            } else {
                                fullTime = "0" + originTargetVal.substring(0,1) + ":" + originTargetVal.substring(1,3);
                            }

                            break;
                        case 4:
                            fullTime = originTargetVal.substring(0,2) + ":" + originTargetVal.substring(2,4);

                            if (Number(originTargetVal.substring(0,2)) > 23 || Number(originTargetVal.substring(2,4)) > 59 ) {
                                fullTime = null;
                            }

                            break;
                    }
                } else {
                    fullTime = null;
                }
            }

            onBlurFunc(event, fullTime);
        }
    }

    const lpad = (str, padLen, padStr) => {
        if (padStr.length > padLen) {
            return str;
        }

        str += ""; // 문자로
        padStr += ""; // 문자로
        while (str.length < padLen)
            str = padStr + str;
        str = str.length >= padLen ? str.substring(0, padLen) : str;
        return str;
    }

    const onKeyDownHandler = event => {
        // if (event.keyCode === 9) {
        //     event.preventDefault();
        // }

        if (onKeyDownFunc) {
            if (event.keyCode === 13) {
                onBlurHandle(event);
            }
            onKeyDownFunc(event);
        }
    }

    return (
        <Fragment>
            <Input type="text" className={timeClass} placeholder={placeholder} onBlur={onBlurHandle} value={value} onChange={onChangeFunc} onKeyDown={onKeyDownHandler} {...etc}/>
        </Fragment>
    )
}



const createDate = (date) => {
    if (!date) return new Date();

    const year = date.slice(0,4);
    const month = date.slice(4,6);
    const day = date.slice(6,8);

    return year+'-'+month+'-'+day;
}
class GridDateFieldCellEditor extends Component {
    constructor(props) {
        super(props);
        this.focusAfterAttached = props.cellStartedEdit;

        this.state = {
            stateDate: new Date(createDate(this.props.value)),
            open : false
        }
    }
    afterGuiAttached() {
        if (this.focusAfterAttached)
            document.getElementById(`${this.props.colDef.field}_${this.props.rowIndex}`).select();
    };

    focusIn() {
        document.getElementById(`${this.props.colDef.field}_${this.props.rowIndex}`).select();
        return true;
    }

    getValue() {
        if (isNaN(this.state.stateDate)) {
            return null;
        } else {
            return dateFormat(this.state.stateDate, 'yyyymmdd');
        }
    }

    render() {
        const onChangeStateDate = (date ) => {
            this.setState({
                stateDate: date
            })
        }

        const clickHandler = (id) => {
            if (!this.state.open) {
                this.setState({
                    open : true
                });
            }
        }

        const closeHandler = () => {
            this.setState({
                open : false
            });
        }

        return (
            // <IconButtonDatePicker
            //     disableToolbar
            //     variant="inline"
            //     defaultValue={this.state.stateDate}
            //     callbackFunc={onChangeStateDate}
            //     // callbackFunc2={otherDatePickerChangeHandler('cal_search_icon')}
            //     placeholder="ex) 2018-10-10"
            //     format="yyyy-MM-dd"
            //     orientation="landscape"
            //     invalidDateMessage="날짜 형식 문제"
            //     maxDateMessage="최대 날짜 문제"
            //     minDateMessage="최소 날짜 문제"
            //     InputProps={{ disableUnderline: true }}
            //     inputVariant="outlined"
            //     keyboardIcon={<FontAwesomeIcon icon={faCalendarAlt} className="w-0575em" />}
            // />
            <div className="fromToDate form-control">
                <IconButtonDatePicker
                   disableToolbar
                   id={this.props.colDef.field + "_" + this.props.rowIndex}
                   variant="inline"
                   defaultValue={this.state.stateDate}
                   callbackFunc={onChangeStateDate}
                   placeholder="ex) 2018-10-10"
                   format="yyyy-MM-dd"
                   orientation="landscape"
                   invalidDateMessage=""
                   maxDateMessage=""
                   minDateMessage=""
                   inputVariant="outlined"
                   keyboardIcon={<FontAwesomeIcon icon={faCalendarAlt} className="w-0575em" />}
                //    onClick={() => clickHandler(this.props.colDef.field + "_" + this.props.rowIndex)}
                //    onClose={closeHandler}
                //    open={this.state.open}
                />
            </div>
        );
    }
}

class GridDateFieldNullCellEditor extends Component {
    constructor(props) {
        super(props);
        this.focusAfterAttached = props.cellStartedEdit;

        this.state = {
            stateDate: nullToBlank(this.props.colDef["nullValue"]) !== '' ? nullToBlank(this.props.value) === ''? null : new Date(createDate(this.props.value, true)) : new Date(createDate(this.props.value)),
            open : false
        }
    }

    afterGuiAttached() {
        if (this.focusAfterAttached)
            document.getElementById(`${this.props.colDef.field}_${this.props.rowIndex}`).select();
    };

    focusIn() {
        document.getElementById(`${this.props.colDef.field}_${this.props.rowIndex}`).select();
        return true;
    }

    getValue() {
        if (isNaN(this.state.stateDate) || nullToBlank(this.state.stateDate) === '') {
            return null;
        } else {
            return dateFormat(this.state.stateDate, 'yyyymmdd');
        }
    }

    render() {
        const onChangeStateDate = (date ) => {
            this.setState({
                stateDate: date
            })
        }

        return (
            <div className="fromToDate form-control">
                <IconButtonDatePicker
                   disableToolbar
                   id={this.props.colDef.field + "_" + this.props.rowIndex}
                   variant="inline"
                   defaultValue={this.state.stateDate}
                   callbackFunc={onChangeStateDate}
                   placeholder="ex) 2018-10-10"
                   format="yyyy-MM-dd"
                   orientation="landscape"
                   invalidDateMessage=""
                   maxDateMessage=""
                   minDateMessage=""
                   inputVariant="outlined"
                   keyboardIcon={<FontAwesomeIcon icon={faCalendarAlt} className="w-0575em" />}
                />
            </div>
        );
    }
}

export {InputDatePicker,PopupDatePicker,IconButtonDatePicker, GridIconButtonDatePicker, BootstrapDatePicker, BootstrapTimePicker, GridDateFieldCellEditor, GridDateFieldNullCellEditor}
