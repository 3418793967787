export const ZZ12_0100_2_LANG = formatMessage => {
    let MULTI_LANG = {
        ACTIVE_YN               : formatMessage({id: "zz12_0100_2.title.activeYn",              defaultMessage: "사용여부"}),
        OPTIONS_ALL             : formatMessage({id: "zz12_0100_2.title.all",                   defaultMessage: "전체"}),
        OPTIONS_USE             : formatMessage({id: "zz12_0100_2.title.use",                   defaultMessage: "사용"}),
        OPTIONS_NO_USE          : formatMessage({id: "zz12_0100_2.title.noUse",                 defaultMessage: "사용안함"}),
        BLANK                   : formatMessage({id: "zz12_0100_2.title.blank",                 defaultMessage: "빈값"}),
        PRIMARY                 : formatMessage({id: "zz12_0100_2.title.primary",               defaultMessage: "필수항목"}),
        SERIVCE_CODE            : formatMessage({id: "zz12_0100_2.title.serviceCode",           defaultMessage: "서비스 코드"}),
        NONE_SERVICE_CODE       : formatMessage({id: "zz12_0100_2.title.noneServiceCode",       defaultMessage: "서비스 코드가 없습니다."}),
        CREATE_SERVICE          : formatMessage({id: "zz12_0100_2.title.createService",         defaultMessage: "+ 신규 서비스 코드 생성"}),
        SETTING_DATE            : formatMessage({id: "zz12_0100_2.title.settingDate",           defaultMessage: "설정일자"}),
        CHANGE_ITEM             : formatMessage({id: "zz12_0100_2.title.changeItem",            defaultMessage: "변경항목"}),
        USER_ID                 : formatMessage({id: "zz12_0100_2.title.userId",                defaultMessage: "사용자 ID"}),
        MORE                    : formatMessage({id: "zz12_0100_2.title.more",                  defaultMessage: "더보기"}),
        HISTORY                 : formatMessage({id: "zz12_0100_2.title.history",               defaultMessage: "히스토리"}),
        CLOSE                   : formatMessage({id: "zz12_0100_2.title.close",                 defaultMessage: "닫기"}),
        SETTING_PERIOD          : formatMessage({id: "zz12_0100_2.title.settingPeriod",         defaultMessage: "설정기간"}),
        BUSINESS_NAME           : formatMessage({id: "zz12_0100_2.title.businessName",          defaultMessage: "업체명"}),
        EMAIL_TYPE              : formatMessage({id: "zz12_0100_2.title.emailType",             defaultMessage: "이메일 타입"}),
        EMAIL_ADDRESS           : formatMessage({id: "zz12_0100_2.title.emailAddress",          defaultMessage: "이메일 주소"}),
        CHARGE_NAME             : formatMessage({id: "zz12_0100_2.title.chargeName",            defaultMessage: "담당자명"}),
        SEND_TYPE               : formatMessage({id: "zz12_0100_2.title.sendType",              defaultMessage: "전송타입"}),
        MODIFY                  : formatMessage({id: "zz12_0100_2.title.modify",                defaultMessage: "수정"}),
        CANCEL                  : formatMessage({id: "zz12_0100_2.title.cancel",                defaultMessage: "취소"}),
        SAVE                    : formatMessage({id: "zz12_0100_2.title.save",                  defaultMessage: "저장"}),
        CREATE_SEND_TYPE        : formatMessage({id: "zz12_0100_2.title.createSendType",        defaultMessage: "+ 신규 전송타입 추가"}),
        INITIALIZE              : formatMessage({id: "zz12_0100_2.title.intialize",             defaultMessage: "초기화"}),
        SEARCH                  : formatMessage({id: "zz12_0100_2.title.search",                defaultMessage: "검색"}),
        IMPORT                  : formatMessage({id: "zz12_0100_2.title.import",                defaultMessage: "가져오기"}),
        EXPORT                  : formatMessage({id: "zz12_0100_2.title.export",                defaultMessage: "내보내기"}),
        INPUT_CHECK             : formatMessage({id: "zz12_0100_2.title.inputCheck",            defaultMessage: "(을)를 입력해 주세요."}),
        CHANGE_MESSAGE          : formatMessage({id: "zz12_0100_2.title.changeMessage",         defaultMessage: "@(0)(이)가 '@(1)'에서 '@(2)'(으)로 변경되었습니다."}),
        EMAIL_FORMATTER          : formatMessage({id: "zz12_0100_2.valid.emailFormatter",         defaultMessage: "이메일 형식이 잘못되었습니다."}),
        NONE_DATA               : formatMessage({id: "zz12_0100_2.title.noneData",              defaultMessage: "공백"}),
        BEFORE_DATA             : formatMessage({id: "zz12_0100_2.title.beforeData",            defaultMessage: "변경 전"}),
        AFTER_DATA              : formatMessage({id: "zz12_0100_2.title.afterData",             defaultMessage: "변경 후"}),
        CHANGE_HISTORY          : formatMessage({id: "zz12_0100_2.title.changeHistory",         defaultMessage: "변경내역"}),
        SUCCESS                 : formatMessage({id: "zz12_0100_2.message.success",             defaultMessage: "정상적으로 등록되었습니다."})
    }
    
    return MULTI_LANG;
};
