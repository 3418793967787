/**
 * 파일명       : zz10_0301.js
 * 파일 설명	: 계약 현황
 * 시스템 구분	: ZZ
 *
 * Ver 작성자  일자         내용         비고
 * --- ------ ---------- ---------- --------------------------
 * 1.0  설대형 2020.01.03  최초 작성
*/

import React, {useState, useEffect} from "react";
import {injectIntl} from "react-intl";
import {withRouter} from "react-router-dom";
import {Row, Col, Container} from "reactstrap";
import {ZZ10_0301_LANG} from "./zz10_0301_leng";
import ZZ10_0301_SearchBar from "./zz10_0301/zz10_0301_SearchBar";
import ZZ10_0301_ContractList from "./zz10_0301/zz10_0301_ContractList";
import {INIT_PAGE_SIZE} from "../../../utils/globalValue";
import {successModalFunction, commonRequest} from "../../../utils/commAxios";
import {commonLang} from "../../../utils/commonLang";

const ZZ10_0301 = ({intl, PAGE_NO, OPEN_BTN, SAVE_BTN, EXCEL_BTN, SYSTEM_MENU_NAME}) => {
    const {formatMessage} = intl;
    const MULTI_LANG = ZZ10_0301_LANG(formatMessage);

    const MASTER_SEL = "PG_ZZ10_0300.PR_TB_PM_MASTER_SEL";
    const MASTER_CUD = "PG_ZZ10_0300.PR_TB_PM_MASTER_CUD";

    const [initBtnActive, setInitBtnActive] = React.useState('N');

    const initContractSearch = {
        propertyName: '',
        contStatusYn: '',
        activeYn: '',
        INIT_YN : 'N'
    };

    const [tempState, setTempState] = React.useState(initContractSearch);

    const [page, setPage] = useState({
        page: 1,
        pageSize: INIT_PAGE_SIZE
    });
    const [totalRowCount, setTotalRowCount] = useState(0);
    const [contracts, setContracts] = useState([]);
    const [propertyName, setPropertyName] = useState('');
    const [contractStatusYn, setContractStatusYn] = useState('');
    const [activeYn, setActiveYn] = useState('');
    const [maskingYn, setMaskingYn] = useState('Y');

    /**
     * @author DaeHyung Seol
     * @description Property 변경 이벤트
    */
    const handleChangePropertyName = event => {
        setPropertyName(event.target.value);
    }

    /**
     * @author DaeHyung Seol
     * @description 계약상태 변경 이벤트
    */
    const handleChangeContractStatusYn = event => {
        setContractStatusYn(event.target.value);
    }

    /**
     * @author DaeHyung Seol
     * @description 실사용 여부 변경 이벤트
    */
    const handleChangeActiveYn = event => {
        setActiveYn(event.target.value);
    }

    useEffect(()=>{
        handleSearchContracts(initContractSearch);
    }, []);

    /**
     * @author DaeHyung Seol
     * @description 초기화 버튼 이벤트
    */
    const handleInit = () => {
        setInitBtnActive('Y');
        setPropertyName('');
        setContractStatusYn('');
        setActiveYn('');
        setContracts([]);
        setTotalRowCount(0);
        setTempState({...tempState, INIT_YN: 'Y'});
    }

    /**
     * @author DaeHyung Seol
     * @description 조회 버튼 이벤트
    */
    const handleSearchContracts = () => {
        setInitBtnActive('N');
        loadContractsByPageParams(1, page.pageSize, maskingYn);
    }

    /**
     * @author DaeHyung Seol
     * @description Page 데이터로 계약 리스트 조회한다.
     * @param {Number} page 현재 페이지
     * @param {Number} pageSize 페이지 사이즈
     * @param {String} maskingYn 마스킹 여부
    */
    const loadContractsByPageParams = async (page, pageSize, maskingYn) => {
        loadContracts(propertyName, contractStatusYn, activeYn, page, pageSize, maskingYn);
    }

    /**
     * @author DaeHyung Seol
     * @description INIT_YN 가 N 인 경우에만 Page 데이터로 계약 리스트 조회
     * @param {Number} currentPage 현재 페이지
     * @param {Number} pageSize 페이지 사이즈
     * @param {String} maskingYn 마스킹 여부
    */
    const loadContractsByPagingBtnParams = async (currentPage, pageSize, maskingYn) => {
        page.page = currentPage;
        page.pageSize = pageSize;

        if (tempState['INIT_YN'] === 'N')
            loadContracts(tempState.propertyName, tempState.contStatusYn, tempState.activeYn, currentPage, pageSize, maskingYn);
    }

    /**
     * @author DaeHyung Seol
     * @description 계약 리스트 조회
     * @param {String} propertyName 프로퍼티명
     * @param {String} contractStatusYn 계약상태
     * @param {String} activeYn 실사용여부
     * @param {Number} page 현재 페이지
     * @param {Number} pageSize 페이지 사이즈
     * @param {String} maskingYn 마스킹 여부
    */
    const loadContracts = async (propertyName, contractStatusYn, activeYn, page, pageSize, maskingYn) => {
        const result = await async_loadHotelContract({
            PM_NAME: propertyName,
            CONT_STATUS_YN: contractStatusYn,
            ACTIVE_YN: activeYn,
            INT_PAGE: page,
            PAGE_SIZE: pageSize,
            MASKING_YN: maskingYn
        });

        tempState.propertyName = propertyName;
        tempState.contStatusYn = contractStatusYn;
        tempState.activeYn = activeYn;
        tempState.INIT_YN = 'N';

        setContracts(result.data);
        setPage({
            page: result.page,
            pageSize: result.pageSize
        });
        setTotalRowCount(result.totalRowCount);
    }

    /**
     * @author DaeHyung Seol
     * @description 인증 버튼 콜백
     * @param {String} value 인증 여부 (Y/N)
    */
    const handleMaskingYnChange = value => {
        setMaskingYn(value);
    }

    /**
     * @author DaeHyung Seol
     * @description 그리드 계약리스트 초기화
    */
    const handleInitContracts = () => {
        loadContractsByPagingBtnParams(page.page, page.pageSize, maskingYn);
    };

    /**
     * @author DaeHyung Seol
     * @description 호텔 마스터 조회 (비동기)
     * @param {Object} 전송 파라미터
    */
    const async_loadHotelContract = (additionalFormParams={}) => {
        const form = {
            PROCEDURE_OR_TABLE_NAME: MASTER_SEL,
            ...additionalFormParams
        };

        return new Promise(resolve => {
            commonRequest(result => {
                const items = result.data.resultData.V_OUT_JAVA_CURSOR;
                const page = parseInt(JSON.parse(result.data.resultData.V_IN_JAVA_DATA).INT_PAGE);
                const pageSize = parseInt(JSON.parse(result.data.resultData.V_IN_JAVA_DATA).PAGE_SIZE);

                const mappedItmes = items.map(item => ({
                    pmNo: item.PM_NO,
                    companyId: item.COMPANY_ID,
                    propertyName: item.PM_NAME,
                    areaName: item.REGION_NAME,
                    area: item.REGION_CN ? item.REGION_CN : null,
                    area_TEMP: item.REGION_CN ? item.REGION_CN : null,
                    channelRequestDate: item.BEGIN_REQ_DATE,
                    channelRequestDate_TEMP: item.BEGIN_REQ_DATE,
                    channelStartDate: item.CHANNEL_OPEN_DATE,
                    channelEndDate: item.CONT_END_DATE,
                    termName: item.CMS_CONT_PERIOD_NAME,
                    term: item.CMS_CONT_PERIOD_CN ? item.CMS_CONT_PERIOD_CN : null,
                    term_TEMP: item.CMS_CONT_PERIOD_CN ? item.CMS_CONT_PERIOD_CN : null,
                    pms: item.PM_SYSTEM_TYPE_NAME,
                    contrackManager: item.CONT_INCHARGE,
                    contrackManager_TEMP: item.CONT_INCHARGE,
                    conditionsName: item.CMS_CONT_CONDITION_NAME,
                    conditions: item.CMS_CONT_CONDITION_CN ? item.CMS_CONT_CONDITION_CN : null,
                    conditions_TEMP: item.CMS_CONT_CONDITION_CN ? item.CMS_CONT_CONDITION_CN : null,
                    freeChannel: item.FEE_CN,
                    freeChannel_TEMP: item.FEE_CN,
                    channelNum: item.CONT_CN_CNT,
                    channelNum_TEMP: item.CONT_CN_CNT,
                    contractAdd: item.ADD_CONT_CN_CNT,
                    linkageChannelNum: item.CHANNEL_OPEN_CNT,
                    usageStatus: item.CONT_STATUS_YN,
                    usageStatus_TEMP: item.CONT_STATUS_YN,
                    actualStatus: item.ACTIVE_YN,
                    actualStatus_TEMP: item.ACTIVE_YN,
                    traceNo: item.TRACE_NO,
                    ROWTYPE: 0,
                    usageStatusName: item.CONT_STATUS_NAME,
                    actualStatusName: item.ACTIVE_NAME
                }));

                const totalRowCount = items[0] ? items[0].TOTAL_ROW_COUNT : 0;

                resolve({
                    page,
                    pageSize,
                    data: mappedItmes,
                    totalRowCount
                });
            }, form);
        });
    };

    /**
     * @author DaeHyung Seol
     * @description 호텔 마스터 저장 (비동기)
     * @param {Object} additionalFormParams 전송 파라미터
    */
    const async_saveHotelContract = (additionalFormParams={}) => {
        const form = {
            PROCEDURE_OR_TABLE_NAME: MASTER_CUD,
            ...additionalFormParams
        };

        commonRequest(() => {
            handleSearchContracts(initContractSearch);
            successModalFunction(commonLang(), MULTI_LANG.POPUP_MESSAGE_SUCCESS);
        }, form);
    }

    return (
        <Container fluid>
            <Row>
                <Col md="12">
                    <ZZ10_0301_SearchBar
                        propertyName={propertyName}
                        contractStatusYn={contractStatusYn}
                        activeYn={activeYn}
                        onPropertyNameChange={handleChangePropertyName}
                        onContractStatusYnChange={handleChangeContractStatusYn}
                        onActiveYnChange={handleChangeActiveYn}
                        onSearch={handleSearchContracts}
                        onInit={handleInit}
                        onAuth={OPEN_BTN}
                    />
                </Col>
            </Row>
            <ZZ10_0301_ContractList
                pageNo={PAGE_NO}
                contracts={contracts}
                onSave={async_saveHotelContract}
                onCancle={handleInitContracts}
                onPageMove={loadContractsByPagingBtnParams}
                onMaskingYnChange={handleMaskingYnChange}
                page={page}
                maskingYn={maskingYn}
                totalRowcount={totalRowCount}
                initBtn={initBtnActive}
                setInitBtn={setInitBtnActive}
                savePermission={SAVE_BTN}
                excelPermission={EXCEL_BTN}
                systemMenuName={SYSTEM_MENU_NAME}
            />
        </Container>
    );
}

export default withRouter(injectIntl(ZZ10_0301));

/**
 * @author DaeHyung Seol
 * @description 무료 채널 데이터를 나누는 함수 `splitFreeChannel('50100##DOTW$$50200##Redtie$$')` => [{value: 50100, label: "DOTW"}, {....}]
 * @param {string} freeChannel 문자열 무료 채널
 * @return {Object} 무료 채널을 객체로 반환한다.
*/
export const splitFreeChannel = freeChannel => {
    if (!freeChannel) return [];

    return freeChannel.split("$$").map(items => { 
        const item = items.split("##");

        return {
            key: parseInt(item[0]),
            value: parseInt(item[0]),
            label: item[1]
        };
    });
};

/**
 * @author DaeHyung Seol
 * @description 무료 채널 데이터에 label 키를 문자열로 합친다. [{value: 50100, label: "DOTW"}, {....}] => `DOTW, ....`
 * @param {string} value 문자열 무료 채널
 * @return {string} 무료 채널에 label 값를 문자열로 나열 `DOTW, ....`
*/
export const joinFreeChannelByString = value => {
    const freeChannel = splitFreeChannel(value);

    return freeChannel.sort((frontValue, backValue) => {
        const intFrontValue = frontValue.label[0].toLowerCase().charCodeAt();
        const intBackValue = backValue.label[0].toLowerCase().charCodeAt();

        return intFrontValue < intBackValue ? -1 : (intFrontValue > intBackValue ? 1 : 0);
    }).map(child => child.label).join();
}