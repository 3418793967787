import React, {Fragment, useEffect, useRef} from 'react';
import {Popover, Nav, NavLink, NavItem, CustomInput, FormGroup} from 'reactstrap';
import {withRouter} from "react-router-dom";
import city3 from '../../../assets/utils/images/dropdown-header/city3.jpg';
import {faAngleDown} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {getSessionInfo, nullToBlank, setCookieUserInfo} from "../../../utils/commUtils";
import {postAxios, saveTempLoginHistroy} from "../../../utils/commAxios";
import {connect} from "react-redux";
import {PROPERTY_NO, COOKIE_CHANGE_TIME, PRSN_API_INFO, SESSION_TIME_OUT, SESSION_EXPIRATION_TIME} from "../../../reducers/propertyState";
import {injectIntl} from "react-intl";
import {HEADER_LANG} from "./Header_lang";

const PROPERTY_SELECT_PACKAGE = "PG_ZZ00_AUTHORITY.PR_PMS_LIST_USER_AUTH_SELECT";
let unmounted = false;

const MegaMenu = (props) => {
    const {intl, ssUserInfo, setSsUserInfo, history} = props;
    const {formatMessage} = intl;
    const [state, setState] = React.useState({
        dropdownOpen: false,
        popoverOpen: false,
    });
    const MULTI_LANG = HEADER_LANG(formatMessage);
    const [oriProperty, setOriProperty] = React.useState([]);
    const [property, setProperty] = React.useState([]);

    const filterEl = useRef(null);

    const toggle = () => {
        setState({
            dropdownOpen: !state.dropdownOpen,
            popoverOpen: !state.popoverOpen
        });
    }

    const propertyCallback = (response) => {
        const data = response.data.resultData.V_OUT_JAVA_CURSOR;

        setOriProperty(data);
        setProperty(data);

        
        if (nullToBlank(ssUserInfo) !== "") {
            const findData = data.findIndex(child => {
                return Number(child.PM_NO) === Number(ssUserInfo.SS_PMS_NO);
            });

            if (findData > -1) {
                props.setPrsnApiInfo({
                    PRSN_URL: data[findData].PRSN_URL,
                    PRSN_SYSTEM_CODE: data[findData].PRSN_SYSTEM_CODE,
                    PRSN_CONTENT_TYPE: data[findData].PRSN_CONTENT_TYPE,
                    PRSN_API_KEY: data[findData].PRSN_API_KEY,
                    PRSN_VENDOR_ID: data[findData].PRSN_VENDOR_ID,
                    PRSN_ECHO_TOKEN: data[findData].PRSN_ECHO_TOKEN,
                    PRSN_API_YN: data[findData].PRSN_API_YN,
                    PM_SYSTEM_CONN_TYPE_KEY: data[findData].PM_SYSTEM_CONN_TYPE_KEY,
                    PM_CODE: data[findData].PM_CODE
                });
                props.setSessionTimeout(data[findData].SESSION_TIME_OUT);
                props.setSessionExpirationTime(new Date().setMinutes(new Date().getMinutes() + data[findData].SESSION_TIME_OUT));
            }
        }
    }

    useEffect(() => {
        const abortController = new AbortController();

        const getProperty = async() => {
            const dataString = {
                'PROCEDURE_OR_TABLE_NAME': PROPERTY_SELECT_PACKAGE,
            }
            postAxios({
                    method: 'POST',
                    url: "/comm/jsonProcess_Procedure"
                },
                dataString,
                propertyCallback,
                (e) => {
                    console.log("에러 " + e)
                });
        }

        getProperty();

        return () => {
            abortController.abort();
        }
    }, []);

    useEffect(() => {
        if(state.popoverOpen){
            setTimeout(() => {
                if(nullToBlank(filterEl.current) != "") {
                    filterEl.current.focus();
                }
            }, 400)
        }
    }, [state.popoverOpen]);

    const filterHandler = (e) => {
        let filterProperty = oriProperty;
        filterProperty = filterProperty.filter(item => {
            return item.PM_NAME.toLowerCase().search(
                e.target.value.toLowerCase()
            ) !== -1;
        });

        setProperty(filterProperty);
    }

    const propertyHandler = (e) => {
        const selectedValue = property[e.target.value];
        let sessionInfo = getSessionInfo();

        sessionInfo['SS_PMS_NO'] = selectedValue.PM_NO;
        sessionInfo['SS_PMS_NAME'] = selectedValue.PM_NAME;
        sessionInfo['SS_DISTRIBUTED_DB_NO'] = selectedValue.DISTRIBUTED_DB_NO;
        sessionInfo['SS_PMS_GMT_TIME_GAP'] = selectedValue.GMT_TIME_GAP;
        sessionInfo['SS_DECIMAL_POINT_DIGIT'] = selectedValue.DECIMAL_POINT_DIGIT;

        let changeTime = setCookieUserInfo(JSON.stringify(sessionInfo));
        setSsUserInfo(sessionInfo);

        setState({
            popoverOpen : !state.popoverOpen
        });

        saveTempLoginHistroy({
            DISTRIBUTED_DB_NO   : selectedValue.DISTRIBUTED_DB_NO,
            PM_NO               : selectedValue.PM_NO,
            USER_NO             : sessionInfo.SS_USER_NO
        });

        props.setChangeTime(changeTime);
        props.setPrsnApiInfo(selectedValue);
        props.setPropertyNo(selectedValue);
        props.setSessionTimeout(selectedValue.SESSION_TIME_OUT);
        props.setSessionExpirationTime(new Date().setMinutes(new Date().getMinutes() + selectedValue.SESSION_TIME_OUT))

        history.push('/app/zz/zz03_0100');
    }

    const clearButton = () => {
        document.getElementById('searchFilter').value = '';
        setProperty(oriProperty);
    }

    return (
        <Fragment>
            <Nav className="header-megamenu">
                <NavItem>
                    <NavLink href="javascript:void(0);" onClick={toggle} id="PopoverMegaMenu">
                        <i className="nav-link-icon pe-7s-settings"> </i>
                        {/* Mega Menu 프로퍼티명1*/}
                        {ssUserInfo.SS_PMS_NAME}
                        <FontAwesomeIcon className="ml-2 opacity-5" icon={faAngleDown}/>
                    </NavLink>
                </NavItem>
                <Popover className="rm-max-width" placement="bottom-start" fade={false} trigger="legacy" isOpen={state.popoverOpen} target="PopoverMegaMenu"
                         toggle={toggle}>
                    <div className="dropdown-mega-menu">
                        <div className="">
                            <div className="dropdown-menu-header">
                                <div className="dropdown-menu-header-inner bg-info">
                                    <div className="menu-header-image opacity-2"
                                            style={{
                                                backgroundImage: 'url(' + city3 + ')'
                                            }}
                                    />
                                    <div className="menu-header-content">
                                        {/* <h5 className="menu-header-title">프로퍼티를 선택하세요.</h5> */}
                                        <h6 className="menu-header-subtitle">{MULTI_LANG.HEADER_SELECT_PROPERTY}</h6>
                                    </div>
                                    <div className="search-wrapper active mt-2">
                                        <div className="input-holder">
                                            <input type="text" className="search-input" placeholder={MULTI_LANG.HEADER_SEARCH_PROPERTY} onChange={filterHandler} ref={filterEl} id="searchFilter"/>
                                            <button className="search-icon"><span/></button>
                                            <button className="close" type="button" onClick={clearButton}><span aria-hidden="true">×</span></button>
                                            {/* <button className="close float-right"/> */}
                                            {/* <button className="close float-right" type="button"><span aria-hidden="true">×</span></button> */}
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div>
                                <FormGroup>
                                    <CustomInput type="select" id="exampleCustomMutlipleSelect" name="customSelect" multiple={true} onChange={propertyHandler}>
                                        {
                                            property.map((item, index) => (
                                                <option value={index} key={index}>{item.PM_NAME}</option>
                                            ))
                                        }
                                    </CustomInput>
                                </FormGroup>
                            </div>
                        </div>
                    </div>
                </Popover>
            </Nav>
        </Fragment>
    )
}

const mapStateToProps = state => {
    return({
        propertyNo: state.PropertyState.propertyNo,
        propertyName: state.PropertyState.propertyName,
        changeTime: state.PropertyState.changeTime,
        prsnUrl: state.PropertyState.prsnUrl,
        prsnSystemCode: state.PropertyState.prsnSystemCode,
        prsnContentType: state.PropertyState.prsnContentType,
        prsnApiKey: state.PropertyState.prsnApiKey,
        prsnVendorId: state.PropertyState.prsnVendorId,
        prsnEchoToken: state.PropertyState.prsnEchoToken,
        prsnApiYn: state.PropertyState.prsnApiYn,
        sessionTimeout : state.PropertyState.sessionTimeout,
        sessionExpirationTime:state.PropertyState.sessionExpirationTime
    });
}

const mapDispatchToProps = dispatch => ({
    setPropertyNo: (property) => dispatch({type:PROPERTY_NO, propertyNo: property.PM_NO, propertyName: property.PM_NAME}),
    setChangeTime: (changeTime) => dispatch({type:COOKIE_CHANGE_TIME, changeTime:changeTime}),
    setPrsnApiInfo: (prsnApiInfo) => dispatch({
        type:PRSN_API_INFO, 
        prsnUrl:prsnApiInfo.PRSN_URL, 
        prsnSystemCode:prsnApiInfo.PRSN_SYSTEM_CODE, 
        prsnContentType:prsnApiInfo.PRSN_CONTENT_TYPE, 
        prsnApiKey:prsnApiInfo.PRSN_API_KEY, 
        prsnVendorId:prsnApiInfo.PRSN_VENDOR_ID, 
        prsnEchoToken:prsnApiInfo.PRSN_ECHO_TOKEN, 
        prsnApiYn:prsnApiInfo.PRSN_API_YN,
        hotelInfo:prsnApiInfo.PM_SYSTEM_CONN_TYPE_KEY,
        hotelCode:prsnApiInfo.PM_CODE
    }),
    setSessionTimeout : (sessionTimeout) => dispatch({type:SESSION_TIME_OUT, sessionTimeout:sessionTimeout}),
    setSessionExpirationTime : (sessionExpirationTime) => dispatch({type:SESSION_EXPIRATION_TIME, sessionExpirationTime:sessionExpirationTime})
});

export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(MegaMenu)));