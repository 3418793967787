/**
 * 파일명       : zz10_0301_SearchBar.js
 * 파일 설명	: 호텔 계약 리스트 조회 영역
 * 시스템 구분	: ZZ
 *
 * Ver 작성자  일자         내용         비고
 * --- ------ ---------- ---------- --------------------------
 * 1.0  설대형 2020.01.03  최초 작성
*/

import React, {useEffect} from "react";
import {Form, FormGroup, Label, Input, Card, CardBody, CustomInput} from "reactstrap";
import {ZZ10_0301_LANG} from "../zz10_0301_leng";
import {injectIntl} from "react-intl";
import {SearchButton} from "components/Button/Button";
import {moveFocusElement} from "../../../../utils/commUtils";

const ZZ10_0300_SearchBar = ({ 
    intl,
    propertyName, 
    contractStatusYn, 
    activeYn, 
    onPropertyNameChange, 
    onContractStatusYnChange, 
    onActiveYnChange, 
    onSearch,
    onInit,
    onAuth
}) => {
    const {formatMessage} = intl;
    const MULTI_LANG = ZZ10_0301_LANG(formatMessage);
    const ENTER_KEY = 13;

    /**
     * @author DaeHyung Seol
     * @description 화면 로딩시 프로퍼티명 조회 조건 포커스 이동
    */
    useEffect(() => {
        moveFocusElement("propertyName");
    }, []);

    /**
     * @author DaeHyung Seol
     * @description 프로퍼티명 키보드 Event
     * @param {Object} event
    */
    const handleKeyDown = event => {
        if (event.keyCode === ENTER_KEY) {
            if (event.target.name === "propertyName") moveFocusElement("searchBtn", true);
            event.preventDefault();
        }
    }

    return (
        <Form onSubmit={event => {event.preventDefault()}}>
            <FormGroup>
                <Card className="mb-3 border-c3c9cd">
                    <CardBody className="pt-1">
                        <div>
                            <div className="d-md-inline-block mr-md-4 mt-3">
                                <Label className="font-weight-md text-333 mr-3">
                                    {MULTI_LANG.SEARCH_LABEL_PROPERTY}
                                </Label>
                                <Input
                                    id="propertyName"
                                    type="text"
                                    name="propertyName"
                                    className="w_200_md d-md-inline-block"
                                    value={propertyName}
                                    onChange={onPropertyNameChange}
                                    onKeyDown={handleKeyDown}
                                />
                            </div>
                            <span className="ml-2 mr-4 d-none d-md-inline-block text-e9ecef3">|</span>
                            <div className="d-md-inline-block mr-md-4 mt-3">
                                <Label className="font-weight-md text-333">
                                    {MULTI_LANG.SEARCH_LABEL_CONTRACT}
                                </Label>
                                <div className="badge badge-dot badge-dot-sm badge-danger mr-3 ml-1 top-5">&nbsp;</div>
                                <CustomInput
                                    id="contStatusAll"
                                    type="radio"
                                    name="contStatusYn"
                                    className="custom-control-inline font-size-082"
                                    checked={contractStatusYn === ''}
                                    value=""
                                    onClick={onContractStatusYnChange}
                                    label={MULTI_LANG.SEARCH_LABEL_ALL}
                                />
                                <CustomInput
                                    id="contStatusY"
                                    type="radio"
                                    name="contStatusYn"
                                    className="custom-control-inline font-size-082"
                                    checked={contractStatusYn === 'Y'}
                                    value="Y"
                                    onClick={onContractStatusYnChange}
                                    label={MULTI_LANG.SEARCH_LABEL_Y}
                                />
                                <CustomInput
                                    id="contStatusN"
                                    type="radio"
                                    name="contStatusYn"
                                    className="custom-control-inline font-size-082 mr-0"
                                    checked={contractStatusYn === 'N'}
                                    value="N"
                                    onClick={onContractStatusYnChange}
                                    label={MULTI_LANG.SEARCH_LABEL_N}
                                />
                            </div>
                            <span className="ml-2 mr-4 d-none d-md-inline-block text-e9ecef3">|</span>
                            <div className="d-md-inline-block mt-3">
                                <Label className="font-weight-md text-333">
                                    {MULTI_LANG.SEARCH_LABEL_ACTIVE}
                                </Label>
                                <div className="badge badge-dot badge-dot-sm badge-danger mr-3 ml-1 top-5">&nbsp;</div>
                                <CustomInput
                                    id="activeAll"
                                    type="radio"
                                    name="activeYn"
                                    className="custom-control-inline font-size-082"
                                    checked={activeYn === ''}
                                    value=""
                                    onClick={onActiveYnChange}
                                    label={MULTI_LANG.SEARCH_LABEL_ALL}
                                />
                                <CustomInput
                                    id="activeY"
                                    type="radio"
                                    name="activeYn"
                                    className="custom-control-inline font-size-082"
                                    checked={activeYn === 'Y'}
                                    value="Y"
                                    onClick={onActiveYnChange}
                                    label={MULTI_LANG.SEARCH_LABEL_Y}
                                />
                                <CustomInput
                                    id="activeN"
                                    type="radio"
                                    name="activeYn"
                                    className="custom-control-inline font-size-082"
                                    checked={activeYn === 'N'}
                                    value="N"
                                    onClick={onActiveYnChange}
                                    label={MULTI_LANG.SEARCH_LABEL_N}
                                />
                            </div>
                        </div>
                        <div className="divider mt-0-8rem mb-0-8rem" />
                        <SearchButton
                            searchId="searchBtn"
                            initId="searchInit"
                            initHandler={onInit}
                            searchHandler={onSearch}
                            close={onAuth}
                        />
                    </CardBody>
                </Card>
            </FormGroup>
        </Form>
    );
}

export default injectIntl(ZZ10_0300_SearchBar);