import React from "react";
import RcPagination from "rc-pagination";
import Select from "rc-select";
import {PAGINATION_LANG} from "./Pagination_lang";
import {PAGING_OBJECT} from "../../utils/globalValue";
import '../../assets/pagination/index.css';
import '../../assets/pagination/select-index.css';

/**
 * @author CMS
 * @description Pagination Component
 * @param {Number} defaultPageSize default Page Data Size
 * @param {Number} defaultCurrent default current page
 * @param {Number} total Total data number
 * @param {Object} locale
 * @param {Array} pageSizeOptions Page Data Size Range
 * @param {Function} showTotal Data Output Range
 * @param {Function} onShowSizeChange Size Change Event
 * @return {Component}
*/
const Pagination = props => {
    const {defaultPageSize, defaultCurrent, total, locale, pageSizeOptions, showTotal, onShowSizeChange, ...otherProperties} = props;
    const {formatMessage} = locale;
    const MULTI_LANG = PAGINATION_LANG(formatMessage);

    const [lang, setLang] = React.useState({
        items_per_page: MULTI_LANG.ITEMS_PER_PAGE,
        jump_to: MULTI_LANG.JUMP_TO,
        jump_to_confirm: MULTI_LANG.JUMP_TO_CONFIRM,
        page: MULTI_LANG.PAGE,
        prev_page: MULTI_LANG.PREV_PAGE,
        next_page: MULTI_LANG.NEXT_PAGE,
        prev_5: MULTI_LANG.PREV_5,
        next_5: MULTI_LANG.NEXT_5,
        prev_3: MULTI_LANG.PREV_3,
        next_3: MULTI_LANG.NEXT_3
    });

    const [pageSizeMessage, setPageSizeMessage] = React.useState(PAGING_OBJECT.INIT_PAGE_SIZE);

    /**
     * @author CMS
     * @description 페이징 사이즈 변경 Event
     * @param {Number} page
     * @param {Number} pageSize
    */
    const onShowSizeChangeHandler = (page, pageSize) => {
        setPageSizeMessage(pageSize);

        if (onShowSizeChange) onShowSizeChange(page, pageSize);
    }

    return (
        <>
            <RcPagination
                showSizeChanger={false}
                selectComponentClass={Select}
                defaultCurrent={defaultCurrent}                                                                 // 기본 페이지 번호
                defaultPageSize={PAGING_OBJECT.INIT_PAGE_SIZE}                                                  // 기본 페이지 사이즈
                onShowSizeChange={onShowSizeChangeHandler}
                total={total}                                                                                   // 전체페이지수
                locale={lang}                                                                                   // 다국어 설정 default : 중국어
                pageSizeOptions={PAGING_OBJECT.PAGE_RANGE}
                showTotal={(total, range) => `[${range[0]} ~ ${range[1]} ${MULTI_LANG.PAGEOF} ${total}]`}       // 페이징 전체 건수 표시 기능 추가
                {...otherProperties}
            />
        </>
    )
}

export {Pagination};