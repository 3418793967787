import React, {Fragment} from 'react';
import { Label, Input } from 'reactstrap';
import {fileDownload} from "../../utils/commAxios";
import {nullToBlank} from "../../utils/commUtils";

const FileAttach = props => {

    let {label, onChangeFunc, onCloseFunc, ext, className, fileName, onClickFunc, fileNo, fileDtlNo, ...others} = props;

    if (ext === undefined || ext === null) {
        ext = [];
    }

    const onDownload = () => {
        if (nullToBlank(fileNo) != "" && nullToBlank(fileDtlNo) != "") {
            fileDownload(fileNo, fileDtlNo, fileName);
        }
    }

    return (
        <Fragment>
            <Input type="file" name="file" id={props.id} className="d-inline-block w-auto file-input" onChange={onChangeFunc} accept={ext.join()} {...others}/>
            {
                fileName.length < 1 &&
                <Label for={fileName.length < 1 ? props.id : "" } className={className} onClick={onClickFunc}>{label}</Label>
            }
            {
                nullToBlank(fileNo) != "" && nullToBlank(fileDtlNo) != "" ?
                <span style={{textDecoration:'underline', cursor:'pointer'}} onClick={onDownload}>{fileName}</span>
                :
                <span>{fileName}</span>
            }
            {
                fileName.length > 0 &&
                <i className="pe-7s-close-circle font-weight-bold mr-2 ml-2" onClick={onCloseFunc}> </i>
            }
        </Fragment>
    )
}

const MultiFileAttach = props => {

    let {label, onChangeFunc, onCloseFunc, ext, className, filesInfo, onClickFunc, listIndex, ...others} = props;

    if (ext === undefined || ext === null) {
        ext = [];
    }

    if(nullToBlank(filesInfo) === ""){
        filesInfo = [];
    }

    const onDownload = (fileNo, fileDtlNo, fileName) => {
        if (nullToBlank(fileNo) != "" && nullToBlank(fileDtlNo) != "") {
            fileDownload(fileNo, fileDtlNo, fileName);
        }
    }

    return (
        <Fragment>
            <Input type="file" name="file" id={props.id} className="d-inline-block w-auto file-input" onChange={onChangeFunc} accept={ext.join(0)} {...others}/>
            <Label for={props.id} className={className} onClick={onClickFunc}>{label}</Label>

            {
                filesInfo.map((item, index) => {
                    return(
                        (nullToBlank(item.ATTC_FILE_NO) !== "" && nullToBlank(item.ATTC_FILE_DTL_NO) !== "") ?
                            <>
                                <span key={index} style={{textDecoration:'underline', cursor:'pointer'}} onClick={()=>onDownload(item.ATTC_FILE_NO, item.ATTC_FILE_DTL_NO, item.FILE_NAME)}>
                                    {item.FILE_NAME}
                                </span>
                                <i className="pe-7s-close-circle font-weight-bold mr-2 ml-2 cursor-pointer" onClick={() => onCloseFunc(listIndex, index)}> </i>
                            </>
                            :
                            <>
                                <span key={index}>
                                    {item.FILE_NAME}
                                </span>
                                <i className="pe-7s-close-circle font-weight-bold mr-2 ml-2 cursor-pointer" onClick={() => onCloseFunc(listIndex, index)}> </i>
                            </>
                    )
                })
            }

            {/*
            {
                fileName.length < 1 &&
                <Label for={fileName.length < 1 ? props.id : "" } className={className} onClick={onClickFunc}>{label}</Label>
            }
            {
                nullToBlank(fileNo) != "" && nullToBlank(fileDtlNo) != "" ?
                    <span style={{textDecoration:'underline', cursor:'pointer'}} onClick={onDownload}>{fileName}</span>
                    :
                    <span>{fileName}</span>
            }
            {
                fileName.length > 0 &&
                <i className="pe-7s-close-circle font-weight-bold mr-2 ml-2" onClick={onCloseFunc}> </i>
            }
            */}
        </Fragment>
    )
}

export {FileAttach, MultiFileAttach};
